import {
    Checkbox,
    Collapse,
    FormControlLabel,
    Stack,
    Typography,
} from '@mui/material'
import { Fragment } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '../../redux/store'
import { updateToggleables } from '../trackingToolSlice'
import { MapPointType } from '../trackingToolUtils'

// Component which controls what type of map points are enabled and disabled
const MapPointToggleables = () => {
    const dispatch = useDispatch()
    const open = useSelector(
        (state: RootState) => state.trackingTool.toggleablesOpen
    )
    const toggleables = useSelector(
        (state: RootState) => state.trackingTool.toggleables
    )

    // Disables specific feature
    const toggleFeature = (feature: MapPointType) => {
        dispatch(
            updateToggleables({
                ...toggleables,
                [feature]: !toggleables[feature],
            })
        )
    }

    const renderToggle = (
        text: string,
        checked: boolean,
        onChange: () => void
    ) => (
        <Stack direction="row" justifyItems="space-between" alignItems="center">
            <Typography align="left" variant="h6" fontSize={18} sx={{ mr: 1 }}>
                {text}
            </Typography>
            <FormControlLabel
                control={<Checkbox checked={checked} onChange={onChange} />}
                label="Show All"
            />
        </Stack>
    )

    const toggles = [
        renderToggle(
            'Processed Text',
            toggleables[MapPointType.ProcessedText],
            () => toggleFeature(MapPointType.ProcessedText)
        ),
        renderToggle('From File', toggleables[MapPointType.FileImport], () =>
            toggleFeature(MapPointType.FileImport)
        ),
        renderToggle(
            'From Local Catalog',
            toggleables[MapPointType.LocalCatalog],
            () => toggleFeature(MapPointType.LocalCatalog)
        ),
        renderToggle(
            'From Coordinates',
            toggleables[MapPointType.FromCoordinates],
            () => toggleFeature(MapPointType.FromCoordinates)
        ),
        renderToggle(
            'From External Catalog',
            toggleables[MapPointType.ExternalCatalog],
            () => toggleFeature(MapPointType.ExternalCatalog)
        ),
    ]

    return (
        <Fragment>
            <Collapse in={open} timeout="auto" unmountOnExit>
                <Stack direction="column">
                    {toggles}
                </Stack>
            </Collapse>
        </Fragment>
    )
}

export default MapPointToggleables
