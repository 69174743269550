import { createSlice } from '@reduxjs/toolkit'
import { LatLngTuple } from 'leaflet'
import mapConfig from '../../config/mapConfig'
import { CatalogItemDto, ExternalCatalogItemDto, PathDto } from '../../swagger/data-contracts'
import { importFromExternalCatalog, importFromLocalCatalog, saveToCatalog, sendTextForProcessing } from './trackingToolThunks'
import { buildPath, calculateMapCenter, ExternalMapPoint, ExternalPath, getExternalPathColor, mapExternalCatalogItemToMapPoint, mapLocalCatalogItemToMapPoint, MapPoint, MapPointType, Path, updateMapPointIfCatalogItemPresent } from './trackingToolUtils'

// map of all toggleables
export interface TrackingToolToggleables {
    [MapPointType.LocalCatalog]: boolean
    [MapPointType.ExternalCatalog]: boolean
    [MapPointType.FileImport]: boolean
    [MapPointType.FromCoordinates]: boolean,
    [MapPointType.ProcessedText]: boolean
}

/**
 * Contains tracking tool state
 */
export interface TrackingToolState {
    isLoading: boolean // whether the pathDto is loading
    pathDto?: PathDto // response object from the text
    displayedPath?: Path // processed text path
    lastError?: string // last error to have occurred
    mapCenter: LatLngTuple // center of the map
    dialogApiCallSuccess: boolean, // api call flag for dialog
    toggleablesOpen: boolean, // whether the filter with toggleables is open
    importLoading: boolean // whether the import is loading
    importedPaths: ExternalPath[], // list of all imported paths
    localCatalogMapPoints: Path, // list of all local catalog map points
    externalCatalogMapPoints: ExternalMapPoint[], // list of all external catalog map points
    coordinatesMapPoints: Path, // list of all coordinates map points
    // Toggleables control what feature is displayed on the map
    // I.e. we can disable some map points 
    toggleables: TrackingToolToggleables
    savingToCatalog?: boolean // whether saving to the catalog
    savedToCatalogSuccess?: boolean // whether the save to catalog was successful
}

const initialState: TrackingToolState = {
    isLoading: false,
    mapCenter: [mapConfig.defaultCoordinates[0], mapConfig.defaultCoordinates[1]],
    dialogApiCallSuccess: true,
    toggleablesOpen: false,
    importLoading: false,
    importedPaths: [],
    localCatalogMapPoints: [],
    externalCatalogMapPoints: [],
    coordinatesMapPoints: [],
    toggleables: {
        [MapPointType.LocalCatalog]: true,
        [MapPointType.ExternalCatalog]: true,
        [MapPointType.FileImport]: true,
        [MapPointType.FromCoordinates]: true,
        [MapPointType.ProcessedText]: true
    }
}

export const trackingToolSlice = createSlice({
    name: 'trackingTool',
    initialState,
    reducers: {
        /**
         * Consumes generic error. This should be called by component that displayed the error
         */
        consumeErr: (state: TrackingToolState) => ({ ...state, lastErr: undefined, }),

        /**
         * Resets state to the initial state
         */
        clear: () => ({ ...initialState, }),

        /**
         * Resets dialog api call success flag
         */
        resetDialogApiCallSuccess: (state: TrackingToolState) => ({ ...state, dialogApiCallSuccess: false, }),

        /**
         * Flips toggleables filter on/off
         */
        toggleToggleables: (state: TrackingToolState) => ({ ...state, toggleablesOpen: !state.toggleablesOpen, }),

        /**
         * Removes map point from the path - by passing its reactId 
         */
        removeMapPointFromPath: (state: TrackingToolState, action: {payload: string}) => ({
            ...state,
           displayedPath: state.displayedPath ? state.displayedPath.filter(mapPoint => mapPoint.reactId !== action.payload) : undefined
        }),

        /**
         * Changes map point variant to another index
         */
        changeMapPointVariant: (state: TrackingToolState, action: { payload: { mapPoint: MapPoint, variantIdx: number, } }) => {
            const path = [...state.displayedPath ?? []]
            const { mapPoint, variantIdx } = action.payload
            if (mapPoint.idx >= path.length) {
                // Ignore if map point is out of bounds
                return state
            }

            // Copy the map point and its variants array
            const pathMapPoint = { ...path[mapPoint.idx] }
            const pathMapPointVariants = [...pathMapPoint.variants ?? []]
            if (pathMapPointVariants.length <= variantIdx || !mapPoint.variants) {
                // Ignore if variant is out of bounds
                return state
            }
            const newVariant = pathMapPointVariants[variantIdx]
            const previousItemVariant = { ...pathMapPoint.catalogItem }
            const previousItemVariantIdx = mapPoint.variantIdx

            return {
                ...state,
                displayedPath: path.map((pathMapPoint, idx) => {
                    if (pathMapPoint.firstVariantCatalogItemId !== mapPoint.firstVariantCatalogItemId) {
                        return pathMapPoint
                    }

                    // Copy current state of the map point to variants array of the item
                    const modifiedPathMapPoint = {
                        ...pathMapPoint,
                        variants: !pathMapPoint.variants ? pathMapPoint.variants : [
                            ...pathMapPoint.variants.slice(0, previousItemVariantIdx),
                            previousItemVariant,
                            ...pathMapPoint.variants.slice(previousItemVariantIdx + 1)
                        ]
                    }

                    if (modifiedPathMapPoint.reactId !== mapPoint.reactId) {
                        return modifiedPathMapPoint
                    }

                    modifiedPathMapPoint.id = newVariant.id as string
                    modifiedPathMapPoint.catalogItem = newVariant
                    modifiedPathMapPoint.variantIdx = variantIdx
                    return modifiedPathMapPoint
                })
            }
        },

        /**
         * Updates map point only at its specific index - this should be used when we are e.g. removing
         * the map point from the path
         */
        updateMapPointAtIdx: (state: TrackingToolState, action: { payload: MapPoint }) => {
            const newPath = [...state.displayedPath ?? []]
            if (newPath.length <= action.payload.idx) {
                return state // do nothing if there is no path
            }

            newPath[action.payload.idx] = { ...action.payload }
            return {
                ...state,
                displayedPath: newPath,
            }
        },

        /**
         * Updates all occurrences of map point with its specific id - this should be used when we are e.g
         * toggling visibility of the map point
         */
        updateMapPoint: (state: TrackingToolState, action: { payload: MapPoint }) => {
            const newPath = [...state.displayedPath ?? []]
            return {
                ...state,
                displayedPath: newPath.map((point, _) => point.id === action.payload.id ? {
                    ...action.payload, idx: point.idx,
                    reactId: point.reactId, id: point.id
                } : point)
            }
        },

        /**
         * Changes position of map point in the path
         */
        changeMapPointIdx: (state: TrackingToolState, action: { payload: { destination: number, source: number } }) => {
            const { destination, source } = action.payload
            const newPath = [...state.displayedPath ?? []]
            // JS array dark magic
            const removed = { ...newPath[source] }
            newPath.splice(source, 1)
            newPath.splice(destination, 0, removed)
            const result = newPath.map((point, idx) => ({ ...point, idx }))
            return {
                ...state,
                displayedPath: result,
            }
        },

        /**
         * Adds new map point from coordinates to the coordinatesMapPoints array 
         */
        addCoordinatesMapPoint: (state: TrackingToolState, action: { payload: MapPoint }) => ({
            ...state,
            coordinatesMapPoints: [...state.coordinatesMapPoints, action.payload]
        }),

        /**
         * Updates toggleables - i.e. what is displayed on the map
         */
        updateToggleables: (state: TrackingToolState, action: { payload: TrackingToolToggleables }) => ({
            ...state,
            toggleables: action.payload
        }),

        /**
         * Appends new path to file import paths
         */
        appendFileImportPath: (state: TrackingToolState,
            action: { payload: { path: Path, filename: string } }) => ({
                ...state,
                importedPaths: [...state.importedPaths, {
                    idx: state.importedPaths.length,
                    color: getExternalPathColor(state.importedPaths.length),
                    path: action.payload.path,
                    visible: true,
                    filename: action.payload.filename,
                }]
            }),

        /**
         * Removes saved to catalog successfully flag
         */
        consumeSavedToCatalogSuccessfully: (state: TrackingToolState) => ({
            ...state,
            savedToCatalogSuccessfully: undefined
        }),

        /**
         * Updates catalog item id of the map point 
         */
        updateCatalogItem: (state: TrackingToolState, action: { payload: CatalogItemDto }) => ({
            ...state,
            displayedPath: state.displayedPath?.map(mapPoint => updateMapPointIfCatalogItemPresent({ ...mapPoint }, action.payload))
        }),

        /**
         * Setter for file path visibility
         */
        toggleFilePathVisibility: (state: TrackingToolState, action: { payload: { idx: number, visible: boolean } }) => ({
            ...state,
            importedPaths: state.importedPaths.map((path, idx) => idx === action.payload.idx ? { ...path, visible: action.payload.visible } : path)
        }),

        /**
         * Removes file path from the importedPaths list
         */
        removeFilePath: (state: TrackingToolState, action: { payload: { idx: number } }) => ({
            ...state,
            importedPaths: state.importedPaths.filter((_, idx) => idx !== action.payload.idx)
        }),
    },
    // Thunks
    extraReducers: (builder) => {
        builder.addCase(sendTextForProcessing.fulfilled, (state, action) => {
            const pathDto: PathDto = action.payload
            const displayedPath = buildPath(pathDto)
            return {
                ...state,
                pathDto,
                mapCenter: calculateMapCenter(displayedPath) ?? state.mapCenter,
                isLoading: false,
                // dialogApiCallSuccess: true,
                displayedPath,
            } as TrackingToolState
        })
        builder.addCase(sendTextForProcessing.rejected, (_, action: any) => ({
            ...initialState,
            lastError: action.error.message,
            isLoading: false,
            // dialogApiCallSuccess: false,
            currentPage: 0,
            displayedPath: undefined,
            displayedPathIdx: 0,
            pathDto: undefined,
            pathVariants: undefined,
        }))
        builder.addCase(sendTextForProcessing.pending, (state: TrackingToolState) => ({
            ...state,
            isLoading: true,
            // dialogApiCallSuccess: false,
        }))
        builder.addCase(importFromExternalCatalog.fulfilled, (state: TrackingToolState, action: { payload: ExternalCatalogItemDto[] }) => ({
            ...state,
            externalCatalogMapPoints: [...new Map(
                [...state.externalCatalogMapPoints, ...action.payload.map(item => mapExternalCatalogItemToMapPoint(item))]
                    .map((item, _) => [item.id, item]))
                .values()],
            dialogApiCallSuccess: true,
        }))
        builder.addCase(importFromExternalCatalog.rejected, (state: TrackingToolState, action: any) => ({
            ...state,
            lastError: action.error.message,
        }))
        builder.addCase(importFromExternalCatalog.pending, (state: TrackingToolState) => ({
            ...state,
            isLoading: true,
        }))
        builder.addCase(importFromLocalCatalog.fulfilled, (state: TrackingToolState, action: { payload: CatalogItemDto[] }) => ({
            ...state,
            localCatalogMapPoints: [...new Map(
                [...state.localCatalogMapPoints, ...action.payload.map(item => mapLocalCatalogItemToMapPoint(item))]
                    .map((item, _) => [item.id, item]))
                .values()],
            dialogApiCallSuccess: true,
        }))
        builder.addCase(importFromLocalCatalog.rejected, (state: TrackingToolState, action: any) => ({
            ...state,
            lastError: action.error.message,
        }))
        builder.addCase(importFromLocalCatalog.pending, (state: TrackingToolState) => ({
            ...state,
            importLoading: true,
        }))
        builder.addCase(saveToCatalog.fulfilled, (state: TrackingToolState) => ({
            ...state,
            savedToCatalogSuccess: true,
            savingToCatalog: false,
        }))
        builder.addCase(saveToCatalog.pending, (state: TrackingToolState) => ({
            ...state,
            savingToCatalog: true
        }))
        builder.addCase(saveToCatalog.rejected, (state: TrackingToolState, action: any) => ({
            ...state,
            lastError: action.error.message,
            savingToCatalog: false
        }))
    }
})

export const {
    consumeErr,
    clear,
    resetDialogApiCallSuccess,
    toggleToggleables,
    changeMapPointVariant,
    updateMapPointAtIdx,
    updateMapPoint,
    changeMapPointIdx,
    addCoordinatesMapPoint,
    updateToggleables,
    appendFileImportPath,
    consumeSavedToCatalogSuccessfully,
    updateCatalogItem,
    toggleFilePathVisibility,
    removeFilePath,
    removeMapPointFromPath
} = trackingToolSlice.actions

const trackingToolReducer = trackingToolSlice.reducer
export default trackingToolReducer
