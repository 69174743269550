import DOMPurify from 'dompurify'
import { ReactNode } from 'react'

/**
 * Formats HTML string to safe virtual DOM nodes.
 * @param htmlString string to format
 */
export const formatHtmlStringToReactDom = (htmlString: string, defaultValue: string = '-'): ReactNode => {
    if (!htmlString || htmlString.length === 0) {
        return defaultValue
    }
    return (
        <div dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(htmlString) }} />
    )
}
