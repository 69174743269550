import { createAsyncThunk } from '@reduxjs/toolkit'
import axiosInstance from '../../api/api'
import { Path } from './trackingToolUtils'

/**
 * Sends post request to process the text
 */
export const sendTextForProcessing = createAsyncThunk(
    'trackingTool/sendTextForProcessing',
    async (text: string) => {
        try {
            const { data, status } = await axiosInstance.post('path', { text })
            if (status !== 200) {
                return Promise.reject(
                    'Error while fetching map, please try again later'
                )
            }
            return data
        } catch (err: any) {
            return Promise.reject('Error, server is currently unavailable')
        }
    }
)

/**
 * Sends request to get all items from the local catalog with specified filter
 */
export const importFromLocalCatalog = createAsyncThunk('trackingTool/importFromLocalCatalog', async ({ name, country, type, writtenForm }: {
    name?: string,
    country?: string,
    type?: string,
    writtenForm?: string
}) => {
    try {
        const { data, status } = await axiosInstance.get('/catalog-items', {
            params: {
                name: name ? encodeURIComponent(name) : undefined,
                country: country ? encodeURIComponent(country) : undefined,
                type: type ? encodeURIComponent(type) : undefined,
                writtenForm: writtenForm ? encodeURIComponent(writtenForm) : undefined
            }
        })

        if (status !== 200) {
            return Promise.reject('Error while local catalog items, please try again later')
        }

        return data
    }
    catch (err: any) {
        return Promise.reject('Error, server is currently unavailable')
    }
})

/**
 * Imports items from external catalog
 */
export const importFromExternalCatalog = createAsyncThunk(
    'trackingTool/importFromExternalCatalog',
    async ({ name, source }: { name: string, source: string }) => {
        try {
            const { data, status } = await axiosInstance.get('/external-catalog-items', {
                params: { name: name ? encodeURIComponent(name) : undefined, source }
            })

            if (status !== 200) {
                return Promise.reject(
                    'Error while fetching external catalog items, please try again later'
                )
            }

            return data
        }
        catch (err: any) {
            return Promise.reject('Error, server is currently unavailable')
        }
    }
)

/**
 * Save item to the local catalog
 */
export const saveToCatalog = createAsyncThunk(
    'trackingTool/savePathItems',
    async (path: Path) => {
        try {
            const { status } = await axiosInstance.post('catalog-items/batch', path.filter(item => item.addToPath && !item.hidden)
                .map(item => ({ ...item.catalogItem })))

            if (status !== 200) {
                return Promise.reject('Error while saving to catalog, please try again later')
            }

            return true
        }
        catch (err: any) {
            return Promise.reject('Error while saving to catalog, please try again later')
        }
    }
)