import { Stack, Typography } from '@mui/material'
import { Fragment, FunctionComponent } from 'react'
import { Popup, Tooltip } from 'react-leaflet'
import { useSelector } from 'react-redux'
import { formatHtmlStringToReactDom } from '../../../utils/Formatting/HtmlUtils'
import { RootState } from '../../redux/store'
import {
    ExternalMapPoint,
    isMapPointDisplayable,
    MapPoint,
    MapPointType,
} from '../trackingToolUtils'
import MapMarker from './MapMarker'
import { CatalogItemDetailDialog as CatalogItemDetailDialog } from '../../Catalog/CatalogItemDetail'
import ExternalCatalogItemDetailDialog from '../../Catalog/ExternalCatalogItem'

/**
 * Map marker for external catalog
 */
const ExternalCatalogMapMarker: FunctionComponent<{
    item: ExternalMapPoint
}> = ({ item }) => {
    return (
        <MapMarker
            position={[
                item.catalogItem.latitude as number,
                item.catalogItem.longitude as number,
            ]}
            mapPoint={item}
            draggable={false}
            updatePositionCallbackFn={() => {}}
        >
            <Fragment>
                {item.catalogItem.names &&
                    item.catalogItem.names.length > 0 && (
                        <Tooltip>{item.catalogItem.names[0]}</Tooltip>
                    )}
                <Popup>
                    <Fragment>
                        <Stack direction="column" sx={{ m: 0 }}>
                            <Typography
                                variant="h6"
                                fontWeight="bold"
                                fontSize={16}
                            >
                                {formatHtmlStringToReactDom(
                                    item.catalogItem.names &&
                                        item.catalogItem.names.length > 0
                                        ? item.catalogItem.names[0]
                                        : 'Unkown'
                                )}
                            </Typography>
                            <Typography variant="body2">
                                {item.externalSource}
                            </Typography>
                            <ExternalCatalogItemDetailDialog
                                item={item.catalogItem}
                            />
                        </Stack>
                    </Fragment>
                </Popup>
            </Fragment>
        </MapMarker>
    )
}

const LocalCatalogMarker: FunctionComponent<{ item: MapPoint }> = ({
    item,
}) => {
    return (
        <MapMarker
            position={[
                item.catalogItem.latitude as number,
                item.catalogItem.longitude as number,
            ]}
            mapPoint={item}
            draggable={false}
            updatePositionCallbackFn={() => {}}
        >
            <Fragment>
                <Tooltip>{item.catalogItem.name ?? 'Unknown'}</Tooltip>
                <Popup>
                    <Fragment>
                        <Stack direction="column" sx={{ m: 0 }}>
                            <Typography
                                variant="h6"
                                fontWeight="bold"
                                fontSize={16}
                            >
                                {formatHtmlStringToReactDom(
                                    item.catalogItem.name as string
                                )}
                            </Typography>
                            <CatalogItemDetailDialog
                                mapPointType={MapPointType.LocalCatalog}
                                itemId={item.catalogItem.id ?? ''}
                            />
                        </Stack>
                    </Fragment>
                </Popup>
            </Fragment>
        </MapMarker>
    )
}

/**
 * This component is used to render both local catalog map points and external catalog map points
 */
const CatalogMapMarkers = () => {
    const localMapMarkers = useSelector((state: RootState) =>
        state.trackingTool.localCatalogMapPoints.filter((item) =>
            isMapPointDisplayable(item)
        )
    )
    const localMapMarkersEnabled = useSelector(
        (state: RootState) =>
            state.trackingTool.toggleables[MapPointType.LocalCatalog]
    )
    const externalMapMarkers = useSelector((state: RootState) =>
        state.trackingTool.externalCatalogMapPoints.filter((item) =>
            isMapPointDisplayable(item)
        )
    )
    const externalMapMarkersEnabled = useSelector(
        (state: RootState) =>
            state.trackingTool.toggleables[MapPointType.ExternalCatalog]
    )

    return (
        <Fragment>
            {localMapMarkersEnabled &&
                localMapMarkers.map((item) => (
                    <LocalCatalogMarker key={item.reactId} item={item} />
                ))}
            {externalMapMarkersEnabled &&
                externalMapMarkers.map((item) => (
                    <ExternalCatalogMapMarker key={item.reactId} item={item} />
                ))}
        </Fragment>
    )
}

export default CatalogMapMarkers
