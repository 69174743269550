import { ThemeProvider } from '@mui/material'
import { useRef, useEffect } from 'react'
import { MapContainer, TileLayer } from 'react-leaflet'
import { useSelector } from 'react-redux'
import mapConfig from '../../../config/mapConfig'
import { RootState } from '../../redux/store'
import { buildTheme } from '../../Theme/ThemeWrapper'
import RightClickPopupMenu from '../Import/ImportContextMenu'
import ProcessedTextPath from './ProcessedTextPath'
import { Map as LeafletMap } from 'leaflet'
import ExternalMapPaths from './ExternalMapPaths'
import CatalogMapMarkers from './CatalogMapMarkers'
import CoordinatesMapMarkers from './CoordinatesMapMarkers'

const mapTheme = buildTheme('light')

const Map = () => {

    const mapCenter = useSelector((state: RootState) => state.trackingTool.mapCenter)

    const mapRef = useRef<LeafletMap | undefined>(undefined)
    useEffect(() => {
        if (!mapRef || !mapRef.current) {
            return
        }

        const map = mapRef.current
        map.setView(mapCenter, mapConfig.defaultZoom, {
            animate: true,
        })
    }, [mapCenter, mapRef])

    return (
        <ThemeProvider theme={mapTheme}>
            <MapContainer
                center={[mapCenter[0], mapCenter[1]]}
                zoom={mapConfig.defaultZoom}
                style={{ height: '100%', minHeight: '100%' }}
                whenCreated={(map) => {
                    mapRef.current = map
                }}
            >
                <TileLayer
                    attribution={mapConfig.attribution}
                    url={mapConfig.url}
                />
                <ProcessedTextPath />
                <RightClickPopupMenu />
                <ExternalMapPaths />
                <CatalogMapMarkers />
                <CoordinatesMapMarkers />
            </MapContainer>
        </ThemeProvider>
    )
}

export default Map
