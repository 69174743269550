import {
    DialogContent,
    DialogTitle,
    IconButton,
    Stack,
    Typography,
} from '@mui/material'
import { useFormik } from 'formik'
import { useState } from 'react'
import { useDispatch } from 'react-redux'
import { showNotification } from '../../Notification/notificationSlice'
import ButtonOpenableDialog from '../../Reusables/ButtonOpenableDialog'
import SingleFileSelectionForm from '../../Reusables/SingleFileSelectionForm'
import * as yup from 'yup'
import generateUuid from '../../../utils/id/uuidGenerator'
import { MapPoint, MapPointType } from '../trackingToolUtils'
import UploadFileIcon from '@mui/icons-material/UploadFile'
import { appendFileImportPath } from '../trackingToolSlice'
import CloseIcon from '@mui/icons-material/Close'

const catalogItemValidationSchema = yup.object({
    id: yup.string().optional(),
    name: yup.string().optional(),
    allNames: yup.array().of(yup.string()).optional(),
    description: yup.string().optional(),
    latitude: yup.number().required(),
    longitude: yup.number().required(),
})

/**
 * Parses a GeoJson string and returns a list of MapPoints
 * @param geoJson loaded file
 * @returns
 */
export const parseGeoJsonToPathVariant = (geoJson: string) => {
    const parsed = JSON.parse(geoJson)
    if (parsed.type !== 'FeatureCollection') {
        throw new Error('Invalid GeoJson')
    }
    const features = parsed.features
    if (!features) {
        throw new Error('Invalid GeoJson provided')
    }
    const path: MapPoint[] = features.map((feature: any) => {
        const catalogItemDto = feature.properties.catalogItem

        if (!catalogItemDto) {
            throw new Error('GeoJson file does not have a valid structure')
        }
        // validate catalog item
        const catalogItem =
            catalogItemValidationSchema.validateSync(catalogItemDto)

        return {
            id: generateUuid(),
            idx: feature.properties.idx,
            addToPath: true,
            catalogItem: {
                id: catalogItem.id,
                name: catalogItem.name,
                description: catalogItem.description,
                latitude: catalogItem.latitude,
                longitude: catalogItem.longitude,
            },
            type: feature.properties.type ?? MapPointType.FileImport,
        } as MapPoint
    })
    return path
}

const ExternalPathImportDialog = () => {
    const dispatch = useDispatch()

    const [filename, setFilename] = useState<string | undefined>(undefined)
    const [fileProcessing, setFileProcessing] = useState(false)
    const [open, setOpen] = useState(false)

    const validationSchema = yup.object().shape({
        file: yup.mixed().required('File is required'),
    })

    const initialValues: { file?: File } = {
        file: undefined,
    }

    // Callback when user selects the file
    const onFileSelected = (event: any) => {
        const file = event.currentTarget.files[0]
        if (file) {
            setFilename(file.name)
            formik.setFieldValue('file', file)
        }
    }

    const formik = useFormik({
        initialValues,
        validationSchema,
        onSubmit: async (values) => {
            setFileProcessing(true)
            const reader = new FileReader()
            reader.readAsText(values.file as File)
            reader.onload = async () => {
                try {
                    const path = parseGeoJsonToPathVariant(
                        reader.result as string
                    )
                    // Append the path to the store
                    dispatch(appendFileImportPath({ path, filename: filename ?? '' }))
                    onClose()
                } catch (e: any) {
                    dispatch(
                        showNotification({
                            message: e.message,
                            severity: 'error',
                            autohideSecs: 5,
                        })
                    )
                }
                setFileProcessing(false)
            }
        },
    })

    const onClose = () => {
        if (fileProcessing) {
            return
        }
        setFilename(undefined)
        formik.resetForm()
        setOpen(false)
    }

    const onClearSelectedFile = () => {
        setFilename(undefined)
        formik.setFieldValue('file', undefined)
    }

    return (
        <ButtonOpenableDialog
            buttonText="Import"
            buttonColor="primary"
            buttonVariant="contained"
            onCloseCallback={onClose}
            maxWidth="xs"
            open={open}
            setOpen={setOpen}
            startIcon={<UploadFileIcon />}
        >
            <DialogTitle>
                <Stack direction="row" justifyContent="space-between">
                    <Typography variant="h5" fontWeight="600">
                        Import Path
                    </Typography>
                    <IconButton onClick={onClose}>
                        <CloseIcon />
                    </IconButton>
                </Stack>
            </DialogTitle>
            <DialogContent>
                <SingleFileSelectionForm
                    onFileSelected={onFileSelected}
                    onClearSelectedFile={onClearSelectedFile}
                    filename={filename}
                    formik={formik}
                />
            </DialogContent>
        </ButtonOpenableDialog>
    )
}

export default ExternalPathImportDialog
