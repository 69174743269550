import {
    Dialog,
    DialogContent,
    DialogTitle,
    IconButton,
    ListItem,
    ListItemText,
    Stack,
    Tooltip,
} from '@mui/material'
import { Fragment, FunctionComponent, useState } from 'react'
import { DraggableProvided } from 'react-beautiful-dnd'
import { useDispatch } from 'react-redux'
import { CatalogItemDto } from '../../../swagger/data-contracts'
import { MapPoint } from '../trackingToolUtils'
import DragHandleIcon from '@mui/icons-material/DragHandle'
import VisibilityIcon from '@mui/icons-material/Visibility'
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff'
import AddRoadIcon from '@mui/icons-material/AddRoad'
import RemoveRoadIcon from '@mui/icons-material/RemoveRoad'
import { ChevronLeft, ChevronRight } from '@mui/icons-material'
import {
    changeMapPointVariant,
    removeMapPointFromPath,
    updateMapPoint,
    updateMapPointAtIdx,
} from '../trackingToolSlice'
import CatalogItemDetail, {
    CatalogItemDetailProps,
} from '../../Catalog/CatalogItemDetail'
import InfoIcon from '@mui/icons-material/Info'
import ConfirmationDialog from '../../Reusables/ConfirmationDialog'
import DeleteForeverIcon from '@mui/icons-material/DeleteForever'
import CloseIcon from '@mui/icons-material/Close'

const getFormattedLocationOrEmpty = (catalogItem: CatalogItemDto) => {
    if (!catalogItem || !catalogItem.latitude || !catalogItem.longitude) {
        return 'Location unavailable'
    }

    return `${catalogItem.latitude.toFixed(
        3
    )}°, ${catalogItem.longitude.toFixed(3)}°`
}

/**
 * Catalog Item Detail Dialog that has a icon button instead of a normal button
 */
export const CatalogItemDetailDialog: FunctionComponent<
    CatalogItemDetailProps
> = ({ itemId }) => {
    const [open, setOpen] = useState(false)
    return (
        <Fragment>
            <Tooltip title="Show Info">
                <IconButton onClick={() => setOpen(true)}>
                    <InfoIcon />
                </IconButton>
            </Tooltip>
            <Dialog
                open={open}
                onClose={() => setOpen(false)}
                fullWidth
                maxWidth="lg"
            >
                <DialogTitle>
                    <Stack
                        direction="row"
                        spacing={1}
                        justifyContent="flex-end"
                    >
                        <IconButton
                            onClick={() => {
                                setOpen(false)
                            }}
                        >
                            <CloseIcon />
                        </IconButton>
                    </Stack>
                </DialogTitle>
                <DialogContent>
                    <CatalogItemDetail itemId={itemId} />
                </DialogContent>
            </Dialog>
        </Fragment>
    )
}

export interface ReorderableMapPointProps {
    item: MapPoint
    provided: DraggableProvided
    idx: number
}

/**
 * Reorderable list item
 */
const ReorderableMapPointItem: FunctionComponent<ReorderableMapPointProps> = ({
    provided,
    item,
    idx,
}) => {
    const dispatch = useDispatch()

    // Controls if the item is added to the path or not
    const toggleAddToPath = () => {
        dispatch(
            updateMapPointAtIdx({
                ...item,
                addToPath: !item?.addToPath,
                idx,
            } as MapPoint)
        )
    }

    // Controls visibility of the item
    const toggleHidden = () => {
        dispatch(
            updateMapPoint({
                ...item,
                hidden: !item?.hidden,
            })
        )
    }

    // Sets previous variant of the item
    const previousVariant = () => {
        dispatch(
            changeMapPointVariant({
                mapPoint: item,
                variantIdx: item.variantIdx - 1,
            })
        )
    }

    // Sets next variant of the item
    const nextVariant = () => {
        dispatch(
            changeMapPointVariant({
                mapPoint: item,
                variantIdx: item.variantIdx + 1,
            })
        )
    }

    const [deleteItemDialogOpen, setDeleteItemDialogOpen] = useState(false)
    const deleteItem = () => {
        dispatch(removeMapPointFromPath(item.reactId))
    }

    return (
        <ListItem
            ref={provided.innerRef}
            {...provided.draggableProps}
            {...provided.dragHandleProps}
        >
            <ConfirmationDialog
                open={deleteItemDialogOpen}
                setOpen={setDeleteItemDialogOpen}
                onConfirmCallback={deleteItem}
                title="Delete Map Point"
                secondaryText="Are you sure you want to delete this map point from the path?"
                maxWidth="xs"
            />
            <DragHandleIcon sx={{ mr: 1.5 }} />
            <ListItemText
                primary={
                    // <Typography>
                    item.catalogItem.name ?? 'Unknown name'
                    // </Typography>
                }
                secondary={getFormattedLocationOrEmpty(item.catalogItem)}
            />
            <Stack justifyContent="space-between" direction="row">
                <Stack direction="row">
                    {item.variants && (
                        <Fragment>
                            {
                                <Tooltip title="Previous variant">
                                    <IconButton
                                        disabled={item.variantIdx <= 0}
                                        onClick={previousVariant}
                                    >
                                        <ChevronLeft />
                                    </IconButton>
                                </Tooltip>
                            }
                            {
                                <Tooltip title="Next variant">
                                    <IconButton
                                        disabled={
                                            item.variantIdx >=
                                            item.variants.length - 1
                                        }
                                        onClick={nextVariant}
                                    >
                                        <ChevronRight />
                                    </IconButton>
                                </Tooltip>
                            }
                        </Fragment>
                    )}
                </Stack>
                <Stack direction="row">
                    <CatalogItemDetailDialog itemId={item.id as string} />
                    {!!item.catalogItem.latitude &&
                    !!item.catalogItem.longitude ? (
                        <Tooltip title="Display item on the map">
                            <IconButton onClick={toggleHidden}>
                                {item.hidden ? (
                                    <VisibilityOffIcon />
                                ) : (
                                    <VisibilityIcon />
                                )}
                            </IconButton>
                        </Tooltip>
                    ) : (
                        <IconButton disabled>
                            <VisibilityOffIcon />
                        </IconButton>
                    )}
                    <Tooltip title="Add item to the path">
                        <IconButton onClick={toggleAddToPath}>
                            {item.addToPath ? (
                                <AddRoadIcon />
                            ) : (
                                <RemoveRoadIcon />
                            )}
                        </IconButton>
                    </Tooltip>
                    <Tooltip title="Delete Item From Path">
                        <IconButton
                            onClick={() => {
                                setDeleteItemDialogOpen(true)
                            }}
                        >
                            <DeleteForeverIcon />
                        </IconButton>
                    </Tooltip>
                </Stack>
            </Stack>
        </ListItem>
    )
}

export default ReorderableMapPointItem
