import {
    Button,
    CircularProgress,
    DialogContent,
    DialogTitle,
    IconButton,
    Stack,
    TextField,
    Typography,
} from '@mui/material'
import { useFormik } from 'formik'
import { Fragment, useEffect, useState } from 'react'
import SendIcon from '@mui/icons-material/Send'
import ClearIcon from '@mui/icons-material/Clear'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '../../redux/store'
import { sendTextForProcessing } from '../trackingToolThunks'
import * as yup from 'yup'
import { resetDialogApiCallSuccess } from '../trackingToolSlice'
import ButtonOpenableDialog from '../../Reusables/ButtonOpenableDialog'
import TextSnippetIcon from '@mui/icons-material/TextSnippet'
import CloseIcon from '@mui/icons-material/Close'

const PlaintextUpload = () => {
    const loading = useSelector(
        (state: RootState) => state.trackingTool.isLoading
    )
    const [open, setOpen] = useState(false) // controls whether the dialog is open

    // This controls whether to keep dialog open after sending the request to the API
    const dialogApiCallSuccess = useSelector(
        (state: RootState) => state.trackingTool.dialogApiCallSuccess
    )

    const dispatch = useDispatch()

    const validationSchema = yup.object().shape({
        text: yup.mixed().required('Text is required'),
    })

    // Form control
    const formik = useFormik({
        initialValues: {
            text: '',
        },
        validationSchema,
        onSubmit: async () => {
            // Dispatch the thunk
            dispatch(sendTextForProcessing(formik.values.text))
        },
    })

    const onCloseDialog = () => {
        formik.resetForm()
        setOpen(false)
    }

    const resetForm = () => {
        formik.resetForm()
    }

    useEffect(() => {
        if (!dialogApiCallSuccess) {
            return
        }
        dispatch(resetDialogApiCallSuccess())
        setOpen(false)
    }, [dialogApiCallSuccess, dispatch])

    return (
        <Fragment>
            <ButtonOpenableDialog
                open={open}
                setOpen={setOpen}
                maxWidth="lg"
                buttonColor="primary"
                buttonVariant="contained"
                buttonText="Text"
                startIcon={<TextSnippetIcon />}
                onCloseCallback={onCloseDialog}
            >
                <DialogTitle>
                    <Stack direction="row" justifyContent="space-between" >
                    <Typography variant="h5" fontWeight="600">
                        Plaintext Input
                    </Typography>
                    <IconButton onClick={onCloseDialog}>
                        <CloseIcon />
                    </IconButton>
                    </Stack>
                </DialogTitle>
                <DialogContent>
                    <form onSubmit={formik.handleSubmit}>
                        <TextField
                            sx={{ my: 2 }}
                            fullWidth
                            multiline
                            label="Plaintext input"
                            rows={10}
                            name="text"
                            value={formik.values.text}
                            onChange={formik.handleChange}
                        />
                        <Stack
                            direction="row"
                            spacing={1}
                            justifyContent="space-between"
                            sx={{ mt: 1 }}
                        >
                            <Stack
                                justifyContent="flex-start"
                                alignSelf="flex-start"
                            >
                                {loading ? (
                                    <CircularProgress size={24} />
                                ) : (
                                    <Fragment />
                                )}
                            </Stack>
                            <Stack
                                direction="row"
                                justifyContent="flex-end"
                                alignItems="center"
                                spacing={2}
                                sx={{ mt: 2 }}
                            >
                                <Button
                                    variant="contained"
                                    color="secondary"
                                    onClick={resetForm}
                                    disabled={loading}
                                    startIcon={<ClearIcon />}
                                >
                                    Clear
                                </Button>
                                <Button
                                    type="submit"
                                    variant="contained"
                                    startIcon={<SendIcon />}
                                    disabled={loading}
                                >
                                    Submit
                                </Button>
                            </Stack>
                        </Stack>
                    </form>
                </DialogContent>
            </ButtonOpenableDialog>
        </Fragment>
    )
}

export default PlaintextUpload
