import { OverridableComponent } from '@mui/material/OverridableComponent'
import HomeIcon from '@mui/icons-material/Home'
import { MenuBook } from '@mui/icons-material'
import LibraryBooksIcon from '@mui/icons-material/LibraryBooks'
import MapIcon from '@mui/icons-material/Map'
import PersonIcon from '@mui/icons-material/Person'
import LoginIcon from '@mui/icons-material/Login'
import { SvgIconTypeMap } from '@mui/material'
import LogoutIcon from '@mui/icons-material/Logout'
import SupervisorAccountIcon from '@mui/icons-material/SupervisorAccount'
import LocalLibraryIcon from '@mui/icons-material/LocalLibrary';

export interface NavigationMenuItem {
    name: string
    path: string
    // All privileges that can access this menu item
    accessibleTo: Set<string>
    icon: OverridableComponent<SvgIconTypeMap<{}, 'svg'>>
    position: number,
    isDialog?: boolean
}

const visitorRole = 'VISITOR'
const visitorRoleOnly = 'VISITOR_ONLY'
const loggedInRole = 'LOGGED_IN'
const visitorAccess = new Set([visitorRole])
const adminAccess = new Set(['ADMIN'])
const loggedInAccess = new Set([loggedInRole])
const trackingToolAccess = new Set(['READ'])

const items: NavigationMenuItem[] = [
    {
        name: 'Home',
        path: '/',
        accessibleTo: visitorAccess,
        icon: HomeIcon,
        position: 0,
    },
    {
        name: 'Catalog',
        path: '/catalog',
        accessibleTo: visitorAccess,
        icon: MenuBook,
        position: 1,
    },
    {
        name: 'Map',
        path: '/map',
        accessibleTo: trackingToolAccess,
        icon: MapIcon,
        position: 2,
    },
    {
        name: 'Sources',
        path: '/sources',
        accessibleTo: visitorAccess,
        icon: LibraryBooksIcon,
        position: 3,
    },
    {
        name: 'Admin',
        path: '/admin',
        accessibleTo: adminAccess,
        icon: PersonIcon,
        position: 5,
    },
    {
        name: 'Login',
        path: '/login',
        accessibleTo: new Set([visitorRoleOnly]),
        icon: LoginIcon,
        position: 5,
        isDialog: true
    },
    // {
    //     name: 'Statistics',
    //     path: '/stats',
    //     accessibleTo: adminAccess,
    //     icon: DataSaverOffIcon,
    //     position: 4,
    // },
    {
        name: 'External Catalog Sources',
        path: '/externalSources',
        accessibleTo: loggedInAccess,
        icon: LocalLibraryIcon,
        position: 6,
    },
    {
        name: 'Administration',
        path: '/administration',
        accessibleTo: loggedInAccess,
        icon: SupervisorAccountIcon,
        position: 6,
    },
    {
        name: 'Logout',
        path: '/logout',
        accessibleTo: loggedInAccess,
        icon: LogoutIcon,
        position: 1337,
    }
]

const getNavigationItems = (_userRoles: string[]): NavigationMenuItem[] => {
    const userRoles: string[] = [..._userRoles]
    // Add visitor role if not present and visitor role only if user has no roles
    if (!userRoles || userRoles.length === 0) {
        userRoles.push( visitorRole, visitorRoleOnly)
    } else {
        userRoles.push(visitorRole)
        userRoles.push(loggedInRole)
    }

    return items // else return everything the user has privileges to
        .filter((item) => {
            // If the user has any of the roles that are required to access this item
            // then return it
            return userRoles.some((role) => item.accessibleTo.has(role))
        })
        .sort((a, b) => a.position - b.position)
}

export default getNavigationItems
