import { Stack, Typography } from '@mui/material'
import { LatLngTuple } from 'leaflet'
import { Fragment, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { formatHtmlStringToReactDom } from '../../../utils/Formatting/HtmlUtils'
import { RootState } from '../../redux/store'
import {
    getExternalPathColor,
    isMapPointDisplayable,
    MapPoint,
    MapPointType,
} from '../trackingToolUtils'

import { Popup, Tooltip } from 'react-leaflet'
import MapMarker from './MapMarker'
import { buildEdges } from './ProcessedTextPath'

type EdgeElement = any

const ExternalMapPath = ({ idx }: { idx: number }) => {
    const dispatch = useDispatch()
    const paths = useSelector(
        (state: RootState) => state.trackingTool.importedPaths
    )
    const externalPath = paths[idx]

    const [displayableMapPoints, setDisplayableMapPoints] = useState<
        MapPoint[]
    >([])
    useEffect(() => {
        if (!externalPath || !externalPath.visible) {
            setDisplayableMapPoints([])
            return
        }

        // Set all displayable vertices
        setDisplayableMapPoints(
            externalPath.path.filter((mapPoint) =>
                isMapPointDisplayable(mapPoint)
            )
        )
    }, [externalPath])

    const [edges, setEdges] = useState<EdgeElement[]>([])
    useEffect(() => {
        buildEdges(displayableMapPoints, setEdges, getExternalPathColor(idx))
    }, [dispatch, displayableMapPoints, idx])

    const [vertices, setVertices] = useState<JSX.Element[]>([])
    useEffect(() => {
        const uniqueMapPoints = new Set<string>()
        setVertices(
            displayableMapPoints
                .filter((mapPoint) => {
                    if (uniqueMapPoints.has(mapPoint.id)) {
                        return false
                    }
                    uniqueMapPoints.add(mapPoint.id)
                    return true
                })
                .map((item) => (
                    <MapMarker
                        key={item.reactId}
                        position={[
                            item.catalogItem.latitude as number,
                            item.catalogItem.longitude as number,
                        ]}
                        mapPoint={item}
                        updatePositionCallbackFn={(position: LatLngTuple) => {}}
                        customColor={getExternalPathColor(idx)}
                    >
                        <Fragment>
                            <Tooltip>
                                {item.catalogItem.name ?? ''}
                            </Tooltip>
                            <Popup>
                                <Fragment>
                                    <Stack direction="column" sx={{ m: 0 }}>
                                        <Typography
                                            variant="h6"
                                            fontWeight="bold"
                                            fontSize={16}
                                        >
                                            {formatHtmlStringToReactDom(
                                                item.catalogItem.name as string ?? ''
                                            )}
                                        </Typography>
                                    </Stack>
                                </Fragment>
                            </Popup>
                        </Fragment>
                    </MapMarker>
                ))
        )
    }, [dispatch, displayableMapPoints, idx])

    return (
        <Fragment>
            {vertices}
            {edges}
        </Fragment>
    )
}

const ExternalMapPaths = () => {
    const paths = useSelector(
        (state: RootState) => state.trackingTool.importedPaths
    )
    const featureEnabled = useSelector(
        (state: RootState) =>
            state.trackingTool.toggleables[MapPointType.FileImport]
    )

    return (
        <Fragment>
            {featureEnabled && (
                <Fragment>
                    {paths.map((_, idx) => (
                        <ExternalMapPath idx={idx} />
                    ))}
                </Fragment>
            )}
        </Fragment>
    )
}

export default ExternalMapPaths
