import { createAsyncThunk } from '@reduxjs/toolkit'
import axiosInstance from '../../api/api'
import { CatalogItemDto } from '../../swagger/data-contracts'
import { CatalogFilter, CatalogState } from './catalogSlice'

const apiError = 'Error, server is currently unavailable.'

// Builds query string from the filter object
const buildQuery = (catalogFilter: CatalogFilter): string => {
    return Object.entries(catalogFilter).length === 0
        ? ''
        : `?${Object.entries(catalogFilter)
            .map(([key, value]) => `${key}=${encodeURIComponent(value)}`)
            .join('&')}`
}

/**
 * Fetches all items from the api, using the filter object
 */
export const fetchItems = createAsyncThunk(
    'catalog/fetchItems',
    async (_, { getState }) => {
        try {
            // To make typescript happy we fool it like this
            const { catalog } = getState() as { catalog: CatalogState }


            // Send request with the filter
            const { data, status } = await axiosInstance.get(
                `/catalog-items${buildQuery(catalog.filter)}`
            )

            // If the request was successful return the items
            if (status === 200) {
                return data
            }

            return Promise.reject(apiError)
        } catch (err: any) {
            return Promise.reject(apiError)
        }
    }
)

/**
 * Sends delete request to the api
 */
export const deleteItem = createAsyncThunk(
    'catalog/deleteItem',
    async (catalogItem: CatalogItemDto, { getState }) => {
        try {
            const { status, } = await axiosInstance.delete(`/catalog-items/${catalogItem.id}`)
            if (status !== 200) {
                return Promise.reject('Error, catalog item could not be deleted. Please try again later.')
            }

            const { catalog: state } = getState() as { catalog: CatalogState }

            return {
                ...state,
                catalogItemDeleted: true,
                items: state.items.filter(item => item.id !== catalogItem.id),
            }
        }
        catch (err: any) {
            return Promise.reject('Error, catalog item could not be deleted. Please try again later.')
        }
    }
)
