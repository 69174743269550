import { Fragment, FunctionComponent, useEffect, useState } from 'react'
import Dialog, { DialogProps } from '@mui/material/Dialog'
import {
    Button,
    DialogContent,
    DialogTitle,
    IconButton,
    Link,
    Stack,
    TextField,
    Typography,
} from '@mui/material'
import { useFormik } from 'formik'
import * as yup from 'yup'
import { useDispatch, useSelector } from 'react-redux'
import { Link as RouterLink, useNavigate } from 'react-router-dom'
import { logIn } from './userThunks'
import { RootState } from '../redux/store'
import { consumeError, resetLoggingIn } from './userSlice'
import { Box } from '@mui/system'
import CloseIcon from '@mui/icons-material/Close'

export interface LoginDialogProps {
    maxWidth?: DialogProps['maxWidth']
}

// Login dialog
const LoginDialog: FunctionComponent<LoginDialogProps> = ({ maxWidth }) => {
    const [open, setOpen] = useState(true)
    const lastErr = useSelector((state: RootState) => state.user.lastErr)

    const dispatch = useDispatch()
    const navigate = useNavigate()
    dispatch(resetLoggingIn())
    const validationSchema = yup.object().shape({
        username: yup.string().required('Username is required'),
        password: yup.string().required('Password is required'),
    })

    const isLoggingIn = useSelector(
        (state: RootState) => state.user.isLoggingIn
    )

    const formik = useFormik({
        initialValues: {
            username: '',
            password: '',
        },
        validationSchema,
        onSubmit: () => {
            dispatch(
                logIn({
                    username: formik.values.username,
                    password: formik.values.password,
                })
            )
        },
    })

    const onCancel = () => {
        formik.resetForm()
        dispatch(consumeError())
        navigate('/')
    }

    return (
        <Fragment>
            <Dialog
                open={open}
                fullWidth={true}
                onClose={onCancel}
                maxWidth="md"
            >
                <DialogTitle>
                    <Stack
                        direction="row"
                        spacing={1}
                        justifyContent="space-between"
                        alignItems="center"
                    >
                        <Typography variant="h5" fontWeight="600">
                            Login
                        </Typography>
                        <IconButton
                            onClick={() => {
                                onCancel()
                            }}
                        >
                            <CloseIcon />
                        </IconButton>
                    </Stack>
                </DialogTitle>

                <DialogContent>
                    {lastErr ? (
                        <Typography
                            sx={{ mb: 1, mt: 1 }}
                            align="left"
                            color="error"
                            fontWeight="bold"
                        >
                            {lastErr}
                        </Typography>
                    ) : null}
                    <form onSubmit={formik.handleSubmit}>
                        <TextField
                            fullWidth
                            label="Email"
                            name="username"
                            sx={{ mb: 2, mt: 1 }}
                            value={formik.values.username}
                            onChange={formik.handleChange}
                            error={
                                Boolean(formik.errors.username) &&
                                formik.touched.username
                            }
                            helperText={
                                formik.errors.username &&
                                formik.touched.username &&
                                formik.errors.username
                            }
                        />
                        <TextField
                            fullWidth
                            label="Password"
                            name="password"
                            type="password"
                            sx={{ mb: 2 }}
                            value={formik.values.password}
                            onChange={formik.handleChange}
                            error={
                                Boolean(formik.errors.password) &&
                                formik.touched.password
                            }
                            helperText={
                                formik.errors.password &&
                                formik.touched.password &&
                                formik.errors.password
                            }
                        />
                        <Fragment>
                            <Button
                                sx={{ mb: 2 }}
                                type="submit"
                                variant="contained"
                                fullWidth
                                disabled={isLoggingIn}
                            >
                                Log in
                            </Button>
                        </Fragment>
                    </form>
                    <Box sx={{ mb: 1 }} />
                </DialogContent>
            </Dialog>
        </Fragment>
    )
}

export default LoginDialog
