import { Fragment, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { RootState } from '../redux/store'
import LoginDialog from './LoginDialog'


const Login = () => {
    const userLoggedIn = useSelector(
        (state: RootState) => state.user.isLoggedIn
    )

    // Redirect to home if the user is logged in
    const navigate = useNavigate()
    useEffect(() => {
        if (userLoggedIn) {
            navigate('/')
        }
    }, [userLoggedIn, navigate])

    return (
        <Fragment>
            <LoginDialog />
        </Fragment>
    )
}

export default Login
