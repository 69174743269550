import { createSlice } from '@reduxjs/toolkit'
import persistReducer from 'redux-persist/es/persistReducer'
import storage from 'redux-persist/lib/storage'

export interface NavigationState {
    selectedMenuItem?: string, // which item is selected
    open: boolean, // whether the menu is open
}

// persist navigation bar in local storage
const persistConfig = {
    key: 'navigation',
    storage
}

const initialState: NavigationState = {
    selectedMenuItem: '',
    open: false
}

export const navigationSlice = createSlice({
    name: 'navigation',
    initialState,
    reducers: {
        setSelectedMenuItem: (state: NavigationState, action: any) => ({...state, selectedMenuItem: action.payload}),

        /**
         * Sets the navigation menu open
         */
        setOpen: (state: NavigationState, action: any) => ({...state, open: action.payload}),
    }
})

const navigationReducer = persistReducer(persistConfig, navigationSlice.reducer)

export const { setSelectedMenuItem, setOpen } = navigationSlice.actions

export default navigationReducer
