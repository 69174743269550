import { Typography } from '@mui/material'
import { Fragment, FunctionComponent } from 'react'

export interface ShowErrorProps {
    err?: string
}

// Utility component for showing error messages
const ShowErrorIfPresent: FunctionComponent<ShowErrorProps> = ({ err }) => (
    <Fragment>
        {err ? (
            <Typography sx={{mb: 1}} align="center" variant="h6" color="error" fontWeight="bold">
                {err}
            </Typography>
        ) : null}
    </Fragment>
)

export default ShowErrorIfPresent
