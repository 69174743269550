import { Button, Stack } from "@mui/material"
import { Link as RouterLink } from 'react-router-dom'

const NotAuthorized = () => {

    return (
        <Stack alignItems={"center"} sx={{my: 4}}>
            <h1>Not Authorized</h1>
            <Button variant="outlined" component={RouterLink} to="/" >Return Home</Button>
        </Stack>
    )
}

export default NotAuthorized