import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Navigate } from 'react-router-dom'
import { RootState } from '../redux/store'
import { logout } from './userSlice'

// Component that logs the user out if they are logged in
const Logout = () => {
    const userLoggedIn = useSelector(
        (state: RootState) => state.user.isLoggedIn
    )

    const dispatch = useDispatch()
    
    // Check whether the user is logged in and if so log them out
    useEffect(() => {
        if (userLoggedIn) {
            dispatch(logout())
        }
    }, [dispatch, userLoggedIn])

    return <Navigate to="/" />
}

export default Logout
