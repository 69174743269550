import {
    Button, Container,
    Grid,
    List,
    ListItem,
    ListItemText,
    Paper, Typography,
} from '@mui/material'
import React, {Fragment, useEffect, useState} from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '../redux/store'
import 'react-quill/dist/quill.snow.css'
import ContentLoading from "../Reusables/ContentLoading"
import {UserDto} from "../../swagger/data-contracts"
import UserDetail from "./UserDetail"
import {Link as RouterLink} from "react-router-dom"
import AddIcon from '@mui/icons-material/Add';
import {fetchUsers} from "./userDetailThunks"
import {
    clear,
    consumeError,
    resetIsRequestCompleted,
    setLoading,
    setSelectedUser
} from "./userDetailSlice"
import ShowErrorIfPresent from "../Reusables/ShowErrorIfPresent"
import {number} from "yup"
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';

const apiError =
    'Error while fetching data from the server, please try again later.'

const Administration = () => {
    // Items, loading and error from api
    const users = useSelector((state: RootState) => state.usersDetail.users)
    const loading = useSelector((state: RootState) => state.usersDetail.loading)
    const apiError = useSelector((state: RootState) => state.usersDetail.error)
    const selectedUser = useSelector((state: RootState) => state.usersDetail.selectedUser)
    const isRequestCompleted = useSelector((state: RootState) => state.usersDetail.isRequestCompleted)
    const [selectedUserId, setSelectedUserId] = useState<number | undefined>(undefined)
    const loggedUsername = useSelector((state: RootState) => state.user.username)

    // Local state to display any error relevant error
    const [displayError, setDisplayError] = useState<string | undefined>(
        undefined
    )

    const dispatch = useDispatch()

    const isAdmin = useSelector(
        (state: RootState) => state.user.roles.includes("ROLE_ADMIN")
    )

    const handleClick = (userID: number) => {
        if (users) {
            setSelectedUserId(userID)
            dispatch(setSelectedUser(users.at(userID)))
        }
    }

    // Use effect to read the error and consume it
    useEffect(() => {
        if (users.length > 0) {
            // if there is user selected, leave that state - handled by useEffect on isRequestCompleted
            if (selectedUser !== undefined) {
                const selUser = users.find(u => u.email === selectedUser.email)
                if (selUser !== undefined) {
                    setSelectedUserId(users.findIndex(u => u.email === selectedUser.email))
                    dispatch(setSelectedUser(selUser))
                    return
                }
                else {
                    setSelectedUserId(0)
                    dispatch(setSelectedUser(users.at(0)))
                }
            } else {
                setSelectedUserId(0)
                dispatch(setSelectedUser(users.at(0)))
            }
        }
    }, [users, dispatch])

    useEffect(() => {
        if (isRequestCompleted) {
            dispatch(resetIsRequestCompleted())
            dispatch(fetchUsers())
        }
    }, [isRequestCompleted, dispatch])


    // Use effect to read the error and consume it
    useEffect(() => {
        if (apiError) {
            setDisplayError(apiError)
            dispatch(consumeError())
        }
    }, [apiError, dispatch])

    // Fetch the item from the api after mounting the component
    useEffect(() => {
        dispatch(fetchUsers())

        return () => {
            // Invalidate the state when unmounting so that the old list is not rerendered when the user returns to the page
            dispatch(setLoading())
        }
    }, [dispatch])

    return (
        <Fragment>
            {isAdmin? <ShowErrorIfPresent err={displayError}/> : null }
            {loading && !apiError ? <ContentLoading /> : null}
            {isAdmin?
                (!loading && users ? (
                <Grid container justifyContent="space-around">
                    <Grid item xs={6} md={3} sx={{ px: 2 }}>
                        <Paper style={{ minHeight: '80vh', display:'flex', justifyContent: 'space-between', flexDirection:'column'}} variant="outlined">
                            <List>
                                {users.map((user, id) => (
                                    <ListItem
                                        key={id}
                                        className={
                                            selectedUserId === id
                                                ? 'clicked-user'
                                                : ''
                                        }
                                        onClick={() => handleClick(id)}
                                    >
                                        <ListItemText primary={user.name} />
                                    </ListItem>
                                ))}
                            </List>
                            <Button startIcon={<AddIcon />}
                                    variant="contained"
                                    component={RouterLink}
                                    to="/register"
                                    color="primary"
                                    sx={{ m: 2 }} >
                                Add user
                            </Button>
                        </Paper>
                    </Grid>
                    <Grid item md={9} xs={6}>
                        {selectedUserId !== undefined  ?
                            <UserDetail user={selectedUser as UserDto}/>
                        : null }
                    </Grid>
                </Grid>
                ) : null)
            : (
                <Paper style={{minHeight: '80vh'}} variant="outlined">
                    <Container>
                        <Grid sx={{my: 2}} container justifyContent="space-around">
                            <Grid item xs={6} sx={{px: 1}}>
                                <Typography fontWeight={500}>E-mail</Typography>
                            </Grid>
                            <Grid item xs={6} sx={{ml: 'auto'}}>
                                <Typography key="email">{loggedUsername}</Typography>
                            </Grid>
                        </Grid>
                        <Button startIcon={<ManageAccountsIcon />}
                                variant="contained"
                                component={RouterLink}
                                to="/changePassword"
                                color="primary"
                                sx={{ m: 2 }} >
                            Change Password
                        </Button>
                    </Container>
                </Paper>
            )}
        </Fragment>
    )
}

export default Administration
