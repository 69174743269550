import { styled, useTheme } from '@mui/material/styles'
import { FunctionComponent, useEffect, useState } from 'react'
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft'
import ChevronRightIcon from '@mui/icons-material/ChevronRight'
import {
    Divider,
    Drawer,
    IconButton,
    Link,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
} from '@mui/material'
import { useSelector } from 'react-redux'
import { RootState } from '../redux/store'
import getNavigationItems from './navigationMenuItems'
import { Link as RouterLink } from 'react-router-dom'

export interface NavigationMenuProps {
    open: boolean
    drawerWidth: number
    setOpen: (open: boolean) => void
}

const DrawerHeader = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: 'flex-end',
}))

const NavigationMenu: FunctionComponent<NavigationMenuProps> = ({
    open,
    drawerWidth,
    setOpen,
}) => {
    const theme = useTheme()

    const onCloseDrawer = () => {
        setOpen(false)
    }

    // We need user roles to determine which pages will be accessible from the navigation menu
    const userRoles = useSelector((state: RootState) => state.user.roles)

    // List of all menu items
    const [menuItems, setMenuItems] = useState(getNavigationItems(userRoles))

    // Use effect to update menu items should the user roles change
    useEffect(() => {
        setMenuItems(getNavigationItems(userRoles))
    }, [userRoles])
    

    return (
        <Drawer
            sx={{
                width: drawerWidth,
                flexShrink: 0,
                '& .MuiDrawer-paper': {
                    width: drawerWidth,
                    boxSizing: 'border-box',
                },
            }}
            variant="persistent"
            anchor="left"
            open={open}
        >
            <DrawerHeader>
                <IconButton onClick={onCloseDrawer}>
                    {theme.direction === 'ltr' ? (
                        <ChevronLeftIcon />
                    ) : (
                        <ChevronRightIcon />
                    )}
                </IconButton>
            </DrawerHeader>
            <Divider />

            <List>
                {menuItems.map((item, idx) => (
                    <Link
                        underline="none"
                        color="inherit"
                        component={RouterLink}
                        to={item.path}
                        key={idx}
                    >
                        <ListItem button>
                            <ListItemIcon><item.icon /></ListItemIcon>
                            <ListItemText>{item.name}</ListItemText>
                        </ListItem>
                    </Link>
                ))}
            </List>
        </Drawer>
    )
}

export default NavigationMenu
