import {
    Button,
    DialogContent,
    DialogTitle,
    Grid,
    IconButton,
    Paper,
    Stack,
    TextField,
} from '@mui/material'
import { Fragment, FunctionComponent, useState } from 'react'
import ButtonOpenableDialog from '../../Reusables/ButtonOpenableDialog'
import * as yup from 'yup'
import { useDispatch } from 'react-redux'
import { MapPoint, MapPointType } from '../trackingToolUtils'
import generateUuid from '../../../utils/id/uuidGenerator'
import { useFormik } from 'formik'
import ThemeWrapper from '../../Theme/ThemeWrapper'
import CloseIcon from '@mui/icons-material/Close'
import { addCoordinatesMapPoint } from '../trackingToolSlice'

interface AddCatalogItemFromCoords {
    latitude: number
    longitude: number
    name: string
}

const AddFromCoordinatesDialog: FunctionComponent<{
    latLng: [number, number]
    closeContextMenu: () => void
}> = ({ latLng, closeContextMenu }) => {
    const [open, setOpen] = useState(false)
    const dispatch = useDispatch()

    const formik = useFormik({
        initialValues: {
            latitude: latLng[0],
            longitude: latLng[1],
            name: '',
        } as AddCatalogItemFromCoords,
        validationSchema: yup.object().shape({
            latitude: yup.number().required('Latitude is required'),
            longitude: yup.number().required('Longitude is required'),
            name: yup.string().required('Name is required'),
        }),
        onSubmit: (values: AddCatalogItemFromCoords) => {
            dispatch(
                addCoordinatesMapPoint({
                    id: generateUuid(),
                    idx: -1,
                    addToPath: false,
                    type: MapPointType.FromCoordinates,
                    catalogItem: {
                        name: values.name,
                        latitude: values.latitude,
                        longitude: values.longitude,
                    },
                } as MapPoint)
            )
            onClose()
        },
    })

    const onClose = () => {
        formik.resetForm()
        closeContextMenu()
        setOpen(false)
    }

    return (
        <ButtonOpenableDialog
            buttonText="Create Location"
            buttonColor="primary"
            buttonVariant="text"
            onCloseCallback={onClose}
            maxWidth="xs"
            open={open}
            setOpen={setOpen}
            size="small"
        >
            <ThemeWrapper>
                <Paper>
                    <DialogTitle>
                        <Stack
                            direction="row"
                            justifyContent="space-between"
                            alignItems="center"
                            spacing={1}
                        >
                            <Fragment>
                                Add New Location From Coordinates
                            </Fragment>
                            <IconButton onClick={onClose}>
                                <CloseIcon />
                            </IconButton>
                        </Stack>
                    </DialogTitle>
                    <DialogContent>
                        <form onSubmit={formik.handleSubmit}>
                            <Grid
                                container
                                sx={{ mt: 1 }}
                                // spacing={1}
                                justifyContent="space-around"
                                alignItems="center"
                            >
                                <Grid item xs={6} md={9}>
                                    <TextField
                                        fullWidth
                                        label="Name"
                                        size="small"
                                        name="name"
                                        variant="outlined"
                                        value={formik.values.name}
                                        onChange={formik.handleChange}
                                        error={
                                            Boolean(formik.errors.name) &&
                                            formik.touched.name
                                        }
                                        helperText={
                                            formik.errors.name &&
                                            formik.touched.name
                                        }
                                    />
                                </Grid>
                                <Grid
                                    item
                                    container
                                    xs={6}
                                    md={3}
                                    justifyContent="flex-end"
                                >
                                    <Button
                                        variant="contained"
                                        type="submit"
                                        color="primary"
                                    >
                                        Create
                                    </Button>
                                </Grid>
                            </Grid>
                            <Grid container spacing={1} sx={{ mt: 1, mb: 1 }}>
                                <Grid item xs={12} md={6}>
                                    <TextField
                                        fullWidth
                                        label="Latitude"
                                        name="latitude"
                                        type="number"
                                        size="small"
                                        variant="outlined"
                                        value={formik.values.latitude}
                                        onChange={formik.handleChange}
                                        error={
                                            Boolean(formik.errors.latitude) &&
                                            formik.touched.latitude
                                        }
                                        helperText={
                                            formik.errors.latitude &&
                                            formik.touched.latitude
                                        }
                                    />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <TextField
                                        fullWidth
                                        label="Longitude"
                                        name="longitude"
                                        type="number"
                                        variant="outlined"
                                        size="small"
                                        value={formik.values.longitude}
                                        onChange={formik.handleChange}
                                        error={
                                            Boolean(formik.errors.longitude) &&
                                            formik.touched.longitude
                                        }
                                        helperText={
                                            formik.errors.longitude &&
                                            formik.touched.longitude
                                        }
                                    />
                                </Grid>
                            </Grid>
                        </form>
                    </DialogContent>
                </Paper>
            </ThemeWrapper>
        </ButtonOpenableDialog>
    )
}

export default AddFromCoordinatesDialog
