import {
    Button,
    CircularProgress,
    Link,
    Stack,
    Typography,
} from '@mui/material'
import { Fragment, FunctionComponent } from 'react'
import AttachmentIcon from '@mui/icons-material/Attachment'
import DeleteIcon from '@mui/icons-material/Delete'
import SendIcon from '@mui/icons-material/Send'

export interface SingleFileSelectionFormProps {
    filename?: string
    onFileSelected: (event: any) => void
    formik: any
    onClearSelectedFile: () => void
    loading?: boolean
}

/**
 * Reusable form component for selecting a single file
 */
const SingleFileSelectionForm: FunctionComponent<
    SingleFileSelectionFormProps
> = ({ filename, onFileSelected, formik, onClearSelectedFile, loading }) => {
    return (
        <form onSubmit={formik.handleSubmit}>
            {!filename ? (
                <Fragment>
                    <Stack
                        direction="row"
                        justifyContent="flex-end"
                        alignItems="center"
                    >
                        <Button
                            variant="contained"
                            color="primary"
                            component="label"
                            // size="small"
                            disabled={loading}
                            startIcon={<AttachmentIcon />}
                        >
                            Select File
                            <input
                                id="file"
                                name="file"
                                type="file"
                                hidden
                                onChange={onFileSelected}
                            />
                        </Button>
                    </Stack>
                </Fragment>
            ) : (
                <Fragment>
                    <Stack direction="row" spacing={1}>
                        <Typography variant="body1">Selected File: </Typography>
                        <Typography
                            sx={{
                                textOverflow: 'ellipsis',
                                overflow: 'hidden',
                            }}
                            component={Link}
                        >
                            {filename}
                        </Typography>
                    </Stack>
                    <Stack
                        direction="row"
                        spacing={1}
                        justifyContent="space-between"
                        sx={{mt: 1}}
                    >
                        <Stack
                            justifyContent="flex-start"
                            alignSelf="flex-start"
                        >
                            {loading ? <CircularProgress size={24} /> : <Fragment />}
                        </Stack>
                        <Stack
                            direction="row"
                            justifyContent="flex-end"
                            alignItems="center"
                            spacing={2}
                            sx={{ mt: 2 }}
                        >
                            <Button
                                // sx={{ mb: 2, mt: 1 }}
                                variant="contained"
                                size="small"
                                endIcon={<DeleteIcon />}
                                onClick={onClearSelectedFile}
                                disabled={loading}
                            >
                                Remove Selection
                            </Button>
                            <Button
                                size="small"
                                type="submit"
                                variant="contained"
                                startIcon={<SendIcon />}
                                disabled={loading}
                            >
                                Submit
                            </Button>
                        </Stack>
                    </Stack>
                </Fragment>
            )}
        </form>
    )
}

export default SingleFileSelectionForm
