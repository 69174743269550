import { createSlice } from '@reduxjs/toolkit'
import { CatalogItemDto } from '../../swagger/data-contracts'
import { deleteItem, fetchItems } from './catalogThunks'

// State of the filter panel
export interface CatalogFilter {
    name?: string
    type?: string
    country?: string
    writtenForm?: string
}

export interface CatalogState {
    items: CatalogItemDto[] // list of all fetched items
    filter: CatalogFilter // filter object
    filterOpen: boolean // is filter panel open
    loading: boolean // whether the catalog is loading
    error?: string // error message
    rowsPerPage: number // number of rows per page
    rowsPerPageOptions: any[] // options for rows per page - i.e. 10, 20, 25, 50, ...
    catalogItemDeleted?: boolean // whether the catalog item was deleted
}

export const ShowAllItemsOption = 'All'

const initialState: CatalogState = {
    items: [],
    filter: {},
    filterOpen: false,
    loading: true,
    error: undefined,
    rowsPerPage: 20,
    rowsPerPageOptions: [5, 10, 20, 50, 100, 1000, ShowAllItemsOption],
}

const catalogSlice = createSlice({
    name: 'catalog',
    initialState,
    reducers: {
        /**
         * Sets the filter object
         */
        setFilter: (state: CatalogState, action: { payload: CatalogFilter }) => ({
            ...state,
            filter: { ...action.payload },
        }),

        /**
         * Clears the filter object
         */
        clearFilter: (state: CatalogState) => ({
            ...state,
            loading: true,
            filter: {},
        }),

        /**
         * Sets filter open
         */
        setFilterOpen: (state: CatalogState, action: { payload: boolean }) => ({
            ...state,
            filterOpen: action.payload,
        }),

        /**
         * Resets state to initial state
         */
        clear: (state: CatalogState) => ({ ...initialState }),

        /**
         * Sets loading
         */
        setLoading: (state: CatalogState) => ({ ...state, loading: true }),

        /**
         * Removes error message from the state - this should be called by a component that has read the error message state change
         */
        consumeError: (state: CatalogState) => ({ ...state, error: undefined }),

        /**
         * Sets number of rows per page
         */
        setRowsPerPage: (state: CatalogState, action: { payload: number }) => ({
            ...state,
            rowsPerPage: action.payload,
        }),

        /**
         * Removes flag that the catalog item has been deleted - this should be called by a component that has read the state change
         */
        consumeCatalogItemDeleted: (state: CatalogState) => ({
            ...state,
            catalogItemDeleted: undefined,
        }),
    },
    // Thunks
    extraReducers: (builder) => {
        builder.addCase(fetchItems.pending, (state: CatalogState) => ({
            ...state,
            loading: true,
        }))
        builder.addCase(fetchItems.fulfilled, (state: CatalogState, action: any) => ({
            ...state,
            items: action.payload,
            loading: false,
        }))
        builder.addCase(fetchItems.rejected, (state: CatalogState, action: any) => ({
            ...state,
            loading: false,
            error: action.error.message as string,
        }))
        builder.addCase(deleteItem.fulfilled, (state: CatalogState, action: { payload: CatalogState }) => ({
            ...action.payload
        }))
        builder.addCase(deleteItem.rejected, (state: CatalogState, action: any) => {
            return ({
                ...state,
                error: action.error.message as string,
            })
        })
    },
})

export const {
    setFilter,
    clearFilter,
    setFilterOpen,
    clear,
    setLoading,
    consumeError,
    setRowsPerPage,
    consumeCatalogItemDeleted,
} = catalogSlice.actions
const reducer = catalogSlice.reducer
export default reducer
