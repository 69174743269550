import {
    Avatar,
    Button,
    Dialog,
    DialogContent,
    DialogTitle,
    Divider,
    Grid,
    IconButton,
    Paper,
    Stack,
    Typography,
} from '@mui/material'
import { Fragment, FunctionComponent, useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import axiosInstance from '../../api/api'
import { CatalogItemDto } from '../../swagger/data-contracts'
import ShowErrorIfPresent from '../Reusables/ShowErrorIfPresent'
import ContentLoading from '../Reusables/ContentLoading'
import CatalogItemMap from './CatalogItemMap'
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos'
import { Link as RouterLink } from 'react-router-dom'
import { formatHtmlStringToReactDom } from '../../utils/Formatting/HtmlUtils'
import EditIcon from '@mui/icons-material/Edit'
import DeleteForeverIcon from '@mui/icons-material/DeleteForever'
import { useDispatch, useSelector } from 'react-redux'
import { deleteItem } from './catalogThunks'
import ConfirmationDialog from '../Reusables/ConfirmationDialog'
import { RootState } from '../redux/store'
import CloseIcon from '@mui/icons-material/Close'
import { MapPointType } from '../TrackingTool/trackingToolUtils'

const apiError =
    'Error while fetching data from the server, please try again later.'

export interface CatalogItemDetailProps {
    itemId: string // id of the item
    showReturnToCatalogButton?: boolean // whether return to catalog button is shown
    showEditAndDeleteButton?: boolean // whether edit and delete buttons are shown
    redirectAfterDelete?: string // whether the user should redirect somewhere after delete
    mapPointType?: MapPointType // type of the map point to set color of the marker
}

/**
 * Creates a one row in the item detail
 * @param rowName name of the row - e.g. All Names
 * @param items list of all items in the row
 * @returns Renderable component
 */
const mapToRow = (rowName: string, items: string[]) => (
    <Fragment>
        <Grid
            sx={{ my: 2 }}
            container
            wrap="nowrap"
            direction="row"
            justifyContent="space-around"
            alignItems="stretch"
        >
            <Grid item xs={8} sx={{ px: 1 }}>
                <Typography fontWeight={500}>{rowName}</Typography>
            </Grid>
            <Grid item xs={4} sx={{ ml: 'auto' }}>
                {items.map((item) => (
                    <Typography key={item} style={{ wordWrap: 'break-word' }}>
                        {formatHtmlStringToReactDom(item)}
                    </Typography>
                ))}
            </Grid>
        </Grid>
    </Fragment>
)

/**
 * Catalog item detail component
 */
const CatalogItemDetail: FunctionComponent<CatalogItemDetailProps> = ({
    itemId,
    showReturnToCatalogButton,
    showEditAndDeleteButton,
    redirectAfterDelete,
    mapPointType,
}) => {
    const [item, setItem] = useState<CatalogItemDto | undefined>(undefined)
    const [isItemLoading, setIsItemLoading] = useState(true)
    const [err, setErr] = useState<string | undefined>(undefined)
    const [openDeleteCatalogItemDialog, setOpenDeleteCatalogItemDialog] =
        useState(false)

    // Get permissions to display buttons to the user
    const deletePermission = useSelector((state: RootState) =>
        state.user.roles.includes('DELETE')
    )
    const editPermission = useSelector((state: RootState) =>
        state.user.roles.includes('WRITE')
    )

    const dispatch = useDispatch()
    const navigate = useNavigate()

    // Confirmation to delete the item
    const confirmDeleteCatalogItem = () => {
        if (!item) {
            return
        }

        dispatch(deleteItem(item))
        if (redirectAfterDelete) {
            navigate(redirectAfterDelete)
        }
    }

    // Fetch the item from the api after mounting the component
    useEffect(() => {
        // Function to fetch the item from the api
        const fetchItem = async () => {
            try {
                const { data, status } = await axiosInstance.get(
                    `/catalog-items/${itemId}`
                )
                if (status !== 200) {
                    setErr(apiError)
                    return
                }

                setItem(data)
                setIsItemLoading(false)
            } catch (err: any) {
                setErr(apiError)
            }
        }

        fetchItem()
    }, [itemId])

    // Catalog item rows
    const rows = [
        {
            rowName: 'Name',
            items: [item?.name],
        },
        {
            rowName: 'All Names',
            items: item?.allNames ?? [],
        },
        {
            rowName: 'Written Forms',
            items: item?.writtenForms ?? [],
        },
        {
            rowName: 'Type',
            items: item?.types ?? [],
        },
        {
            rowName: 'State or Territory',
            items: item?.countries ?? [],
        },
        {
            rowName: 'Coordinates',
            // Must be in array otherwise the string gets iterated
            items: [
                item?.longitude && item?.latitude
                    ? `${item?.latitude}°, ${item?.longitude}°`
                    : '-',
            ],
        },
        {
            rowName: 'Certainty',
            items: [item?.certainty],
        },
        {
            rowName: 'Bibliography',
            items: item?.bibliography ?? [],
        },
    ]

    return (
        <Fragment>
            <ConfirmationDialog
                open={openDeleteCatalogItemDialog}
                setOpen={setOpenDeleteCatalogItemDialog}
                onConfirmCallback={confirmDeleteCatalogItem}
                title="Delete Catalog Item"
                secondaryText="Are you sure you want to delete this catalog item? This action is irreversible."
                maxWidth="xs"
            />
            <Stack
                direction="row"
                justifyContent="space-between"
                spacing={2}
                sx={{ mt: 1, mb: 1 }}
            >
                <Stack direction="row" spacing={2}>
                    {showReturnToCatalogButton && (
                        <Button
                            startIcon={<ArrowBackIosIcon />}
                            variant="contained"
                            component={RouterLink}
                            to="/catalog"
                            color="primary"
                        >
                            Return To Catalog
                        </Button>
                    )}
                    {item && editPermission && showEditAndDeleteButton && (
                        <Button
                            startIcon={<EditIcon />}
                            variant="contained"
                            component={RouterLink}
                            to={`/catalog/edit/${itemId as string}`}
                            color="primary"
                        >
                            Edit
                        </Button>
                    )}
                </Stack>
                {item && deletePermission && showEditAndDeleteButton && (
                    <Stack justifyContent="flex-end" direction="row">
                        <Button
                            startIcon={<DeleteForeverIcon />}
                            variant="contained"
                            color="error"
                            onClick={() => setOpenDeleteCatalogItemDialog(true)}
                        >
                            Delete
                        </Button>
                    </Stack>
                )}
            </Stack>

            <ShowErrorIfPresent err={err} />

            <Paper style={{ minHeight: '100vh' }} variant="outlined">
                {isItemLoading && !err ? <ContentLoading /> : null}
                {!isItemLoading && item ? (
                    <Grid container justifyContent="space-around">
                        <Grid item container xs={12} md={6} sx={{ px: 2 }}>
                            {rows.map((row, idx) => {
                                return (
                                    <Fragment>
                                        {mapToRow(
                                            row.rowName as string,
                                            row.items as string[]
                                        )}
                                        <Divider />
                                    </Fragment>
                                )
                            })}
                            <Grid item xs={12}>
                                <Typography
                                    fontWeight={500}
                                    sx={{ mb: 0, px: 1 }}
                                >
                                    Description
                                </Typography>
                                <Typography sx={{px: 1}} style={{ wordWrap: 'break-word' }}>
                                    {formatHtmlStringToReactDom(
                                        item.description ?? ''
                                    )}
                                </Typography>
                            </Grid>
                        </Grid>

                        <Grid item md={6} xs={12}>
                            <CatalogItemMap
                                mapPointType={mapPointType}
                                item={item}
                            />
                        </Grid>
                    </Grid>
                ) : null}
            </Paper>
        </Fragment>
    )
}

export const RoutedCatalogItemDetail = () => {
    const { itemId } = useParams()
    return (
        <CatalogItemDetail
            itemId={itemId ?? ''}
            showReturnToCatalogButton
            showEditAndDeleteButton
            redirectAfterDelete="/catalog"
        />
    )
}

export const CatalogItemDetailDialog: FunctionComponent<
    CatalogItemDetailProps
> = ({ itemId, mapPointType }) => {
    const [open, setOpen] = useState(false)
    return (
        <Fragment>
            <Button variant="contained" onClick={() => setOpen(true)}>
                Detail
            </Button>
            <Dialog
                open={open}
                onClose={() => setOpen(false)}
                fullWidth
                maxWidth="lg"
            >
                <DialogTitle>
                    <Stack direction="row" justifyContent="flex-end">
                        <IconButton onClick={() => setOpen(false)}>
                            <CloseIcon />
                        </IconButton>
                    </Stack>
                </DialogTitle>
                <DialogContent>
                    <CatalogItemDetail
                        mapPointType={mapPointType}
                        itemId={itemId}
                    />
                </DialogContent>
            </Dialog>
        </Fragment>
    )
}

export default CatalogItemDetail
