import { LeafletMouseEvent } from 'leaflet'
import { Fragment, useCallback, useState } from 'react'
import { Popup, useMapEvents } from 'react-leaflet'
import Typography from '@mui/material/Typography'
import { Stack } from '@mui/material'
import AddFromCoordinatesDialog from './AddFromCoordinatesDialog'
import { useSelector } from 'react-redux'
import { RootState } from '../../redux/store'
import ImportLocationDialog from './ImportLocationDialog'

const RightClickPopupMenu = () => {
    const [open, setOpen] = useState(false)
    const [latLng, setLatLng] = useState<[number, number]>([0, 0])
    const path = useSelector(
        (state: RootState) => state.trackingTool.displayedPath
    )

    useMapEvents({
        contextmenu: (e: LeafletMouseEvent) => {
            if (!path) {
                return
            }
            setLatLng([e.latlng.lat, e.latlng.lng])
            setOpen(true)
        },
    })

    const closeContextMenu = useCallback(() => {
        setOpen(false)
    }, [setOpen])

    return (
        <Fragment>
            {open && (
                <Popup onClose={() => setOpen(false)} position={latLng}>
                    <Stack
                        sx={{ p: 0, mt: 0 }}
                        direction="column"
                        justifyItems="center"
                        justifyContent="center"
                    >
                        <Typography
                            style={{ margin: 0 }}
                            sx={{ mb: 0.5 }}
                            align="center"
                        >
                            {latLng[0].toFixed(5)}°{latLng[1].toFixed(5)}°
                        </Typography>
                        <AddFromCoordinatesDialog
                            latLng={latLng}
                            closeContextMenu={closeContextMenu}
                        />
                        <ImportLocationDialog
                            closeContextMenu={closeContextMenu}
                        />
                    </Stack>
                </Popup>
            )}
        </Fragment>
    )
}

export default RightClickPopupMenu
