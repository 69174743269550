import {
    Button,
    Dialog,
    DialogContent,
    DialogTitle,
    Divider,
    Grid,
    IconButton,
    Paper,
    Stack,
    Typography,
} from '@mui/material'
import { Fragment, FunctionComponent, useState } from 'react'
import { ExternalCatalogItemDto } from '../../swagger/data-contracts'
import { formatHtmlStringToReactDom } from '../../utils/Formatting/HtmlUtils'
import CatalogItemMap from './CatalogItemMap'
import CloseIcon from '@mui/icons-material/Close'
import { MapPointType } from '../TrackingTool/trackingToolUtils'

const externalSourceMapping = {
    PLEIADES: 'Pleiades',
    GEONAMES: 'GeoNames',
    CIGS: 'CIGS',
    ANE: 'ANE',
    NA: 'Unknown',
}

/**
 * Creates a renderable component of row of the catalog item - i.e. a row containing Coordinates, All Names, etc.
 * @param rowName name of the row
 * @param items items
 * @param skip skip - if true, null is returned and the component will not render
 * @returns 
 */
const mapToRow = (rowName: string, items: string[], skip: boolean = false) => {
    if (skip) {
        return null
    }

    return (
        <Fragment>
            <Grid sx={{ my: 2 }} container justifyContent="space-around">
                <Grid item xs={8} sx={{ px: 1 }}>
                    <Typography fontWeight={500}>{rowName}</Typography>
                </Grid>
                <Grid item xs={4} sx={{ ml: 'auto' }}>
                    {items.map((item) => (
                        <Typography key={item}>
                            {formatHtmlStringToReactDom(item)}
                        </Typography>
                    ))}
                </Grid>
            </Grid>
        </Fragment>
    )
}

/**
 * Component with detail of the external catalog item
 */
const ExternalCatalogItemDetailDialog: FunctionComponent<{
    item: ExternalCatalogItemDto
}> = ({ item }) => {
    const [open, setOpen] = useState(false)

    // Rows
    const rows = [
        {
            rowName: 'Name',
            items:
                item.names && item.names.length > 0
                    ? [item.names[0]]
                    : ['Name not available'],
        },
        {
            rowName: 'All Names',
            items:
                item.names && item.names.length > 0
                    ? item.names
                    : ['Names not available'],
        },
        {
            rowName: 'State or Territory',
            items: [item?.country],
            skip: !item?.country
        },
        {
            rowName: 'Coordinates',
            // Must be in array otherwise the string gets iterated
            items: [
                item?.longitude !== null && item?.latitude !== null
                    ? `${item?.latitude}°, ${item?.longitude}°`
                    : '-',
            ],
        },
        {
            rowName: 'Location Precision',
            items: [item?.locationPrecision],
            skip: !item?.locationPrecision,
        },
        {
            rowName: 'Accuracy',
            items: [item?.accuracy ?? ''],
            skip: item?.accuracy === null || item?.accuracy === undefined,
        },
        {
            rowName: 'Minimum Date',
            items: [`${item?.minDate}`],
            skip: item?.minDate === null || item?.minDate === undefined,
        },
        {
            rowName: 'Maximum Date',
            items: [`${item?.maxDate}`],
            skip: item?.maxDate === null || item?.maxDate === undefined,
        },
        {
            rowName: 'Time Periods',
            items: [item?.timePeriodKeys ?? ''],
            skip:
                item?.timePeriodKeys === null ||
                item?.timePeriodKeys === undefined,
        },
        {
            rowName: 'PID',
            items: [item?.pid ?? ''],
            skip: item?.pid === null || item?.pid === undefined,
        },
        {
            rowName: 'Feature Code',
            items: [item?.featureCode ?? ''],
            skip: !item?.featureCode,
        },
        {
            rowName: 'Geoname ID',
            items: [item?.geonameId],
            skip: !item?.geonameId,
        },
        {
            rowName: 'Pleiades ID',
            items: [item?.pleiadesId],
            skip: !item?.pleiadesId,
        },
        {
            rowName: 'Source',
            items: [externalSourceMapping[item.externalSource ?? 'NA']],
        },
    ]

    return (
        <Fragment>
            <Button variant="contained" onClick={() => setOpen(true)}>
                Detail
            </Button>
            <Dialog
                open={open}
                onClose={() => setOpen(false)}
                fullWidth
                maxWidth="lg"
            >
                <DialogTitle>
                    <Stack direction="row" justifyContent="flex-end">
                        <IconButton onClick={() => setOpen(false)}>
                            <CloseIcon />
                        </IconButton>
                    </Stack>
                </DialogTitle>
                <DialogContent>
                    <Paper style={{ minHeight: '100vh' }} variant="outlined">
                        {item && (
                            <Grid container justifyContent="space-around">
                                <Grid item xs={12} md={6} sx={{ px: 2 }}>
                                    {rows.map((row, idx) => {
                                        const maxIdx = rows.length - 1
                                        return (
                                            <Fragment>
                                                {mapToRow(
                                                    row.rowName as string,
                                                    row.items as string[],
                                                    row.skip ?? false
                                                )}
                                                {idx === maxIdx || row.skip ? null : (
                                                    <Divider />
                                                )}
                                            </Fragment>
                                        )
                                    })}
                                </Grid>

                                <Grid item md={6} xs={12}>
                                    <CatalogItemMap
                                        mapPointType={MapPointType.ExternalCatalog}
                                        item={{
                                            name:
                                                item.names &&
                                                item.names.length > 0
                                                    ? item.names[0]
                                                    : '',
                                            latitude: item?.latitude,
                                            longitude: item?.longitude,
                                        }}
                                    />
                                </Grid>
                            </Grid>
                        )}
                    </Paper>
                </DialogContent>
            </Dialog>
        </Fragment>
    )
}

export default ExternalCatalogItemDetailDialog
