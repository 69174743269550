import { Card, CardContent, Stack, Typography } from '@mui/material'
import { Fragment } from 'react'
import { useSelector } from 'react-redux'
import { formatHtmlStringToReactDom } from '../../../utils/Formatting/HtmlUtils'
import { RootState } from '../../redux/store'

/**
 * Component used for displaying processed text from the pathDto object in the trackingTool slice
 */
const ProcessedTextDisplay = () => {
    const pathDto = useSelector(
        (state: RootState) => state.trackingTool.pathDto
    )

    return (
        <Fragment>
            {pathDto && (
                <Card variant="outlined" sx={{ maxHeight: '50vh', overflow: 'auto' }}>
                    <CardContent>
                        <Stack direction="column">
                            <Typography
                                variant="h5"
                                sx={{ mb: 1 }}
                                fontWeight="600"
                            >
                                Processed Text
                            </Typography>
                            <Typography variant="body2">
                                {formatHtmlStringToReactDom(pathDto.text ?? '')}
                            </Typography>
                        </Stack>
                    </CardContent>
                </Card>
            )}
        </Fragment>
    )
}

export default ProcessedTextDisplay
