import { createSlice } from '@reduxjs/toolkit'
import { persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import { changePassword, logIn, register } from './userThunks'

export interface UserState {
    accessToken?: string // JWT token to send requests
    refreshToken?: string // refresh token to refresh access token
    username: string // username of the user
    roles: string[] // all user roles - this is used to check if user has access to certain features
    isLoggingIn: boolean // whether the user is logging in
    isLoggedIn: boolean // whether the user is logged in
    lastErr?: string// consumable for errors during thunks
    isRegistered: boolean // whether the user is registered
    isPasswordChanged: boolean // whether the user's password has been changed
}

const persistConfig = {
    key: 'auth',
    storage, // localStorage for browsers
}

// Default state when user first starts the application
const initialState: UserState = {
    roles: [],
    isLoggedIn: false,
    isLoggingIn: false,
    username: '',
    isRegistered: false,
    isPasswordChanged: false,
}

export const userSlice = createSlice({
    name: 'user', // name to generate action types
    initialState, // default state
    // Reducers that update the state
    reducers: {
        logout: () => initialState, // Reset to the inital state
        refreshTokens: (state: UserState, action: any) => ({
            ...state,
            accessToken: action.payload.accessToken,
            refreshToken: action.payload.refreshToken,
        }),
        setErr: (state: UserState, action: any) => ({
            ...state,
            lastErr: action.payload,
        }),
        consumeError: (state: UserState) => ({ ...state, lastErr: undefined }),
        setUserState: (state: UserState, action: any) => ({ ...state, ...action.payload }),
        resetLoggingIn: (state: UserState) => ({ ...state, isLoggingIn: false }),
        resetIsRegistered: (state: UserState) => ({ ...state, isRegistered: false }),
        resetIsPasswordChanged: (state: UserState) => ({ ...state, isPasswordChanged: false }),
    },

    // Thunks
    extraReducers: (builder) => {
        builder.addCase(logIn.fulfilled, (state, action) => {
            return { ...state, ...action.payload, lastErr: undefined }
        })
        builder.addCase(logIn.rejected, (state, action) => {
            if (typeof action.error.message === 'string') {
                return { ...state, lastErr: action.error.message }
            }
        })
        builder.addCase(logIn.pending, (state) => {
            return { ...state, isLoggingIn: true }
        })
        builder.addCase(register.fulfilled, (state, action) => {
            return {...state, isRegistered: true, lastErr: undefined }
        })
        builder.addCase(register.rejected, (state, action) => {
            if (typeof action.error.message === 'string') {
                return { ...state, lastErr: action.error.message }
            }
        })
        builder.addCase(changePassword.fulfilled, (state, action) => {
            return {...state, isPasswordChanged: true, lastErr: undefined}
        })
        builder.addCase(changePassword.rejected, (state, action) => {
            if (typeof action.error.message === 'string') {
                return { ...state, lastErr: action.error.message }
            }
        })
    },
})

const userReducer = persistReducer(persistConfig, userSlice.reducer)

export const { logout, refreshTokens, setErr, setUserState, resetLoggingIn, resetIsRegistered, consumeError, resetIsPasswordChanged } =
    userSlice.actions

export default userReducer
