import { createSlice } from '@reduxjs/toolkit'
import { CatalogItemDto } from '../../swagger/data-contracts'
import {updateCatalogItem, updateCatalogItemsBulk} from "./catalogItemThunks"

export interface CatalogItemState {
    isRequestCompleted: boolean
    loading: boolean // whether the catalog is loading
    error?: string
}

export interface UpdateCatalogItem {
    item: CatalogItemDto
}

export interface UpdateCatalogItemsBulk {
    items: CatalogItemDto[]
}

const initialState: CatalogItemState = {
    isRequestCompleted: false,
    loading: false,
    error: undefined
}

const catalogItemSlice = createSlice({
    name: 'catalogItem',
    initialState,
    reducers: {
        clear: (state: CatalogItemState) => ({ ...initialState }),
        setLoading: (state: CatalogItemState) => ({ ...state, loading: true }),
        resetRequestCompleted: (state: CatalogItemState) => ({ ...state, isRequestCompleted: false }),
        resetLoading: (state: CatalogItemState) => ({ ...state, loading: false }),
        consumeError: (state: CatalogItemState) => ({ ...state, error: undefined }),
    },
    extraReducers: (builder) => {
        builder.addCase(updateCatalogItem.pending, (state: CatalogItemState) => ({
            ...state,
            loading: true,
        }))
        builder.addCase(updateCatalogItem.fulfilled, (state: CatalogItemState, action: any) => ({
            ...state,
            loading: false,
            isRequestCompleted: true
        }))
        builder.addCase(updateCatalogItem.rejected, (state: CatalogItemState, action: any) => ({
            ...state,
            loading: false,
            error: action.error.message as string,
        }))
        builder.addCase(updateCatalogItemsBulk.pending, (state: CatalogItemState) => ({
            ...state,
            loading: true,
        }))
        builder.addCase(updateCatalogItemsBulk.fulfilled, (state: CatalogItemState, action: any) => ({
            ...state,
            loading: false,
            isRequestCompleted: true
        }))
        builder.addCase(updateCatalogItemsBulk.rejected, (state: CatalogItemState, action: any) => ({
            ...state,
            loading: false,
            error: action.error.message as string,
        }))
    },
})

export const {
    clear,
    setLoading,
    resetLoading,
    consumeError,
    resetRequestCompleted,
} = catalogItemSlice.actions
const catalogItemReducer = catalogItemSlice.reducer
export default catalogItemReducer
