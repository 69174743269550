import { LatLngTuple, Marker as MarkerPOJO } from 'leaflet'
import {
    Fragment,
    FunctionComponent,
    ReactNode,
    useMemo,
    useRef,
    useState,
} from 'react'
import { Marker } from 'react-leaflet'
import ConfirmationDialog from '../../Reusables/ConfirmationDialog'
import {
    getMapPointIcon,
    getCustomMapPointIcon,
    MapPoint,
} from '../trackingToolUtils'

export interface MapMarkerProps {
    position: LatLngTuple
    children?: ReactNode
    mapPoint: MapPoint
    customColor?: string
    draggable?: boolean // by default set to false
    updatePositionCallbackFn: (position: LatLngTuple) => void // Callback function to notify MapPath to rerender the path
}

// Custom Map Marker component
const MapMarker: FunctionComponent<MapMarkerProps> = ({
    position,
    children,
    updatePositionCallbackFn,
    mapPoint,
    customColor,
    draggable,
}) => {
    const [currentPosition, setCurrentPosition] = useState(position)
    const markerRef = useRef<MarkerPOJO | null>(null)
    const [openConfirmationDialog, setOpenConfirmationDialog] = useState(false)
    const eventHandlers = useMemo(
        () => ({
            dragend: () => {
                setOpenConfirmationDialog(true)
            },
        }),
        [updatePositionCallbackFn]
    )

    return (
        <Fragment>
            <ConfirmationDialog
                title="Move Map Point"
                open={openConfirmationDialog}
                setOpen={setOpenConfirmationDialog}
                secondaryText="Are you sure you want to move this map point?"
                maxWidth="xs"
                onConfirmCallback={() => {
                    const marker = markerRef.current
                    if (!marker) {
                        return
                    }
                    const latlng = marker.getLatLng()
                    setCurrentPosition([latlng.lat, latlng.lng])
                    updatePositionCallbackFn([latlng.lat, latlng.lng])
                }}
                onCancelCallback={() => {
                    setCurrentPosition(position)
                    updatePositionCallbackFn(position)
                }}
            />
            <Marker
                draggable={draggable}
                position={currentPosition}
                icon={
                    customColor
                        ? getCustomMapPointIcon(customColor)
                        : getMapPointIcon(mapPoint)
                }
                eventHandlers={eventHandlers}
                ref={markerRef}
            >
                {children}
            </Marker>
        </Fragment>
    )
}

export default MapMarker
