import { Fragment, useEffect } from 'react'
import {useDispatch, useSelector} from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { RootState } from '../redux/store'
import LoginDialog from './LoginDialog'
import NotAuthorized from "../NotAuthorized/NotAuthorized"
import RegisterDialog from "./RegisterDialog"
import {resetIsRegistered} from "./userSlice"

// Component for registering a new user
const Register = () => {
    const isAdmin = useSelector(
        (state: RootState) => state.user.roles.includes("ROLE_ADMIN")
    )
    const isRegistered = useSelector(
        (state: RootState) => state.user.isRegistered
    )

    const dispatch = useDispatch()

    // Redirect to home if the user is logged in
    const navigate = useNavigate()
    useEffect(() => {
        if (isRegistered) {
            dispatch(resetIsRegistered())
            navigate('/administration')
        }
    }, [dispatch, isRegistered, navigate])

    return (
        <Fragment>
            {isAdmin ? (<RegisterDialog />) : <NotAuthorized />}
        </Fragment>
    )
}

export default Register
