import { Stack, Typography } from '@mui/material'
import { Fragment, FunctionComponent } from 'react'
import { Popup, Tooltip } from 'react-leaflet'
import { useSelector } from 'react-redux'
import { formatHtmlStringToReactDom } from '../../../utils/Formatting/HtmlUtils'
import { RootState } from '../../redux/store'
import { MapPoint, MapPointType } from '../trackingToolUtils'
import MapMarker from './MapMarker'

const CoordinatesMapMarker: FunctionComponent<{ item: MapPoint }> = ({
    item,
}) => {
    return (
        <MapMarker
            key={item.id}
            position={[
                item.catalogItem.latitude as number,
                item.catalogItem.longitude as number,
            ]}
            mapPoint={item}
            draggable={true}
            updatePositionCallbackFn={() => {}}
        >
            <Fragment>
                <Tooltip>{item.catalogItem.name ?? ''}</Tooltip>
                <Popup>
                    <Fragment>
                        <Stack direction="column" sx={{ m: 0 }}>
                            <Typography
                                variant="h6"
                                fontWeight="bold"
                                align="center"
                                fontSize={16}
                            >
                                {formatHtmlStringToReactDom(
                                    item.catalogItem.name as string
                                )}
                            </Typography>
                            <Typography>{`${item.catalogItem?.latitude ? item.catalogItem?.latitude.toFixed(8) : 0}°, 
                            ${item.catalogItem?.longitude ? item.catalogItem?.longitude.toFixed(8) : 0}°`}</Typography>
                        </Stack>
                    </Fragment>
                </Popup>
            </Fragment>
        </MapMarker>
    )
}

const CoordinatesMapMarkers = () => {
    const coordinatesMapMarkers = useSelector((state: RootState) => state.trackingTool.coordinatesMapPoints)
    const featureEnabled = useSelector((state: RootState) => state.trackingTool.toggleables[MapPointType.FromCoordinates])
    
    return (
        <Fragment>
            {featureEnabled && coordinatesMapMarkers.map((item) => <CoordinatesMapMarker key={item.id} item={item} />)}
        </Fragment>
    )
}

export default CoordinatesMapMarkers
