import { createSlice } from '@reduxjs/toolkit'
import {PermissionDto, UserDto} from '../../swagger/data-contracts'
import {deleteUser, fetchUsers, savePermissions} from './userDetailThunks'
import {number} from "yup"

export interface UsersDetailState {
    users: UserDto[] // list of all fetched items
    loading: boolean // whether the users are loading
    error?: string
    selectedUser?: UserDto
    permissions?: PermissionDto
    isRequestCompleted: boolean
}

const initialState: UsersDetailState = {
    users: [],
    loading: true,
    error: undefined,
    selectedUser: undefined,
    permissions: undefined,
    isRequestCompleted: false
}

const usersDetailSlice = createSlice({
    name: 'usersDetail',
    initialState,
    reducers: {
        clear: (state) => ({ ...initialState }),
        setLoading: (state) => ({ ...state, loading: true }),
        consumeError: (state) => ({ ...state, error: undefined }),
        setSelectedUser: (state, action) => ({
            ...state,
            selectedUser: action.payload
        }),
        setSelectedUserPermissions: (state, action) => ({
            ...state,
            permissions: action.payload
        }),
        resetIsRequestCompleted: (state) => ({
            ...state,
            isRequestCompleted: false
        }),
    },
    extraReducers: (builder) => {
        builder.addCase(fetchUsers.pending, (state) => ({
            ...state,
            loading: true,
        }))
        builder.addCase(fetchUsers.fulfilled, (state, action) => ({
            ...state,
            users: action.payload,
            loading: false,
        }))
        builder.addCase(fetchUsers.rejected, (state, action) => ({
            ...state,
            loading: false,
            error: action.error.message as string,
        }))
        builder.addCase(savePermissions.fulfilled, (state, action) => ({
            ...state,
            isRequestCompleted: true,
        }))
        builder.addCase(deleteUser.fulfilled, (state, action) => ({
            ...state,
            isRequestCompleted: true,
        }))
    },
})

export const {
    clear,
    setLoading,
    consumeError,
    setSelectedUser,
    setSelectedUserPermissions,
    resetIsRequestCompleted
} = usersDetailSlice.actions
const usersDetailReducer = usersDetailSlice.reducer
export default usersDetailReducer
