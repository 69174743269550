import { Alert, AlertColor, Snackbar } from '@mui/material'
import { Fragment, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '../redux/store'
import { consumeNotification } from './notificationSlice'

// Represents notification component that will be displayed on the screen
const Notification = () => {
    const dispatch = useDispatch()
    const notification = useSelector((state: RootState) => state.notification)

    const [displayMessage, setDisplayMessage] = useState('')
    const [open, setOpen] = useState(false)
    const [severity, setSeverity] = useState<AlertColor>('info')

    // Timeout controls when the notification will be hidden
    // This must be killed should the new notification appear before the current one is close
    const [closeTimeout, setCloseTimeout] = useState<NodeJS.Timeout | null>(
        null
    )

    const closeNotification = () => {
        setOpen(false)
        if (closeTimeout) {
            clearTimeout(closeTimeout) // clear timeout if it exists
            setCloseTimeout(null) // and set it to null in our local state
        }
    }

    // Set the message to be displayed if something is set
    useEffect(() => {
        if (notification.message) {
            setDisplayMessage(notification.message)
            setSeverity(notification.severity as AlertColor)
            // Consume the message from store
            dispatch(consumeNotification())

            // Show the message in the notification
            setOpen(true)

            if (closeTimeout) {
                clearTimeout(closeTimeout)
                setCloseTimeout(null)
            }

            // Set the autohide duration
            if (notification.autohideSecs !== undefined) {
                const timeout = setTimeout(() => {
                    setOpen(false)
                    setCloseTimeout(null)
                }, notification.autohideSecs * 1000)
                setCloseTimeout(timeout)
            }
        }
    }, [notification, dispatch, closeTimeout])

    return (
        <Fragment>
            <Snackbar
                open={open}
                autoHideDuration={null}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
            >
                <Alert severity={severity} onClose={closeNotification}>
                    {displayMessage}
                </Alert>
            </Snackbar>
        </Fragment>
    )
}

export default Notification
