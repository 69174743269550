import {
    Button,
    Grid,
    Checkbox,
    Paper,
    Typography,
    FormGroup,
    FormControlLabel
} from '@mui/material'
import React, {Fragment, useEffect, useState} from 'react'
import axiosInstance from "../../api/api"
import 'react-quill/dist/quill.snow.css'
import {PermissionDto, UserDto} from "../../swagger/data-contracts"
import ClearIcon from "@mui/icons-material/Clear"
import CheckIcon from '@mui/icons-material/Check';
import {useDispatch, useSelector} from "react-redux"
import {deleteUser, savePermissions} from "./userDetailThunks"
import {RootState} from "../redux/store"
import {setSelectedUserPermissions} from "./userDetailSlice"
import AddIcon from "@mui/icons-material/Add"
import {Link as RouterLink} from "react-router-dom"
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import ConfirmationDialog from "../Reusables/ConfirmationDialog"
import DeleteForeverIcon from "@mui/icons-material/DeleteForever"

export interface UserDetailProps {
    user: UserDto
}

const UserDetail = (props: UserDetailProps) => {
    const selectedUser: UserDto | undefined = useSelector((state: RootState) => state.usersDetail.selectedUser)
    const [canRead, setCanRead] = useState(selectedUser?.permissions?.canRead)
    const [canWrite, setCanWrite] = useState(selectedUser?.permissions?.canWrite)
    const [canDelete, setCanDelete] = useState(selectedUser?.permissions?.canDelete)
    const [openDeleteCatalogItemDialog, setOpenDeleteCatalogItemDialog] =
        useState(false)

    const dispatch = useDispatch()

    useEffect(()=> {
        setCanRead(selectedUser?.permissions?.canRead)
        setCanWrite(selectedUser?.permissions?.canWrite)
        setCanDelete(selectedUser?.permissions?.canDelete)
    }, [selectedUser]);

    // Maps user property to corresponding table row
    const mapToRow = (rowName: string, item: string) => (
        <Fragment>
            <Grid sx={{my: 2}} container justifyContent="space-around">
                <Grid item xs={4} md={3} lg={2} sx={{px: 1}}>
                    <Typography fontWeight={500}>{rowName}</Typography>
                </Grid>
                <Grid item xs={8} md={9} lg={10} sx={{ml: 'auto'}}>
                    <Typography key={item}>{item}</Typography>
                </Grid>
            </Grid>
        </Fragment>
    )

    const rows = [
        {
            rowName: 'Name:',
            item: [props.user?.name],
        },
        {
            rowName: 'E-mail:',
            item: selectedUser?.email,
        }
    ]
    const rights = {
        canWrite: 'Add and edit locations',
        canDelete: 'Delete locations',
        canRead: 'Access to tracking tool',
    }


    const prepareDeleteUser = async () => {
        dispatch(deleteUser())
    }

    const prepareSavePermissions = () => {
        dispatch(setSelectedUserPermissions({canRead: canRead, canWrite: canWrite, canDelete: canDelete}))
        dispatch(savePermissions())
    }

    return (
        <Fragment>
            <ConfirmationDialog
                open={openDeleteCatalogItemDialog}
                setOpen={setOpenDeleteCatalogItemDialog}
                onConfirmCallback={prepareDeleteUser}
                title="Delete User"
                secondaryText="Are you sure you want to delete this user? This action is irreversible."
                maxWidth="xs"
            />
            <Paper style={{minHeight: '80vh'}} variant="outlined">
                <Grid container justifyContent="space-around">
                    <Grid item xs={12} md={7} sx={{p: 2}}>
                        <Typography fontWeight={800}>Information</Typography>
                        {rows.map((row) => (

                            <Fragment>
                                {mapToRow(
                                    row.rowName as string,
                                    row.item as string
                                )}
                            </Fragment>
                        ))}
                    </Grid>
                    <Grid item xs={12} md={5} sx={{p: 2}}>
                        <Typography fontWeight={800}>Rights</Typography>
                        <FormGroup>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={canWrite}
                                        onChange={e => setCanWrite(e.target.checked)}
                                    />}
                                label={rights.canWrite}/>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={canRead}
                                        onChange={e => setCanRead(e.target.checked)}
                                    />}
                                label={rights.canRead}/>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={canDelete}
                                        onChange={e => setCanDelete(e.target.checked)}
                                    />}
                                label={rights.canDelete}/>
                        </FormGroup>
                    </Grid>
                </Grid>
                <Button startIcon={<CheckIcon />}
                        variant="contained"
                        color="primary"
                        onClick={prepareSavePermissions}
                        sx={{ m: 2 }} >
                    Save
                </Button>
                <Button startIcon={<DeleteForeverIcon />}
                        variant="contained"
                        color="error"
                        onClick={() => setOpenDeleteCatalogItemDialog(true)}
                        sx={{ m: 2 }} >
                    Delete
                </Button>
                <Button startIcon={<ManageAccountsIcon />}
                        variant="contained"
                        component={RouterLink}
                        to="/changePassword"
                        color="primary"
                        sx={{ m: 2 }} >
                    Change Password
                </Button>
            </Paper>
        </Fragment>
    )
}

export default UserDetail