import { createAsyncThunk } from '@reduxjs/toolkit'
import axiosInstance from '../../api/api'
import {UsersDetailState} from "./userDetailSlice"


const apiError = 'Error, server is currently unavailable.'

export const fetchUsers = createAsyncThunk(
    'usersDetail/fetchUsers',
    async (dispatch, { getState }) => {
        try {
            const {data, status} = await axiosInstance.get(
                `/users`
            )
            if (status === 200) {
                return data
            }
            return Promise.reject(apiError)
        } catch (err: any) {
            return Promise.reject(apiError)
        }
    }
)

export const savePermissions = createAsyncThunk(
    'usersDetail/savePermissions',
    async (dispatch, { getState }) => {
        try {
            const { usersDetail } = getState() as { usersDetail: UsersDetailState }

            const selectedUser = usersDetail.selectedUser
            if (selectedUser === undefined) {
                return Promise.reject("User is undefined")
            }

            const selectedUserPermissions = usersDetail.permissions
            if (selectedUserPermissions === undefined) {
                return Promise.reject("Permissions is undefined")
            }

            const selectedUserIdentifier = selectedUser.email

            const {data, status} = await axiosInstance.patch(
                `/users/${selectedUserIdentifier}/permissions`,
                selectedUserPermissions
            )
            if (status === 200) {
                return data
            }
            return Promise.reject(apiError)
        } catch (err: any) {
            return Promise.reject(apiError)
        }
    }
)

export const deleteUser = createAsyncThunk(
    'usersDetail/deleteUser',
    async (dispatch, { getState }) => {
        const { usersDetail } = getState() as { usersDetail: UsersDetailState }

        const selectedUser = usersDetail.selectedUser

        if (selectedUser === undefined) {
            return Promise.reject("User is undefined")
        }
        const { data, status } = await axiosInstance.delete(
            `/users/${selectedUser?.email}`
        )
        if (status !== 200) {
            // TODO dodělat zpracování erroru
            return Promise.reject("Deleting user failed")
        }
        return status
    }
)
