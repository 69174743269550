import { Button } from '@mui/material'
import { Fragment, useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '../../redux/store'
import ConfirmationDialog from '../../Reusables/ConfirmationDialog'
import { saveToCatalog } from '../trackingToolThunks'
import SaveIcon from '@mui/icons-material/Save'
import { consumeSavedToCatalogSuccessfully } from '../trackingToolSlice'
import { showNotification } from '../../Notification/notificationSlice'

/**
 * Component to save displayed path to the database
 */
const SaveDisplayedPath = () => {
    const [dialogOpen, setDialogOpen] = useState(false) // opens / closes the dialog
    const dispatch = useDispatch()

    // disabled flag to prevent user clicking the button multiple times
    const disabled = useSelector(
        (state: RootState) => state.trackingTool.savingToCatalog
    )

    // reference to the path
    const path = useSelector(
        (state: RootState) => state.trackingTool.displayedPath
    )

    const savedToCatalogSuccessfully = useSelector(
        (state: RootState) => state.trackingTool.savedToCatalogSuccess
    )

    const onConfirmCallback = () => {
        if (!path) {
            return
        }
        dispatch(saveToCatalog([...path]))
    }

    // Only users with write privilege can save the path
    const editPrivilege = useSelector((state: RootState) =>
        state.user.roles.includes('WRITE')
    )

    // Use effect to consume savedToCatalogSuccessfully trigger and display notification
    useEffect(() => {
        if (savedToCatalogSuccessfully) {
            dispatch(consumeSavedToCatalogSuccessfully())
            dispatch(
                showNotification({
                    message: 'Path was saved successfully',
                    severity: 'success',
                    autohideSecs: 5,
                })
            )
        }
    }, [dispatch, savedToCatalogSuccessfully])

    return (
        <Fragment>
            {editPrivilege && (
                <Fragment>
                    <Button
                        disabled={disabled}
                        variant="contained"
                        color="success"
                        onClick={() => setDialogOpen(true)}
                        startIcon={<SaveIcon />}
                    >
                        Save
                    </Button>
                    <ConfirmationDialog
                        maxWidth="xs"
                        open={dialogOpen}
                        setOpen={setDialogOpen}
                        title="Save processed path?"
                        secondaryText="Are you sure you want to save the displayed path?"
                        onConfirmCallback={onConfirmCallback}
                    />
                </Fragment>
            )}
        </Fragment>
    )
}

export default SaveDisplayedPath
