import {Fragment, FunctionComponent, useEffect} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { RootState } from '../redux/store'
import ChangePasswordDialog, {ChangePasswordDialogProps} from "./ChangePasswordDialog"
import {UserDto} from "../../swagger/data-contracts"
import {resetIsPasswordChanged, resetIsRegistered} from "../Auth/userSlice"


const ChangePassword = () => {
    const userLoggedIn = useSelector(
        (state: RootState) => state.user.isLoggedIn
    )

    const navigate = useNavigate()

    const selectedUser: UserDto | undefined = useSelector((state: RootState) => state.usersDetail.selectedUser)
    const isAdmin = useSelector(
        (state: RootState) => state.user.roles.includes("ROLE_ADMIN")
    )

    const isPasswordChanged = useSelector(
        (state: RootState) => state.user.isPasswordChanged
    )

    const dispatch = useDispatch()

    useEffect(() => {
        if (isPasswordChanged) {
            dispatch(resetIsPasswordChanged())
            navigate('/administration')
        }
    }, [isPasswordChanged, navigate])

    // Redirect to home if the user is not logged in
    useEffect(() => {
        if (!userLoggedIn) {
            navigate('/')
        }
    }, [userLoggedIn, navigate])

    return (
        <Fragment>
            <ChangePasswordDialog username={selectedUser?.email} isAdmin={isAdmin} />
        </Fragment>
    )
}

export default ChangePassword
