import { applyMiddleware, combineReducers, createStore } from 'redux'
import { persistStore } from 'redux-persist'
import thunk from 'redux-thunk'
import userReducer from '../Auth/userSlice'
import themeReducer from '../Theme/themeSlice'
import catalogReducer from '../Catalog/catalogSlice'
import { composeWithDevTools } from 'redux-devtools-extension'
import notificationReducer from '../Notification/notificationSlice'
import trackingToolReducer from '../TrackingTool/trackingToolSlice'
import usersDetailReducer from '../Administration/userDetailSlice'
import { enableMapSet } from 'immer'
import navigationReducer from '../Navigation/navigationSlice'
import catalogItemReducer from "../Catalog/catalogItemSlice"

enableMapSet()

const composeEnhancers = composeWithDevTools({})

// Store holds shared state in the application to remove the need for passing state to every component
const store = createStore(
    combineReducers({
        user: userReducer,
        theme: themeReducer,
        catalog: catalogReducer,
        notification: notificationReducer,
        trackingTool: trackingToolReducer,
        usersDetail: usersDetailReducer,
        catalogItem: catalogItemReducer,
        navigation: navigationReducer,
    }),
    process.env.REACT_APP_DEV_ENV === 'true'
        ? composeEnhancers(
              // ComposeEnhancers will inject redux-devtools-extension
              applyMiddleware(thunk) // Thunk middleware so we can async fetch data from the api
          )
        : applyMiddleware(thunk)
)

export default store
export const persistor = persistStore(store)
export type AppStore = typeof store
export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch
