import {
    Button,
    Link, Stack,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TablePagination,
    TableRow,
    Typography,
} from '@mui/material'
import { Fragment, useEffect, useState } from 'react'
import { Link as RouterLink } from 'react-router-dom'
import { CatalogItemDto } from '../../swagger/data-contracts'
import ShowErrorIfPresent from '../Reusables/ShowErrorIfPresent'
import ContentLoading from '../Reusables/ContentLoading'
import { RootState } from '../redux/store'
import { useDispatch, useSelector } from 'react-redux'
import {
    consumeError,
    setLoading,
    setRowsPerPage,
    ShowAllItemsOption,
} from './catalogSlice'
import { fetchItems } from './catalogThunks'
import { formatHtmlStringToReactDom } from '../../utils/Formatting/HtmlUtils'
import { showNotification } from '../Notification/notificationSlice'
// @ts-ignore
import { CSVLink } from "react-csv";
import Moment from 'moment'
import DownloadIcon from '@mui/icons-material/Download';

// Catalog table component
const CatalogTable = () => {
    const [page, setPage] = useState(0) // currently shown page

    const dispatch = useDispatch()

    // Rows per page
    const rowsPerPageOptions = useSelector(
        (state: RootState) => state.catalog.rowsPerPageOptions
    )
    const rowsPerPage = useSelector(
        (state: RootState) => state.catalog.rowsPerPage
    )

    // Items, loading and error from api
    const items = useSelector((state: RootState) => state.catalog.items)
    const loading = useSelector((state: RootState) => state.catalog.loading)
    const apiError = useSelector((state: RootState) => state.catalog.error)

    // Local state to display any error relevant error
    const [displayError, setDisplayError] = useState<string | undefined>(
        undefined
    )
    const [unload, setUnload] = useState(true)

    // When changing rows per page set the selected number and reset to the first page
    const onRowsPerPageChange = (
        event: React.ChangeEvent<HTMLInputElement>
    ) => {
        dispatch(setRowsPerPage(Number(event.target.value)))
        setPage(0)
    }

    useEffect(() => {
        // Fetch items when the component is mounted
        // This will automatically search whenever the filter changes
        dispatch(fetchItems())

        return () => {
            // Invalidate the state when unmounting so that the old list is not rerendered when the user returns to the page
            dispatch(setLoading())
        }
    }, [dispatch])

    // Use effect to read the error and consume it
    useEffect(() => {
        if (apiError) {
            setDisplayError(apiError)
            dispatch(consumeError())
        }
    }, [apiError, dispatch])

    // Name of columns in the header
    const columns = [
        'Name',
        'All Names',
        'Written Form',
        'Type',
        'State or Territory',
        'Coordinates',
        'Certainty',
    ]

    //order,name,alt,cer,lat,lon,writ,type,countr,ha,bibl
    const headersCSV = [
        { label: 'name', key: 'name' },
        { label: 'alt', key: 'allNames' },
        { label: 'cer', key: 'certainty' },
        { label: 'lat', key: 'latitude' },
        { label: 'lon', key: 'longitude' },
        { label: 'writ', key: 'writtenForms' },
        { label: 'type', key: 'types' },
        { label: 'countr', key: 'countries' },
        { label: 'bibl', key: 'bibliography' },
        { label: 'desc', key: 'description' },
    ]

    const mapValueOrDefault = (value?: string, textStyle?: any) => (
        <TableCell align="left">
            <Typography
                sx={{
                    ...textStyle,
                }}
            >
                {formatHtmlStringToReactDom(value as string)}
            </Typography>
        </TableCell>
    )

    // Maps catalogItem to corresponding table row
    const mapItemColumnValues = (item: CatalogItemDto) => (
        <Fragment>
            {/* {mapValueOrDefault(item.name)} */}
            <TableCell align="left" sx={{ maxWidth: '20vw' }}>
                <Link
                    style={{ wordWrap: 'break-word' }}
                    component={RouterLink}
                    to={`/catalog/${item.id as string}`}
                    onClick={() => setUnload(false)}
                >
                    {item.name}
                </Link>
            </TableCell>
            {mapValueOrDefault(item.allNames?.join(', '), {
                display: '-webkit-box',
                overflow: 'hidden',
                WebkitBoxOrient: 'vertical',
                wordBreak: 'break-all',
                WebkitLineClamp: 2,
            })}
            {mapValueOrDefault(item.writtenForms?.join(', '))}
            {mapValueOrDefault(item.types?.join(', '))}
            {mapValueOrDefault(item.countries?.join(', '))}
            {mapValueOrDefault(
                item.latitude && item.longitude
                    ? `${item.latitude}, ${item.longitude}`
                    : undefined
            )}
            {mapValueOrDefault(
                item.certainty ? `${item.certainty}` : undefined
            )}
        </Fragment>
    )

    return (
        <Fragment>
            <ShowErrorIfPresent err={displayError} />
            {loading && !displayError ? <ContentLoading /> : null}
            {!loading && !displayError ? (
                <Fragment>
                    <TableContainer
                        sx={{ minHeight: '60vh', maxHeight: '60vh' }}
                    >
                        <Table
                            stickyHeader
                            sx={{ minWidth: 400 }}
                            aria-label="catalogTable"
                        >
                            <TableHead>
                                <TableRow>
                                    {columns.map((col, idx) => (
                                        <TableCell key={idx} align="left">
                                            {col}
                                        </TableCell>
                                    ))}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {items
                                    .slice(
                                        page * rowsPerPage,
                                        page * rowsPerPage + rowsPerPage
                                    )
                                    .map((row, idx) => (
                                        <TableRow
                                            hover
                                            tabIndex={-1}
                                            key={row.id}
                                        >
                                            {mapItemColumnValues(row)}
                                        </TableRow>
                                    ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <TablePagination
                        rowsPerPageOptions={rowsPerPageOptions.map((item) => ({
                            value:
                                item === ShowAllItemsOption
                                    ? items.length
                                    : item,
                            label: item as string,
                        }))}
                        component="div"
                        count={items.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={(_, newPage) => setPage(newPage)}
                        onRowsPerPageChange={onRowsPerPageChange}
                    />
                    <Stack
                        direction="row"
                    >

                            <CSVLink style={{color: 'rgba(69,69,69,0)'}} data={items} headers={headersCSV} filename={`catalog-${Moment().format('DD-MM-YYYY')}.csv`} >
                                <Button
                                    startIcon={<DownloadIcon />}
                                    variant="contained"
                                    color="primary"
                                >
                                    Download CSV
                                </Button>
                            </CSVLink>

                    </Stack>
                </Fragment>
            ) : null}
        </Fragment>
    )
}

export default CatalogTable
