import { PaletteMode } from '@mui/material'
import { createTheme, Theme, ThemeProvider } from '@mui/material/styles'
import { FunctionComponent, ReactNode, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { RootState } from '../redux/store'

export interface ThemeWrapperProps {
    children: ReactNode
}

export const lightThemePalette = {
    primary: {
        main: '#A28253',
    },
    secondary: {
        main: '#E3DBCF',
    },
    selected: {
        main: '#C0C0C0',
    },
}

export const darkThemePalette = {
    primary: {
        main: '#e0bb84',
    },
    secondary: {
        main: '#E3DBCF',
    },
}

export const getPalette = (paletteMode: PaletteMode) => {
    switch (paletteMode) {
        case 'light':
            return lightThemePalette
        case 'dark':
            return darkThemePalette
        default:
            return lightThemePalette
    }
}

export const buildTheme = (paletteMode: PaletteMode) =>
    createTheme({
        palette: {
            mode: paletteMode,
            ...getPalette(paletteMode),
        },
        shape: {
            borderRadius: 8,
        },
        typography: {
            fontFamily: [
                '-apple-system',
                'BlinkMacSystemFont',
                '"Segoe UI"',
                'Roboto',
                '"Helvetica Neue"',
                'Arial',
                'sans-serif',
                '"Apple Color Emoji"',
                '"Segoe UI Emoji"',
                '"Segoe UI Symbol"',
            ].join(','),
        },
    })

/**
 * Wraps children in a ThemeProvider with the correct theme
 */
const ThemeWrapper: FunctionComponent<ThemeWrapperProps> = ({ children }) => {
    const paletteMode = useSelector(
        (state: RootState) => state.theme.paletteMode
    )

    const [theme, setTheme] = useState<Theme>(buildTheme(paletteMode))
    useEffect(() => {
        setTheme(() => {
            return buildTheme(paletteMode)
        })
    }, [paletteMode])

    return <ThemeProvider theme={theme}>{children}</ThemeProvider>
}

export default ThemeWrapper
