import { Box, Typography } from '@mui/material'
import { Fragment, FunctionComponent } from 'react'
import { MapContainer, Marker, Popup, TileLayer } from 'react-leaflet'
import mapConfig from '../../config/mapConfig'
import { CatalogItemDto } from '../../swagger/data-contracts'
import {
    MapPointType,
    getMapPointIcon,
} from '../TrackingTool/trackingToolUtils'

/**
 * A simple map with a the catalog item marker and popup - if its coordinates are set
 */
const CatalogItemMap: FunctionComponent<{
    item: CatalogItemDto
    mapPointType?: MapPointType
}> = ({ item, mapPointType }) => {
    const [lat, long] = [
        item.longitude ?? mapConfig.defaultCoordinates[0],
        item.latitude ?? mapConfig.defaultCoordinates[1],
    ]

    return (
        <Fragment>
            <Box
                sx={{ px: 2, py: 4 }}
                style={{
                    height: '100%',
                    minHeight: '450px',
                    maxHeight: '70vh',
                }}
            >
                <MapContainer
                    center={[long, lat]}
                    zoom={7}
                    style={{ height: '100%', minHeight: '100%' }}
                >
                    <TileLayer
                        attribution={mapConfig.attribution}
                        url={mapConfig.url}
                    />
                    {!item.longitude || !item.latitude ? null : (
                        <Marker
                            position={[long, lat]}
                            icon={getMapPointIcon({
                                type: !mapPointType
                                    ? MapPointType.ProcessedText
                                    : mapPointType,
                            } as any)}
                        >
                            <Popup>{item.name}</Popup>
                        </Marker>
                    )}
                </MapContainer>
                {!item.longitude || !item.latitude ? (
                    <Typography color="error" align="center" fontWeight="bold">
                        Location Unavailable
                    </Typography>
                ) : null}
            </Box>
        </Fragment>
    )
}

export default CatalogItemMap
