import { Button, Grid, Stack, Typography } from '@mui/material'
import { Fragment, useEffect, useState } from 'react'
import PlaintextUpload from './Upload/PlaintextUpload'
import FileUpload from './Upload/FileUpload'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '../redux/store'
import {
    clear as clearTrackingTool,
    consumeErr as consumeError,
    toggleToggleables,
} from './trackingToolSlice'
import { showNotification } from '../Notification/notificationSlice'
import ClearIcon from '@mui/icons-material/Clear'
import ExternalPathExportButton from './ExternalPath/ExternalPathExportButton'
import ProcessedTextDisplay from './Controls/ProcessedTextDisplay'
import MapPointToggleables from './Controls/MapPointToggleables'
import ExternalPathImportDialog from './ExternalPath/ExternalPathImportDialog'
import Map from './Map/Map'
import ReorderableMapPointList from './Controls/ReorderableMapPointList'
import FilterListIcon from '@mui/icons-material/FilterList'
import FilterListOffIcon from '@mui/icons-material/FilterListOff'
import SaveDisplayedPath from './Controls/SaveDisplayedPath'
import { useNavigate } from 'react-router-dom'
import ConfirmationDialog from '../Reusables/ConfirmationDialog'
import ManageFilePaths from './Controls/ManageFilePaths'

// Page with tracking tool
const TrackingTool = () => {
    const pathDto = useSelector(
        // Path response from the API
        (state: RootState) => state.trackingTool.pathDto
    )

    // Use path as flag for undefined or present
    const path = useSelector(
        (state: RootState) => state.trackingTool.displayedPath
    )

    const toggleablesOpen = useSelector(
        (state: RootState) => state.trackingTool.toggleablesOpen
    )

    const userLoggedIn = useSelector(
        (state: RootState) => state.user.isLoggedIn
    )
    const trackingToolPermission = useSelector((state: RootState) => state.user.roles.includes('READ'))
    const navigate = useNavigate()
    useEffect(() => {
        if (!userLoggedIn || !trackingToolPermission) {
            navigate('/')
        }
    }, [userLoggedIn, navigate, trackingToolPermission])

    // Consume any error
    const dispatch = useDispatch()
    const err = useSelector((state: RootState) => state.trackingTool.lastError)
    useEffect(() => {
        if (!err) {
            return
        }
        const error = `${err}`
        dispatch(consumeError())
        dispatch(
            showNotification({
                message: error,
                severity: 'error',
            })
        )
    }, [err, dispatch])

    const [showClearPathDialog, setShowClearPathDialog] = useState(false)

    return (
        <Fragment>
            <Typography variant="h3" sx={{ mb: 2 }} fontWeight="bold">
                Tracking Tool
            </Typography>

            <Grid container>
                <Grid item xs={12} alignItems="center">
                    {pathDto && pathDto?.foundCatalogItems?.length === 0 && (
                        <Typography
                            variant="body1"
                            sx={{ mb: 2 }}
                            fontWeight="500"
                            align="center"
                            color="error.main"
                        >
                            Looks like no path / catalog items match the
                            uploaded text.
                        </Typography>
                    )}

                    {!pathDto && (
                        <Fragment>
                            <Stack
                                direction="row"
                                alignItems="center"
                                spacing={1}
                                sx={{ my: 1 }}
                            >
                                <Typography variant="h6" fontWeight="500">
                                    Upload:
                                </Typography>
                                <Fragment>
                                    <PlaintextUpload />
                                    <FileUpload />
                                </Fragment>
                            </Stack>
                        </Fragment>
                    )}
                    {path && (
                        <Grid
                            container
                            direction="row"
                            sx={{ my: 1 }}
                            spacing={1}
                            // justifyContent="space-between"
                        >
                            <Grid
                                container
                                item
                                xs={12}
                                md={6}
                                direction="row"
                                spacing={1}
                            >
                                <Grid item>
                                    <Button
                                        startIcon={
                                            toggleablesOpen ? (
                                                <FilterListOffIcon />
                                            ) : (
                                                <FilterListIcon />
                                            )
                                        }
                                        variant="contained"
                                        color="primary"
                                        onClick={() => {
                                            dispatch(toggleToggleables())
                                        }}
                                    >
                                        Filter
                                    </Button>
                                </Grid>
                                <Grid item>
                                    <Button
                                        startIcon={<ClearIcon />}
                                        variant="contained"
                                        onClick={() => {
                                            setShowClearPathDialog(true)
                                        }}
                                    >
                                        Clear Map
                                    </Button>
                                    <ConfirmationDialog
                                        open={showClearPathDialog}
                                        setOpen={setShowClearPathDialog}
                                        onConfirmCallback={() => {
                                            dispatch(clearTrackingTool())
                                        }}
                                        title="Clear Map"
                                        secondaryText="Are you sure you want to clear the map? All data will be discarded."
                                        maxWidth="xs"
                                    />
                                </Grid>
                            </Grid>
                            <Grid
                                item
                                container
                                md={6}
                                xs={12}
                                direction="row"
                                spacing={1}
                                justifyContent="flex-end"
                            >
                                <Grid item>
                                    <ManageFilePaths />
                                </Grid>
                                <Grid item>
                                    <ExternalPathImportDialog />
                                </Grid>
                                <Grid item>
                                    <ExternalPathExportButton />
                                </Grid>
                                <Grid item>
                                    <SaveDisplayedPath />
                                </Grid>
                            </Grid>
                        </Grid>
                    )}

                    {pathDto && (
                        <Fragment>
                            <MapPointToggleables />
                        </Fragment>
                    )}
                </Grid>

                <Grid
                    item
                    xs={12}
                    md={12}
                    style={{
                        minHeight: '60vh',
                        maxHeight: '100vh',
                        width: '100%',
                    }}
                >
                    <Map />
                </Grid>
                <Grid container sx={{ mt: 1, mb: 20 }} spacing={1}>
                    <Grid item xs={12} md={6} lg={8}>
                        <ProcessedTextDisplay />
                    </Grid>
                    <Grid item xs={12} md={6} lg={4}>
                        <ReorderableMapPointList />
                    </Grid>
                </Grid>
            </Grid>
        </Fragment>
    )
}

export default TrackingTool
