
// Map configuration interface
export interface MapConfig {
    attribution: string,
    url: string
    defaultCoordinates: number[] // pair of numbers
    defaultZoom: number
}

const mapConfig: MapConfig = {
    attribution: '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors',
    url: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
    defaultCoordinates: [33.5138, 36.2765], // Damascus, Syria
    defaultZoom: 6
}

export default mapConfig
