import {
    Button,
    DialogContent,
    DialogTitle,
    FormControl,
    Grid,
    IconButton,
    InputLabel,
    MenuItem,
    Paper,
    Select,
    Stack,
    TextField,
} from '@mui/material'
import {
    Fragment,
    FunctionComponent,
    useCallback,
    useEffect,
    useState,
} from 'react'
import ButtonOpenableDialog from '../../Reusables/ButtonOpenableDialog'
import * as yup from 'yup'
import { useFormik } from 'formik'
import ThemeWrapper from '../../Theme/ThemeWrapper'
import CloseIcon from '@mui/icons-material/Close'
import { useDispatch, useSelector } from 'react-redux'
import {
    importFromExternalCatalog,
    importFromLocalCatalog,
} from '../trackingToolThunks'
import { RootState } from '../../redux/store'
import { resetDialogApiCallSuccess } from '../trackingToolSlice'

const importTypes = {
    localCatalog: 'Local Catalog',
    websiteCatalogs: 'Website Catalogs',
}

const externalCatalogs = {
    PLEIADES: 'Pleiades',
    GEONAMES: 'Geonames',
    CIGS: 'CIGS',
    ANE: 'ANE',
}

interface ImportData {
    name: string // used always
    type: string
    writtenForm: ''
    country: ''
    externalCatalogType?: string // only used for website catalogs variant
}

const ImportLocationDialog: FunctionComponent<{
    closeContextMenu: () => void
}> = ({ closeContextMenu }) => {
    const dispatch = useDispatch()
    const [open, setOpen] = useState(false)
    const [importType, setImportType] = useState('localCatalog')

    const dialogApiCallSuccess = useSelector(
        (state: RootState) => state.trackingTool.dialogApiCallSuccess
    )

    const formik = useFormik({
        initialValues: {
            name: '',
            type: '',
            writtenForm: '',
            country: '',
            externalCatalogType: 'PLEIADES',
        } as ImportData,
        validationSchema: yup.object().shape({
            name: yup.string().required('Name is required'),
        }),
        onSubmit: async (values: ImportData) => {
            if (importType === 'localCatalog') {
                values.externalCatalogType = undefined
                dispatch(importFromLocalCatalog({ ...values }))
                return
            }

            dispatch(
                importFromExternalCatalog({
                    name: values.name,
                    source: values.externalCatalogType as string,
                })
            )
        },
    })

    const onClose = useCallback(() => {
        formik.resetForm()
        setOpen(false)
        closeContextMenu()
    }, [closeContextMenu, formik])

    useEffect(() => {
        if (dialogApiCallSuccess) {
            dispatch(resetDialogApiCallSuccess())
            onClose()
            return
        }
    }, [dialogApiCallSuccess, dispatch, onClose])

    const NameTextField = (
        <TextField
            sx={{ mt: 1 }}
            fullWidth
            label="Name"
            name="name"
            size="small"
            value={formik.values.name}
            onChange={formik.handleChange}
            variant="outlined"
            error={Boolean(formik.errors.name) && formik.touched.name}
            helperText={formik.errors.name && formik.touched.name}
        />
    )

    return (
        <ButtonOpenableDialog
            buttonText="Import Location"
            buttonColor="primary"
            buttonVariant="text"
            onCloseCallback={onClose}
            maxWidth="xs"
            open={open}
            setOpen={setOpen}
            size="small"
            onOpenCallback={() => {}}
        >
            <ThemeWrapper>
                <Paper>
                    <form onSubmit={formik.handleSubmit}>
                        <DialogTitle>
                            <Stack
                                direction="row"
                                justifyContent="space-between"
                                alignItems="center"
                                spacing={1}
                            >
                                <Fragment>Import Locations</Fragment>
                                <IconButton onClick={onClose}>
                                    <CloseIcon />
                                </IconButton>
                            </Stack>
                        </DialogTitle>
                        <DialogContent>
                            <Grid
                                container
                                sx={{ mt: 1 }}
                                // spacing={1}
                                justifyContent="space-around"
                                alignItems="center"
                            >
                                <Grid item xs={6} md={9}>
                                    <FormControl fullWidth>
                                        <InputLabel id="importType">
                                            Import
                                        </InputLabel>
                                        <Select
                                            labelId="importType"
                                            id="importTypeSelect"
                                            value={importType}
                                            label="Import"
                                            size="small"
                                            onChange={(e) =>
                                                setImportType(e.target.value)
                                            }
                                        >
                                            {Object.keys(importTypes).map(
                                                (key) => (
                                                    <MenuItem
                                                        key={key}
                                                        value={key}
                                                    >
                                                        {
                                                            //@ts-ignore
                                                            importTypes[key]
                                                        }
                                                    </MenuItem>
                                                )
                                            )}
                                        </Select>
                                    </FormControl>
                                </Grid>
                                <Grid
                                    item
                                    container
                                    xs={6}
                                    md={3}
                                    justifyContent="flex-end"
                                >
                                    <Button
                                        variant="contained"
                                        type="submit"
                                        color="primary"
                                    >
                                        Import
                                    </Button>
                                </Grid>
                                {importType === 'localCatalog' ? (
                                    <Fragment>
                                        {NameTextField}
                                        <TextField
                                            sx={{ mt: 1 }}
                                            fullWidth
                                            label="Type"
                                            name="type"
                                            size="small"
                                            value={formik.values.type}
                                            onChange={formik.handleChange}
                                            variant="outlined"
                                            error={
                                                Boolean(formik.errors.type) &&
                                                formik.touched.type
                                            }
                                            helperText={
                                                formik.errors.type &&
                                                formik.touched.type
                                            }
                                        />
                                        <TextField
                                            sx={{ mt: 1 }}
                                            fullWidth
                                            size="small"
                                            id="writtenForm"
                                            label="Written Form"
                                            value={formik.values.writtenForm}
                                            onChange={formik.handleChange}
                                            variant="outlined"
                                            error={
                                                Boolean(
                                                    formik.errors.writtenForm
                                                ) && formik.touched.writtenForm
                                            }
                                            helperText={
                                                formik.errors.writtenForm &&
                                                formik.touched.writtenForm
                                            }
                                        />
                                        <TextField
                                            sx={{ mt: 1 }}
                                            fullWidth
                                            size="small"
                                            id="country"
                                            label="State or Territory"
                                            value={formik.values.country}
                                            onChange={formik.handleChange}
                                            variant="outlined"
                                            error={
                                                Boolean(
                                                    formik.errors.country
                                                ) && formik.touched.country
                                            }
                                            helperText={
                                                formik.errors.country &&
                                                formik.touched.country
                                            }
                                        />
                                    </Fragment>
                                ) : (
                                    <Fragment>
                                        <FormControl
                                            fullWidth
                                            variant="outlined"
                                            sx={{ mt: 1 }}
                                        >
                                            <InputLabel id="externalCatalogType">
                                                Catalog Type
                                            </InputLabel>
                                            <Select
                                                labelId="externalCatalogType"
                                                name="externalCatalogType"
                                                value={
                                                    formik.values
                                                        .externalCatalogType ||
                                                    'PLEIADES'
                                                }
                                                onChange={formik.handleChange}
                                                label="Catalog Type"
                                                size="small"
                                            >
                                                {Object.entries(
                                                    externalCatalogs
                                                ).map(([key, value], idx) => (
                                                    <MenuItem
                                                        key={key}
                                                        value={key}
                                                    >
                                                        {value}
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                        </FormControl>
                                        {NameTextField}
                                    </Fragment>
                                )}
                            </Grid>
                        </DialogContent>
                    </form>
                </Paper>
            </ThemeWrapper>
        </ButtonOpenableDialog>
    )
}

export default ImportLocationDialog
