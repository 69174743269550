// Virtuoso's resize observer can this error,

import { Paper } from '@mui/material'
import { Fragment, ReactNode, useEffect, useState } from 'react'
import {
    DragDropContext,
    Draggable,
    Droppable,
    DropResult,
} from 'react-beautiful-dnd'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '../../redux/store'
import { changeMapPointIdx } from '../trackingToolSlice'
import ReorderableMapPointItem from './ReorderableMapPointItem'
import { Virtuoso } from 'react-virtuoso'

// which is caught by DnD and aborts dragging.
window.addEventListener('error', (e) => {
    if (
        e.message ===
            'ResizeObserver loop completed with undelivered notifications.' ||
        e.message === 'ResizeObserver loop limit exceeded'
    ) {
        e.stopImmediatePropagation()
    }
})

interface HeightPreservingRowProps {
    children: ReactNode
    props: any
}

// A row that preserves its height when it is dragged
const HeightPreservingRow = ({
    children,
    ...props
}: HeightPreservingRowProps) => {
    const [size, setSize] = useState(0)
    // @ts-ignore
    const knownSize = props['data-known-size']
    useEffect(() => {
        setSize((prevSize) => {
            return knownSize === 0 ? prevSize : knownSize
        })
    }, [knownSize])
    return (
        <div
            {...props}
            className="height-preserving-container"
            // check styling in the style tag below
            // @ts-ignore
            style={{ '--child-height': `${size}px` }}
        >
            {children}
        </div>
    )
}

const ReorderableMapPointList = () => {
    const dispatch = useDispatch()
    const path = useSelector(
        (state: RootState) => state.trackingTool.displayedPath
    )

    const onDragEnd = ({ source, destination }: DropResult) => {
        if (!destination || source.index === destination.index) {
            return
        }

        dispatch(
            changeMapPointIdx({
                destination: destination.index,
                source: source.index,
            })
        )
    }

    return (
        <Fragment>
            <style>
                {`
          .height-preserving-container:empty {
            min-height: calc(var(--child-height));
            box-sizing: border-box;
          }
      `}
            </style>
            {path && path.length > 0 && (
                <Paper variant="outlined">
                    <DragDropContext onDragEnd={onDragEnd}>
                        <Droppable
                            droppableId="droppable"
                            mode="virtual"
                            renderClone={(provided, _, rubric) => (
                                <ReorderableMapPointItem
                                    idx={rubric.source.index}
                                    provided={provided}
                                    item={path[rubric.source.index]}
                                />
                            )}
                        >
                            {(provided) => (
                                <Virtuoso
                                    components={{
                                        //@ts-ignore
                                        Item: HeightPreservingRow,
                                    }}
                                    //@ts-ignore
                                    scrollerRef={provided.innerRef}
                                    data={path}
                                    style={{
                                        height: '50vh',
                                        width: '100%',
                                    }}
                                    itemContent={(index, item) => (
                                        <Draggable
                                            draggableId={item.reactId}
                                            index={index}
                                            key={item.reactId}
                                        >
                                            {(provided) => (
                                                <ReorderableMapPointItem
                                                    idx={index}
                                                    provided={provided}
                                                    item={item}
                                                />
                                            )}
                                        </Draggable>
                                    )}
                                >
                                    {provided.placeholder}
                                </Virtuoso>
                            )}
                        </Droppable>
                    </DragDropContext>
                </Paper>
            )}
        </Fragment>
    )
}

export default ReorderableMapPointList
