import { AlertColor } from '@mui/material'
import { Action, createSlice } from '@reduxjs/toolkit'

export interface NotificationState {
    message?: string // message to display
    severity: AlertColor // severity of the message - color of the notification
    autohideSecs?: number // number of seconds before the message disappears, if this is not set the message will stay until dismissed
}

const initialState: NotificationState = {
    message: undefined,
    severity: 'info',
    autohideSecs: undefined
}

const notificationSlice = createSlice({
    name: 'notification',
    initialState,
    reducers: {
        showNotification: (state: NotificationState, action: { payload: NotificationState }) => ({
            ...state,
            message: action.payload.message,
            severity: action.payload.severity,
            autohideSecs: action.payload.autohideSecs,
        }),
        // consumes the message so it is not displayed after the page gets refreshed
        consumeNotification: () => ({
            ...initialState,
        }),
    },
})

const notificationReducer = notificationSlice.reducer
export const { showNotification, consumeNotification } =
    notificationSlice.actions
export default notificationReducer
