import { createAsyncThunk } from '@reduxjs/toolkit'
import axiosInstance from '../../api/api'
import { UpdateCatalogItem, UpdateCatalogItemsBulk } from './catalogItemSlice'
import { updateCatalogItem as updateCatalogItemTrackingTool } from '../TrackingTool/trackingToolSlice'
const apiError = 'Error, server is currently unavailable.'

// Thunk to update catalog item using API
export const updateCatalogItem = createAsyncThunk(
    'catalogItem/update',
    async (catalogItem: UpdateCatalogItem, { dispatch }) => {
        try {
            // Send request with the filter
            const { data, status } = await axiosInstance.post(
                '/catalog-items',
                catalogItem.item
            )

            // If the request was successful return the items
            if (status === 200) {
                dispatch(updateCatalogItemTrackingTool(catalogItem.item))
                return status
            }

            return Promise.reject(apiError)
        } catch (err: any) {
            return Promise.reject(err.response.data)
        }
    }
)

// Thunk to update catalog item using API
export const updateCatalogItemsBulk = createAsyncThunk(
    'catalogItem/updateBulk',
    async (bulkItems: UpdateCatalogItemsBulk) => {
        try {
            // Send request with the filter
            const { data, status } = await axiosInstance.post(
                '/catalog-items/batch',
                bulkItems.items
            )

            // If the request was successful return the items
            if (status === 200) {
                return data
            }

            return Promise.reject(apiError)
        } catch (err: any) {
            return Promise.reject(err.response.data)
        }
    }
)
