import {
    Button,
    Dialog,
    DialogContent,
    DialogTitle,
    IconButton,
    Stack,
    Typography,
} from '@mui/material'
import { Fragment, useEffect, useState } from 'react'
import AddIcon from '@mui/icons-material/Add'
import { RootState } from '../redux/store'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { CreateCatalogItem } from './EditCatalogItem'
import CloseIcon from '@mui/icons-material/Close'
import { showNotification } from '../Notification/notificationSlice'
import { fetchItems } from './catalogThunks'

/**
 * Component for adding a new location to the catalog
 * This displays a button (if the user has permission) and opens a dialog where user can fill out new item
 */
const AddNewLocationDialog = () => {
    // user must have write permission to add a new location
    const hasWritePermission = useSelector((state: RootState) =>
        state.user.roles.includes('WRITE')
    )

    // dialog controls
    const [dialogOpen, setDialogOpen] = useState(false)
    const dispatch = useDispatch()

    return (
        <Fragment>
            {hasWritePermission && (
                <Fragment>
                    <Button
                        startIcon={<AddIcon />}
                        variant="outlined"
                        onClick={() => setDialogOpen(true)}
                    >
                        Add Location
                    </Button>
                    <Dialog
                        open={dialogOpen}
                        onClose={() => setDialogOpen(false)}
                        fullWidth
                        maxWidth="xl"
                    >
                        <DialogTitle>
                            <Stack
                                direction="row"
                                justifyContent="space-between"
                                alignItems="center"
                            >
                                <Typography variant="h6" fontWeight="bold">
                                    Create new Catalog Item
                                </Typography>
                                <IconButton
                                    onClick={() => setDialogOpen(false)}
                                >
                                    <CloseIcon />
                                </IconButton>
                            </Stack>
                        </DialogTitle>
                        <DialogContent>
                            <CreateCatalogItem
                                item={{}}
                                isItemLoading={false}
                                itemId="" // here we can safely ignore the id because we are creating new item
                                loading={false}
                                onRequestCompletedCallback={() => {
                                    dispatch(
                                        showNotification({
                                            message:
                                                'Catalog item was added successfully',
                                            severity: 'success',
                                            autohideSecs: 5,
                                        })
                                    )
                                    dispatch(fetchItems())
                                    setDialogOpen(false)
                                }}
                            />
                        </DialogContent>
                    </Dialog>
                </Fragment>
            )}
        </Fragment>
    )
}

export default AddNewLocationDialog
