import React from 'react'
import ReactDOM from 'react-dom'
import './index.css'
import App from './App'
import reportWebVitals from './reportWebVitals'
import { BrowserRouter } from 'react-router-dom'
import store, { persistor } from './features/redux/store'
import { Provider } from 'react-redux'
import { injectStore } from './api/api'
import { PersistGate } from 'redux-persist/integration/react'

// Injects store to the axios instance in ./api/axiosInstance
injectStore(store)

ReactDOM.render(
    <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
            {/* <React.StrictMode> */}
                <BrowserRouter>
                    <App />
                </BrowserRouter>
            {/* </React.StrictMode> */}
        </PersistGate>
    </Provider>,
    document.getElementById('root')
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
