import { Fragment, FunctionComponent, useEffect, useState } from 'react'
import Dialog, { DialogProps } from '@mui/material/Dialog'
import {
    Button,
    DialogContent,
    DialogTitle,
    IconButton,
    Link,
    Stack,
    TextField,
    Typography,
} from '@mui/material'
import { useFormik } from 'formik'
import * as yup from 'yup'
import { useDispatch, useSelector } from 'react-redux'
import { Link as RouterLink, useNavigate } from 'react-router-dom'
import { RootState } from '../redux/store'
import { Box } from '@mui/system'
import ShowErrorIfPresent from '../Reusables/ShowErrorIfPresent'
import { changePassword } from '../Auth/userThunks'
import { consumeError } from '../Auth/userSlice'
import CloseIcon from '@mui/icons-material/Close'

export interface ChangePasswordDialogProps {
    username?: string
    isAdmin: boolean
}

const ChangePasswordDialog: FunctionComponent<ChangePasswordDialogProps> = ({
    username,
    isAdmin,
}) => {
    const [open, setOpen] = useState(true)
    const lastErr = useSelector((state: RootState) => state.user.lastErr)

    const dispatch = useDispatch()
    const navigate = useNavigate()

    const validationSchema = yup.object().shape({
        isAdmin: yup.boolean(),
        oldPassword: yup.string().
            when('isAdmin', {
                is: false,
                then: yup.string().required('Old password is required')
        }),
        password: yup.string()
            .required('Password is required')
            .min(8, 'Password too short, minimal length is 8 characters'),
        confirmationPassword: yup.string()
            .oneOf([yup.ref('password'), null], 'Passwords must match'),
    })

    const formik = useFormik({
        initialValues: {
            isAdmin: isAdmin,
            oldPassword: '',
            password: '',
            confirmationPassword: '',
        },
        validationSchema,
        onSubmit: () => {
            dispatch(
                changePassword({
                    username: username,
                    isAdmin: isAdmin,
                    oldPassword: formik.values.oldPassword,
                    password: formik.values.password,
                    confirmationPassword: formik.values.confirmationPassword,
                })
            )
        },
    })

    const onCancel = () => {
        formik.resetForm()
        dispatch(consumeError())
        navigate('/Administration')
    }

    return (
        <Fragment>
            <Dialog
                open={open}
                fullWidth={true}
                onClose={onCancel}
                maxWidth="md"
            >
                <DialogTitle>
                    <Stack
                        direction="row"
                        spacing={1}
                        justifyContent="space-between"
                        alignItems="center"
                    >
                        <Typography
                            variant="h5"
                            fontWeight="600"
                        >
                            Change Password{isAdmin && username ? ": " + username : null}
                        </Typography>
                        <IconButton
                            onClick={() => {
                                formik.resetForm()
                                setOpen(false)
                                onCancel()
                            }}
                        >
                            <CloseIcon />
                        </IconButton>
                    </Stack>
                </DialogTitle>

                <DialogContent>
                    {lastErr ? (
                        <Typography
                            sx={{ mb: 1 }}
                            align="left"
                            color="error"
                            fontWeight="bold"
                        >
                            {lastErr}
                        </Typography>
                    ) : null}
                    <form onSubmit={formik.handleSubmit}>
                        {!isAdmin ? (
                            <TextField
                                fullWidth
                                label="Old Password"
                                name="oldPassword"
                                type="password"
                                sx={{ mb: 2 }}
                                value={formik.values.oldPassword}
                                onChange={formik.handleChange}
                                error={
                                    Boolean(formik.errors.oldPassword) &&
                                    formik.touched.oldPassword
                                }
                                helperText={
                                    formik.errors.oldPassword &&
                                    formik.touched.oldPassword
                                }
                            />
                        ) : null}
                        <TextField
                            fullWidth
                            label="New Password"
                            name="password"
                            type="password"
                            sx={{ mb: 2, mt: 1 }}
                            value={formik.values.password}
                            onChange={formik.handleChange}
                            error={
                                Boolean(formik.errors.password) &&
                                formik.touched.password
                            }
                            helperText={
                                formik.errors.password &&
                                formik.touched.password &&
                                formik.errors.password
                            }
                        />
                        <TextField
                            fullWidth
                            label="Confirmation Password"
                            name="confirmationPassword"
                            type="password"
                            sx={{ mb: 2 }}
                            value={formik.values.confirmationPassword}
                            onChange={formik.handleChange}
                            error={
                                Boolean(formik.errors.confirmationPassword) &&
                                formik.touched.confirmationPassword
                            }
                            helperText={
                                formik.errors.confirmationPassword &&
                                formik.touched.confirmationPassword &&
                                formik.errors.confirmationPassword
                            }
                        />
                        <Fragment>
                            <Button
                                sx={{ mb: 2 }}
                                type="submit"
                                variant="contained"
                                fullWidth
                            >
                                Change Password
                            </Button>
                        </Fragment>
                    </form>
                    <Box sx={{ mb: 1 }} />
                </DialogContent>
            </Dialog>
        </Fragment>
    )
}

export default ChangePasswordDialog
