import { Button } from '@mui/material'
import { useSelector } from 'react-redux'
import { RootState } from '../../redux/store'
import { isMapPointDisplayable, MapPoint } from '../trackingToolUtils'
import FileDownloadIcon from '@mui/icons-material/FileDownload'

export const exportAsGeoJsonString = (path: MapPoint[]) =>
    JSON.stringify({
        type: 'FeatureCollection',
        features: path
            .filter((item) => item.addToPath && isMapPointDisplayable(item))
            .map((item) => {
                const catalogItem = item.catalogItem
                return {
                    type: 'Feature',
                    properties: {
                        catalogItem: {
                            id: catalogItem.id,
                            name: catalogItem.name,
                            allNames: catalogItem.allNames,
                            description: catalogItem.description,
                            latitude: catalogItem.latitude,
                            longitude: catalogItem.longitude,
                        },
                        idx: item.idx,
                        displayable: isMapPointDisplayable(item),
                    },
                    geometry: {
                        type: 'Point',
                        coordinates: [
                            catalogItem.longitude,
                            catalogItem.latitude,
                        ],
                    },
                }
            }),
    })

const ExternalPathExportButton = () => {
    const path = useSelector(
        (state: RootState) => state.trackingTool.displayedPath
    )
    const exportPath = () => {
        if (!path) {
            return
        }

        const exportPath = path.filter(
            (vertex) => isMapPointDisplayable(vertex) && vertex.addToPath
        )
        const exportPathString = exportAsGeoJsonString(exportPath)
        const blob = new Blob([exportPathString], { type: 'application/json' })
        const url = window.URL.createObjectURL(blob)
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', 'path.json')
        document.body.appendChild(link)
        link.click()
        document.body.removeChild(link)
    }

    return (
        <Button variant="contained" onClick={exportPath} startIcon={<FileDownloadIcon />}>
            Export
        </Button>
    )
}

export default ExternalPathExportButton
