import {
    Avatar,
    DialogContent,
    DialogTitle,
    IconButton,
    ListItem,
    ListItemText,
    Stack,
    Typography,
} from '@mui/material'
import { Fragment, FunctionComponent, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '../../redux/store'
import ButtonOpenableDialog from '../../Reusables/ButtonOpenableDialog'
import CloseIcon from '@mui/icons-material/Close'
import VisibilityIcon from '@mui/icons-material/Visibility'
import DeleteIcon from '@mui/icons-material/Delete'
import List from '@mui/material/List'
import { ExternalPath } from '../trackingToolUtils'
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff'
import { removeFilePath, toggleFilePathVisibility } from '../trackingToolSlice'
import ConfirmationDialog from '../../Reusables/ConfirmationDialog'
import { showNotification } from '../../Notification/notificationSlice'
import ClearAllIcon from '@mui/icons-material/ClearAll'

export interface FilePathMenuItemProps {
    path: ExternalPath
}

const FilePathMenuItem: FunctionComponent<FilePathMenuItemProps> = ({
    path,
}) => {
    const dispatch = useDispatch()
    const toggleVisibility = () => {
        dispatch(
            toggleFilePathVisibility({ idx: path.idx, visible: !path.visible })
        )
    }

    const deletePath = () => {
        dispatch(removeFilePath({ idx: path.idx }))
        dispatch(
            showNotification({
                message: `Removed file path: ${path.path}`,
                severity: 'success',
                autohideSecs: 5,
            })
        )
    }

    const [deleteConfirmOpen, setDeleteConfirmOpen] = useState(false)

    return (
        <ListItem>
            <Avatar style={{ backgroundColor: path.color }}> </Avatar>
            <ListItemText
                primary={
                    <Typography sx={{ ml: 2 }} noWrap>
                        {path.filename}
                    </Typography>
                }
            />
            <Stack justifyContent="space-between" direction="row">
                <ConfirmationDialog
                    title="Delete File Path"
                    secondaryText={`Are you sure you want to delete file path (${path.filename})?`}
                    open={deleteConfirmOpen}
                    setOpen={setDeleteConfirmOpen}
                    onConfirmCallback={deletePath}
                    maxWidth="xs"
                />
                <Stack justifyContent="space-between" direction="row">
                    <Stack direction="row">
                        <IconButton onClick={toggleVisibility}>
                            {path.visible ? (
                                <VisibilityIcon />
                            ) : (
                                <VisibilityOffIcon />
                            )}
                        </IconButton>
                        <IconButton
                            onClick={() => {
                                setDeleteConfirmOpen(true)
                            }}
                        >
                            <DeleteIcon />
                        </IconButton>
                    </Stack>
                </Stack>
            </Stack>
        </ListItem>
    )
}

/**
 * Component for managing file paths from imported files
 */
const ManageFilePaths = () => {
    const filePaths = useSelector(
        (state: RootState) => state.trackingTool.importedPaths
    )
    const [open, setOpen] = useState(false)
    useEffect(() => {
        if (filePaths && filePaths.length === 0) {
            setOpen(false)
        }
    }, [filePaths])

    return (
        <Fragment>
            {filePaths && filePaths.length > 0 && (
                <ButtonOpenableDialog
                    open={open}
                    setOpen={setOpen}
                    buttonColor="primary"
                    buttonText="Manage Imported Paths"
                    buttonVariant="contained"
                    maxWidth="xs"
                    startIcon={<ClearAllIcon />}
                >
                    <DialogTitle>
                        <Stack
                            direction="row"
                            justifyContent="space-between"
                            alignItems="center"
                        >
                            <Typography variant="h6" fontWeight="bold">
                                Manage File Paths
                            </Typography>
                            <IconButton onClick={() => setOpen(false)}>
                                <CloseIcon />
                            </IconButton>
                        </Stack>
                    </DialogTitle>
                    <DialogContent>
                        <List>
                            {filePaths.map((item, idx) => (
                                <FilePathMenuItem key={idx} path={item} />
                            ))}
                        </List>
                    </DialogContent>
                </ButtonOpenableDialog>
            )}
        </Fragment>
    )
}

export default ManageFilePaths
