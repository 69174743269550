import * as React from 'react'
import { styled, useTheme } from '@mui/material/styles'
import Box from '@mui/material/Box'
import Drawer from '@mui/material/Drawer'
import CssBaseline from '@mui/material/CssBaseline'
import MuiAppBar, { AppBarProps as MuiAppBarProps } from '@mui/material/AppBar'
import Toolbar from '@mui/material/Toolbar'
import Typography from '@mui/material/Typography'
import IconButton from '@mui/material/IconButton'
import MenuIcon from '@mui/icons-material/Menu'
import { Fragment, FunctionComponent } from 'react'
import NavigationMenu from './NavigationMenu'
import { Paper, Stack } from '@mui/material'
import { useDispatch, useSelector } from 'react-redux'
import { toggleTheme } from '../Theme/themeSlice'
import { RootState } from '../redux/store'
import DarkModeIcon from '@mui/icons-material/DarkMode'
import LightModeIcon from '@mui/icons-material/LightMode'
import { setOpen } from './navigationSlice'

const drawerWidth = 240

const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })<{
    open?: boolean
}>(({ theme, open }) => ({
    flexGrow: 1,
    // padding: theme.spacing(3),
    transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: `-${drawerWidth}px`,
    ...(open && {
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
        marginLeft: 0,
    }),
}))

interface AppBarProps extends MuiAppBarProps {
    open?: boolean
}

const AppBar = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== 'open',
})<AppBarProps>(({ theme, open }) => ({
    transition: theme.transitions.create(['margin', 'width'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    ...(open && {
        width: `calc(100% - ${drawerWidth}px)`,
        marginLeft: `${drawerWidth}px`,
        transition: theme.transitions.create(['margin', 'width'], {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
    }),
}))

const DrawerHeader = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: 'flex-end',
}))

export interface DrawerProps {
    children: React.ReactNode
}

const PersistentDrawerLeft: FunctionComponent<DrawerProps> = ({ children }) => {
    const open = useSelector((state: RootState) => state.navigation.open)

    const onOpenDrawer = () => {
        dispatch(setOpen(true))
    }

    const colorThemeMode = useSelector(
        (state: RootState) => state.theme.paletteMode
    )

    const dispatch = useDispatch()

    const onToggleTheme = () => {
        dispatch(toggleTheme())
    }

    return (
        <Fragment>
            <Paper style={{ minHeight: '100vh', borderRadius: 0 }}>
                <Box sx={{ display: 'flex' }}>
                    {/* <CssBaseline /> */}
                    <AppBar position="fixed" open={open}>
                        <Toolbar>
                            <IconButton
                                color="inherit"
                                aria-label="open drawer"
                                onClick={onOpenDrawer}
                                edge="start"
                                sx={{ ...(open && { display: 'none' }) }}
                            >
                                <MenuIcon />
                            </IconButton>
                            <Typography variant="h6" noWrap component="div">
                                Catalog of Neo-Assyrian Toponyms
                            </Typography>
                            <Stack sx={{ ml: 'auto' }} alignItems="flex-end">
                                {colorThemeMode === 'dark' ? (
                                    <IconButton onClick={onToggleTheme}>
                                        <LightModeIcon />
                                    </IconButton>
                                ) : (
                                    <IconButton onClick={onToggleTheme}>
                                        <DarkModeIcon />
                                    </IconButton>
                                )}
                            </Stack>
                        </Toolbar>
                    </AppBar>
                    <NavigationMenu
                        open={open}
                        drawerWidth={drawerWidth}
                        setOpen={(open: boolean) => dispatch(setOpen(open))}
                    />
                    <Main open={open} sx={{ mt: 2 }}>
                        <DrawerHeader />
                        {children}
                    </Main>
                </Box>
            </Paper>
        </Fragment>
    )
}

export default PersistentDrawerLeft
