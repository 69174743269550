import {Button, Paper} from '@mui/material'
import {Fragment, useEffect, useState} from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { logout } from '../Auth/userSlice'
import { RootState } from '../redux/store'
import {TitlePageDto} from "../../swagger/data-contracts"
import axiosInstance from "../../api/api"
import ReactQuill from "react-quill"
import 'react-quill/dist/quill.snow.css'
import ContentLoading from "../Reusables/ContentLoading"
import {Link as RouterLink, useNavigate} from "react-router-dom"
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos"


const apiError =
    'Error while fetching data from the server, please try again later.'

const EditHome = () => {
    const [content, setContent] = useState<TitlePageDto | undefined>(undefined)
    const [isContentLoading, setIsContentLoading] = useState(true)
    const [err, setErr] = useState<string | undefined>(undefined)

    const hasWritePrivileges = useSelector((state: RootState) => state.user.roles.includes('WRITE'))
    const navigate = useNavigate()
    useEffect(() => {
        if (!hasWritePrivileges) {
            navigate('/')
        }
    }, [navigate, hasWritePrivileges])

    const updateContent = (updated: string) => {
        if (content) {
            content["content"] = updated
        }
    }

    const handleSubmit = () => {
        postData()
    }

    const postData = async () => {
        const {data, status} = await axiosInstance.post('/title-page', content)
        if (status != 200) {
            console.error("Title page data submitting failed.")
        }
    }

    // Fetch the item from the api after mounting the component
    useEffect(() => {
        // Function to fetch the item from the api
        const fetchItem = async () => {
            try {
                const { data, status } = await axiosInstance.get(
                    `/title-page`
                )
                if (status !== 200) {
                    setErr(apiError)
                    return
                }

                setContent(data)
                setIsContentLoading(false)
            } catch (err: any) {
                setErr(apiError)
            }
        }

        fetchItem()
    }, [])

    return (
        <Fragment>
            <Button
                startIcon={<ArrowBackIosIcon />}
                variant="contained"
                component={RouterLink}
                to="/"
                color="primary"
                sx={{ mb: 2 }}
            >
                Return Home
            </Button>
            <Paper style={{ minHeight: '100vh' }} variant="outlined">
    {isContentLoading && !err ? <ContentLoading /> : null}
    {!isContentLoading && content ? (
        <div>
            <h1>Home</h1>
        <form>
            <Button type="button" onClick={handleSubmit}>Submit</Button>
    <ReactQuill
        theme='snow'
        value={content["content"]}
        onChange={updateContent}
        style={{minHeight: '300px'}}
        />
        </form>
        </div>
    ) : null}
    </Paper>
    </Fragment>
)
}

export default EditHome
