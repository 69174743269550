import { Button, Dialog, DialogContent, DialogTitle, Stack, Typography } from '@mui/material'
import { Fragment, FunctionComponent } from 'react'
import CancelIcon from '@mui/icons-material/Cancel'
import CheckIcon from '@mui/icons-material/Check'

export interface ConfirmationDialogProps {
    open: boolean
    setOpen: (open: boolean) => void
    onConfirmCallback?: () => void
    onCancelCallback?: () => void
    title: string
    secondaryText?: string
    confirmText?: string
    cancelText?: string
    maxWidth?: 'xs' | 'sm' | 'md' | 'lg'
}

/**
 * Reusable confirmation dialog that runs specified onConfirmCallback when confirmed
 */
const ConfirmationDialog: FunctionComponent<ConfirmationDialogProps> = (
    props: ConfirmationDialogProps
) => {
    const { open, setOpen, onConfirmCallback, onCancelCallback, title, secondaryText } = props
    const cancelText = props.cancelText ?? 'Cancel'
    const confirmText = props.confirmText ?? 'Confirm'

    // function run after the dialog is confirmed
    const onConfirm = () => {
        if (onConfirmCallback) {
            onConfirmCallback()
        }
        setOpen(false)
    }

    const onCancel = () => {
        if (onCancelCallback) {
            onCancelCallback()
        }
        setOpen(false)
    }

    return (
        <Fragment>
            <Dialog
                open={open}
                fullWidth
                onClose={() => setOpen(false)}
                maxWidth={props.maxWidth ?? 'lg'}
            >
                <DialogTitle sx={{pb: 1}}>{title}</DialogTitle>
                <DialogContent>
                    <Typography sx={{mb: 1.5}}>{secondaryText}</Typography>
                    <Stack direction="row" spacing={1} justifyContent="flex-end" alignItems="center">
                        <Button variant="contained" startIcon={<CancelIcon />} onClick={onCancel}>{cancelText}</Button>
                        <Button variant="contained" startIcon={<CheckIcon />} onClick={onConfirm}>{confirmText}</Button>
                    </Stack>
                </DialogContent>
            </Dialog>
        </Fragment>
    )
}

export default ConfirmationDialog
