import { Container, Paper, Stack, Typography } from '@mui/material'
import CatalogTable from './CatalogTable'
import { Fragment, useEffect } from 'react'
import CatalogFilter from './CatalogFilter'
import { useDispatch, useSelector } from 'react-redux'
import { clearFilter, consumeCatalogItemDeleted } from './catalogSlice'
import { RootState } from '../redux/store'
import { fetchItems } from './catalogThunks'
import { showNotification } from '../Notification/notificationSlice'

/**
 * Catalog component
 */
const Catalog = () => {
    const dispatch = useDispatch()
    // reset filter if the user has redirected
    useEffect(() => {
        return () => {
            dispatch(clearFilter())
        }
    }, [dispatch])

    // Consume delete message and show notification to the user
    const deleteSuccess = useSelector(
        (state: RootState) => state.catalog.catalogItemDeleted
    )
    useEffect(() => {
        if (deleteSuccess) {
            dispatch(
                showNotification({
                    message: 'Item deleted successfully',
                    severity: 'success',
                    autohideSecs: 5,
                })
            )
        }
        dispatch(consumeCatalogItemDeleted())
        dispatch(fetchItems())
    }, [dispatch, deleteSuccess])

    return (
        <Fragment>
            <Typography variant="h3" sx={{ mb: 2 }} fontWeight="bold">
                Catalog
            </Typography>
            <Paper
                elevation={11}
                sx={{ py: 2, mt: 2 }}
                // variant="outlined"
                style={{ minHeight: '50vh' }}
            >
                <Stack sx={{ mx: 12, my: 2 }}>
                    <Stack alignItems="start">
                        <CatalogFilter />
                    </Stack>
                    <CatalogTable />
                </Stack>
            </Paper>
        </Fragment>
    )
}

export default Catalog
