import {Button, Container, Paper, Typography} from '@mui/material'
import {Fragment, useEffect, useState} from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '../redux/store'
import {TitlePageDto} from "../../swagger/data-contracts"
import axiosInstance from "../../api/api"
import 'react-quill/dist/quill.snow.css'
import ContentLoading from "../Reusables/ContentLoading"
import {Link as RouterLink, useNavigate} from "react-router-dom"
import EditIcon from '@mui/icons-material/Edit'
import {formatHtmlStringToReactDom} from "../../utils/Formatting/HtmlUtils"


const apiError =
    'Error while fetching data from the server, please try again later.'

const Home = () => {
    const [content, setContent] = useState<TitlePageDto | undefined>(undefined)
    const [isContentLoading, setIsContentLoading] = useState(true)
    const [err, setErr] = useState<string | undefined>(undefined)

    let navigate = useNavigate()
    const openEditHomePage = () => {
        let path = 'editHome'
        navigate(path)
    }

    const isAdmin = useSelector(
        (state: RootState) => state.user.roles.includes("ROLE_ADMIN")
    )
    // Fetch the item from the api after mounting the component
    useEffect(() => {
        // Function to fetch the item from the api
        const fetchItem = async () => {
            try {
                const { data, status } = await axiosInstance.get(
                    `/title-page`
                )
                if (status !== 200) {
                    setErr(apiError)
                    return
                }

                setContent(data)
                setIsContentLoading(false)
            } catch (err: any) {
                setErr(apiError)
            }
        }
        fetchItem()
    }, [])

    return (
        <Fragment>
            <Typography variant="h3" sx={{ mb: 2 }} fontWeight="bold">
                Home
            </Typography>
            <Paper style={{ minHeight: '80vh', display:'flex', justifyContent: 'space-between', flexDirection:'column'}} variant="outlined">
                {isContentLoading && !err ? <ContentLoading /> : null}
                {!isContentLoading && content ? (
                    <Container sx={{ mt: 2 }} maxWidth={false}>

                        {formatHtmlStringToReactDom(content["content"] as string)}
                    </Container>
                ) : null}

                {isAdmin ? (
                    <Container sx={{ mb: 3 }} maxWidth={false} >
                            <Button
                                startIcon={<EditIcon />}
                                variant="contained"
                                component={RouterLink}
                                to="/editHome"
                                color="primary"
                                sx={{ mb: 2 }}
                            >
                                Edit
                            </Button>
                    </Container>
                ) : null}
            </Paper>
        </Fragment>
    )
}

export default Home
