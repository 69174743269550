import { Button, Dialog } from '@mui/material'
import { Fragment, FunctionComponent, ReactNode, useState } from 'react'

export interface ButtonOpenableDialogProps {
    onOpenCallback?: () => void // this callback is always executed when the dialog is opened
    onCloseCallback?: () => void // this callback is always executed when the dialog is closed
    buttonText: string // the text of the button that opens the dialog
    buttonColor: // the color of the button that opens the dialog
    'primary' | 'secondary' | 'warning' | 'error' | 'success' | 'inherit'
    buttonVariant: 'text' | 'outlined' | 'contained' // the variant of the button that opens the dialog
    children: ReactNode // the content of the dialog
    maxWidth?: 'xs' | 'sm' | 'md' | 'lg' // the max width of the dialog
    open: boolean
    setOpen: (open: boolean) => void
    size?: 'small' | 'medium' | 'large'
    startIcon?: ReactNode // the icon to the left of the button
    endIcon?: ReactNode // the icon to the right of the button
}

// Generic dialog that can be opened by a button and closed by clicking on the backdrop.
const ButtonOpenableDialog: FunctionComponent<ButtonOpenableDialogProps> = ({
    onOpenCallback,
    onCloseCallback,
    buttonText,
    buttonColor,
    buttonVariant,
    children,
    maxWidth,
    open,
    setOpen,
    size,
    startIcon,
    endIcon,
}) => {
    // Change maxWidth to large if its undefined
    maxWidth = maxWidth ?? 'lg'

    const onOpen = () => {
        if (onOpenCallback) {
            // execute the callback if exists
            onOpenCallback()
        }
        setOpen(true)
    }
    const onClose = () => {
        if (onCloseCallback) {
            // execute the callback if exists
            onCloseCallback()
        }
        setOpen(false)
    }

    return (
        <Fragment>
            <Button
                onClick={onOpen}
                color={buttonColor}
                variant={buttonVariant}
                size={size ?? 'medium'}
                startIcon={startIcon}
                endIcon={endIcon}
            >
                {buttonText}
            </Button>
            <Dialog fullWidth open={open} onClose={onClose} maxWidth={maxWidth}>
                {children}
            </Dialog>
        </Fragment>
    )
}

export default ButtonOpenableDialog
