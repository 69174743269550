import './App.css'
import { Routes, Route } from 'react-router-dom'
import Home from './features/Home/Home'
import Catalog from './features/Catalog/Catalog'
import NotFound from './features/NotFound/NotFound'
import { Box } from '@mui/material'
import Login from './features/Auth/Login'
import { RoutedCatalogItemDetail } from './features/Catalog/CatalogItemDetail'
import Navigation from './features/Navigation/Navigation'
import TrackingTool from './features/TrackingTool/TrackingTool'
import Logout from './features/Auth/Logout'
import ThemeWrapper from './features/Theme/ThemeWrapper'
import EditHome from './features/Home/EditHome'
import Notification from './features/Notification/Notification'
import { Fragment } from 'react'
import Administration from './features/Administration/Administration'
import Register from './features/Auth/Register'
import Bibliography from './features/Bibliography/Bibliography'
import EditBibliography from './features/Bibliography/EditBibliography'
import ExternalSources from './features/ExternalSources/ExternalSources'
import ChangePassword from './features/Administration/ChangePassword'
import { RoutedCatalogItemEdit } from './features/Catalog/EditCatalogItem'

const App = () => {
    return (
        <ThemeWrapper>
            <Fragment>
                <Notification />
                <Navigation>
                    <Box sx={{ mx: 5 }}>
                        <Routes>
                            <Route path="/" element={<Home />} />
                            <Route path="/editHome" element={<EditHome />} />
                            <Route path="/catalog" element={<Catalog />} />
                            <Route
                                path="/catalog/:itemId"
                                element={<RoutedCatalogItemDetail />}
                            />
                            <Route
                                path="/catalog/edit/:itemId"
                                element={<RoutedCatalogItemEdit />}
                            />
                            <Route path="/login" element={<Login />} />
                            <Route path="/register" element={<Register />} />
                            <Route path="/logout" element={<Logout />} />
                            <Route
                                path="/changePassword"
                                element={<ChangePassword />}
                            />
                            <Route path="/map" element={<TrackingTool />} />
                            <Route
                                path="/administration"
                                element={<Administration />}
                            />
                            <Route
                                path="/externalSources"
                                element={<ExternalSources />}
                            />
                            <Route path="/sources" element={<Bibliography />} />
                            <Route
                                path="/editSources"
                                element={<EditBibliography />}
                            />
                            <Route path="*" element={<NotFound />} />
                        </Routes>
                    </Box>
                </Navigation>
            </Fragment>
        </ThemeWrapper>
    )
}

export default App
