import { createAsyncThunk } from '@reduxjs/toolkit'
import axiosInstance from '../../api/api'
import {  UserState } from './userSlice'
import jwt from 'jwt-decode' 

const loginError =
    'Login failed. Username or password incorrect.'

const registerError =
    'Server error occurred while registering. Please contact help service to resolve this issue or try again later.'

// This is not present in the swagger since spring generates
export interface UserLogin {
    username: string,
    password: string
}

export interface UserChangePassword {
    isAdmin: boolean
    username?: string
    oldPassword: string
    password: string
    confirmationPassword: string
}

export interface UserRegister {
    name: string,
    passwords: {
        password: string,
        confirmationPassword: string,
    },
    permissions: {
        canRead: boolean,
        canWrite: boolean,
        canDelete: boolean,
    },
    email: string,
}

/**
 * Logs user in - returns a promise that resolves to the user object if successful
 */
export const logIn = createAsyncThunk(
    'user/login',
    async (userDto: UserLogin, { getState }) => {
        try {
            // @ts-ignore
            if (getState().user.isLoggedIn) {
                return Promise.reject(undefined)
            }

            const { data, status } = await axiosInstance.post('/login', userDto)
            const [ accessToken, refreshToken ] = [data.access_token, data.refresh_token]
            if (status !== 200) {
                // TODO read API err
                return Promise.reject(loginError)
            }

            // Strip bearer from access token
            const userInfo = jwt(accessToken.replace('Bearer ', '')) as any
            const { sub, authorities } = userInfo
            if (!sub || !authorities) {
                return Promise.reject(loginError)
            }

            const userState: UserState = {
                accessToken,
                refreshToken,
                username: sub,
                roles: authorities,
                isLoggingIn: false,
                isLoggedIn: true,
                isRegistered: false,
                isPasswordChanged: false
            }
            
            return userState
        } catch (err: any) {
            return Promise.reject(loginError)
        }
    }
)

export const register = createAsyncThunk(
    'user/register',
    async (userDto: UserRegister) => {
        try {
            const { data, status } = await axiosInstance.post('/users', userDto)
            if (status !== 200) {
                // TODO read API err
                return Promise.reject(data)
            }
            return status
        } catch (err: any) {
            return Promise.reject(err.response.data)
        }
    }
)

export const changePassword = createAsyncThunk(
    'user/changePassword',
    async (userChangePasswordDto: UserChangePassword) => {
        try {
            let url = ''
            if (userChangePasswordDto.isAdmin && userChangePasswordDto.username) {
                url = '/users/' + userChangePasswordDto.username + '/password'
            } else {
                url = '/users/password?oldPassword=' + userChangePasswordDto.oldPassword
            }

            const { data, status } = await axiosInstance.patch(
                url,
                {
                    password: userChangePasswordDto.password,
                    confirmationPassword: userChangePasswordDto.confirmationPassword,
                }
            )
            if (status !== 200) {
                // TODO read API err
                return Promise.reject(data)
            }
            return status
        } catch (err: any) {
            return Promise.reject(err.response.data)
        }
    }
)
