import {Button, Container, Paper, Typography} from '@mui/material'
import {Fragment, useEffect, useState} from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '../redux/store'
import axiosInstance from "../../api/api"
import 'react-quill/dist/quill.snow.css'
import NotAuthorized from "../NotAuthorized/NotAuthorized"
import SingleFileSelectionForm from "../Reusables/SingleFileSelectionForm"
import * as yup from "yup"
import {useFormik} from "formik"


interface UploadValues {
    file?: File
}

const initialValues: UploadValues = {}


const ExternalSources = () => {

    const [filename, setFilename] = useState<string | undefined>(undefined)
    const [fileProcessing, setFileProcessing] = useState(false)
    const [loading, setLoading] = useState(false)

    const validationSchema = yup.object().shape({
        file: yup.mixed().required('File is required'),
    })

    const formik = useFormik({
        initialValues,
        validationSchema,
        onSubmit: async (values) => {
            setFileProcessing(true)
            const formData = new FormData()
            // @ts-ignore
            formData.append("file", values.file)

            setLoading(true)
            const {data, status} = await axiosInstance.post(
               '/external-catalog-items',
               formData,
                {headers: {
                'Content-Type': 'multipart/form-data'
                }}
            )
            if (status !== 200) {
                // TODO
            }
            setLoading(false)
        },
    })

    // Callback when user selects the file
    const onFileSelected = (event: any) => {
        const file = event.currentTarget.files[0]
        if (file) {
            setFilename(file.name)
            formik.setFieldValue('file', file)
        }
    }

    const onClose = () => {
        if (fileProcessing) {
            return
        }
        setFilename(undefined)
        formik.resetForm()
    }

    const onClearSelectedFile = () => {
        setFilename(undefined)
        formik.setFieldValue('file', undefined)
    }


    const isAdmin = useSelector(
        (state: RootState) => state.user.roles.includes("ROLE_ADMIN")
    )
    const handleClick = () => {

    }

    const cigsUrl = "https://zenodo.org/record/5642899#.YoeJLXXP1hE";
    const pleiadesUrl = "https://pleiades.stoa.org/";
    const pleiadesDownloadsUrl = "https://atlantides.org/downloads/pleiades/dumps/";
    const aneUrl = "https://www.lingfil.uu.se/research/assyriology/earth/";
    const aneDownloadsUrl = "https://zenodo.org/record/6384045";
    const geonamesUrl = "https://www.geonames.org/";

    return (
        <Fragment>
            <Typography variant="h3" sx={{ mb: 2 }} fontWeight="bold">
                External Sources
            </Typography>
            {isAdmin ? (
            <Paper style={{ minHeight: '80vh' }} variant="outlined">
                <Container sx={{ mt: 4 }}>
                    <Typography sx={{ mb: 2 }}>
                        Please upload CSV file from <a target="_blank" rel="noopener noreferrer" href={cigsUrl}>CIGS</a> (older versions than version 1.4 do not work).
                    </Typography>
                    <Typography sx={{ mb: 3 }}>
                        Files from <a target="_blank" rel="noopener noreferrer" href={pleiadesUrl}>Pleiades</a> (downloads available <a target="_blank" rel="noopener noreferrer" href={pleiadesDownloadsUrl}>here</a>), <a target="_blank" rel="noopener noreferrer" href={aneUrl}>ANE</a> (downloads available <a target="_blank" rel="noopener noreferrer" href={aneDownloadsUrl}>here</a>)
                        and <a target="_blank" rel="noopener noreferrer" href={geonamesUrl}>GeoNames</a> will be added automatically.
                    </Typography>
                    <SingleFileSelectionForm
                        onFileSelected={onFileSelected}
                        onClearSelectedFile={onClearSelectedFile}
                        filename={filename}
                        formik={formik}
                        loading={loading}
                    />
                </Container>
            </Paper>
            ) : <NotAuthorized />}
        </Fragment>
    )
}

export default ExternalSources
