import axios from 'axios'
import { Store } from 'redux'
import conf from '../config/conf'
import config from '../config/conf'
import { AppStore } from '../features/redux/store'

// This module contains axios instance configuration
// All calls to the API should be made through this module via axiosInstance object

let store: AppStore // this will get injected in index.tsx

export const injectStore = (_store: Store) => {
    store = _store
}

// Error
export interface ApiError {}

const createBaseInstance = () =>
    axios.create({
        baseURL: config.baseUrl,
        headers: {}
    })

const axiosInstance = createBaseInstance() // axios instance

axiosInstance.interceptors.request.use(
    (config) => {
        const accessToken = store.getState().user.accessToken // get the access token from the store
        if (accessToken) {
            // @ts-ignore (axios typescript types are a crime and this will always be defined)
            config.headers['Authorization'] = accessToken as string
        }

        return config
    },
    (err) => {
        Promise.reject(err)
    }
)

axiosInstance.interceptors.response.use(
    (res) => res,
    async (err) => {
        const originalConfig = err.config


        // Original URL might be login in which case we don't want to refresh the access token
        // Since the user just failed to log in and no token expired
        if (originalConfig.url === '/login' || !err.response) {
            return Promise.reject(err)
        }

        // If the error is not a 401 reject this
        if (err.response.status !== 401) {
            return Promise.reject(err)
        }

        // We need to set the refresh token in the auth header
        const oldRefreshToken = store.getState().user.refreshToken

        // If there is no refresh token we simply log the user out
        if (!oldRefreshToken) {
            store.dispatch({ type: 'user/logout' })
        }

        // Try refreshing the JWT
        try {
            // For some reason axios refuses to create instance here so just use fetch
            // Send the request
            const res = await fetch(`${conf.baseUrl}/users/token`, {
                method: 'GET',
                // credentials: 'include',
                headers: {
                    'Authorization': oldRefreshToken as string
                }
            })

            const json = await res.json()
            const [ accessToken, refreshToken ] = [json.access_token, json.refresh_token]

            // Set the new tokens
            store.dispatch({
                type: 'user/refreshTokens',
                payload: { accessToken, refreshToken },
            })

            // Set this to retry the request that failed
            originalConfig.retry = true

            // Return the failed instance so it can retry
            return axiosInstance(originalConfig)
        } catch (err: any) {
            // If the refresh token fails we log the user out
            store.dispatch({ type: 'user/logout' })
            originalConfig.retry = false // do not retry we are logged out
            return originalConfig
        }
    }
)

export default axiosInstance
