import { DialogContent, DialogTitle, IconButton, Stack, Typography } from '@mui/material'
import { useFormik } from 'formik'
import { useState } from 'react'
import * as yup from 'yup'
import ButtonOpenableDialog from '../../Reusables/ButtonOpenableDialog'
import { useDispatch, useSelector } from 'react-redux'
import { sendTextForProcessing } from '../trackingToolThunks'
import SingleFileSelectionForm from '../../Reusables/SingleFileSelectionForm'
import AttachFileIcon from '@mui/icons-material/AttachFile'
import { RootState } from '../../redux/store'
import CloseIcon from '@mui/icons-material/Close'

interface UploadValues {
    file?: File
}

const initialValues: UploadValues = {}

const FileUpload = () => {
    const dispatch = useDispatch()

    const [filename, setFilename] = useState<string | undefined>(undefined)
    const [fileProcessing, setFileProcessing] = useState(false)
    const [open, setOpen] = useState(false)

    const validationSchema = yup.object().shape({
        file: yup.mixed().required('File is required'),
    })

    const loading = useSelector(
        (state: RootState) => state.trackingTool.isLoading
    )

    const formik = useFormik({
        initialValues,
        validationSchema,
        onSubmit: async (values) => {
            setFileProcessing(true)
            const reader = new FileReader()
            reader.readAsText(values.file as File)
            reader.onload = async () => {
                dispatch(sendTextForProcessing(reader.result as string))
                setFileProcessing(false)
            }
        },
    })

    // Callback when user selects the file
    const onFileSelected = (event: any) => {
        const file = event.currentTarget.files[0]
        if (file) {
            setFilename(file.name)
            formik.setFieldValue('file', file)
        }
    }

    const onClose = () => {
        if (fileProcessing) {
            return
        }
        setFilename(undefined)
        formik.resetForm()
    }

    const onClearSelectedFile = () => {
        setFilename(undefined)
        formik.setFieldValue('file', undefined)
    }

    return (
        <ButtonOpenableDialog
            buttonText="File"
            buttonColor="primary"
            buttonVariant="contained"
            onCloseCallback={onClose}
            maxWidth="xs"
            open={open}
            setOpen={setOpen}
            startIcon={<AttachFileIcon />}
        >
            <DialogTitle>
                <Stack
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                >
                    <Typography variant="h5" fontWeight="600">
                        Upload File
                    </Typography>
                    <IconButton onClick={() => {
                         if (fileProcessing) {
                            return
                        }
                        setOpen(false)
                    }}>
                        <CloseIcon />
                    </IconButton>
                </Stack>
            </DialogTitle>
            <DialogContent>
                <SingleFileSelectionForm
                    onFileSelected={onFileSelected}
                    onClearSelectedFile={onClearSelectedFile}
                    filename={filename}
                    formik={formik}
                    loading={loading}
                />
            </DialogContent>
        </ButtonOpenableDialog>
    )
}

export default FileUpload
