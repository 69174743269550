
import L, { LatLngTuple, PointExpression } from 'leaflet'
import { CatalogItemDto, ExternalCatalogItemDto, PathDto } from '../../swagger/data-contracts'
import generateUuid from '../../utils/id/uuidGenerator'

// Type of map point for conditional rendering
export enum MapPointType {
    ProcessedText, // From processed text
    LocalCatalog, // Fetched from local catalog
    ExternalCatalog, // Fetched from external catalog
    FileImport, // From GeoJSON file
    FromCoordinates, // From coordinates
}


// Represents a point on the map - wrapper for CatalogItemDto to make it easier to work with
export interface MapPoint {
    id: string // unique id to identify the point on the map
    reactId: string // unique id to identify item in React - e.g. rendering in a list
    idx: number // index in the path
    variantIdx: number, // index of the variant
    variants?: CatalogItemDto[], // variants of the point
    firstVariantCatalogItemId?: string // this id is only used for catalog items with multiple variants
    addToPath: boolean // whether to add the point to the path
    catalogItem: CatalogItemDto, // reference to CatalogItemDto
    type: MapPointType // Type of the map point
    hidden?: boolean // if true the point will not be displayed on the map
    externalSource?: string // if the point is from external source, this is the source name
}

export interface ExternalMapPoint extends MapPoint {
    catalogItem: ExternalCatalogItemDto
}

export type Path = MapPoint[]

export interface ExternalPath {
    idx: number,
    color: string,
    path: Path
    visible: boolean
    filename: string
}

export const getExternalPathColor = (idx: number) => {
    return `hsl(${idx * 360 / 10}, 100%, 50%)`
}

/**
 * Returns true whether the map point is displayable - i.e. can be shown on the map
 * @param mapPoint 
 * @returns true if the map point is displayable
 */
export const isMapPointDisplayable = (mapPoint: MapPoint): boolean =>
    !!mapPoint.catalogItem.latitude && !!mapPoint.catalogItem.longitude && !mapPoint.hidden

/**
 * Based on its type - either imported from local catalog, remote catalogs etc. each type has its own color to differentiate them
 * @param item item to get color for
 * @returns CSS color string
 */
export const getMapPointSemanticColor = (item: MapPoint) => {
    switch (item.type) {
        case MapPointType.LocalCatalog:
            return 'inherit'
        case MapPointType.FromCoordinates:
            return '#21972D'
        case MapPointType.ExternalCatalog:
            return '#A72020'
        case MapPointType.FileImport:
            return '#967520'
    }
}

/**
 * Creates SVG icon for map marker with specific color
 */
const createMapMarkerSvg = (color: string) => {
    return `data:image/svg+xml;utf8, ${encodeURIComponent(`
    <svg width="16px" height="16px" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg" version="1.1" fill="blue"
    stroke="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="1">
    <path
        fill="${color}"
        d="m13.25 7c0 3.75-5.25 7.25-5.25 7.25s-5.25-3.5-5.25-7.25c0-2.89949 2.35051-5.25 5.25-5.25 2.8995 0 5.25 2.35051 5.25 5.25z" />
    <circle cx="8" cy="7" r="1.55" fill="white" />
    </svg> `)}`
}

const mapMarkerSvgs = {
    [MapPointType.ProcessedText]: createMapMarkerSvg('#285CAB'),
    [MapPointType.LocalCatalog]: createMapMarkerSvg('#00B0FF'),
    [MapPointType.ExternalCatalog]: createMapMarkerSvg('#A72020'),
    [MapPointType.FileImport]: createMapMarkerSvg('#967520'),
    [MapPointType.FromCoordinates]: createMapMarkerSvg('#21972D'),
}

const iconAnchor = [22, 22] as PointExpression
const iconSize = [35, 35] as PointExpression

const mapMarkers: any = {
    [MapPointType.ProcessedText]: L.icon({
        iconAnchor, iconSize,
        iconUrl: mapMarkerSvgs[MapPointType.ProcessedText],
    }),
    [MapPointType.LocalCatalog]: L.icon({
        iconAnchor, iconSize,
        iconUrl: mapMarkerSvgs[MapPointType.LocalCatalog],
    }),
    [MapPointType.ExternalCatalog]: L.icon({
        iconAnchor, iconSize,
        iconUrl: mapMarkerSvgs[MapPointType.ExternalCatalog],
    }),

    [MapPointType.FileImport]: L.icon({
        iconAnchor, iconSize,
        iconUrl: mapMarkerSvgs[MapPointType.FileImport],
    }),
    [MapPointType.FromCoordinates]: L.icon({
        iconAnchor, iconSize,
        iconUrl: mapMarkerSvgs[MapPointType.FromCoordinates],
    }),
}

export const getMapPointIcon = (item: MapPoint): L.Icon => mapMarkers[item.type]
export const getCustomMapPointIcon = (color: string) => {
    if (mapMarkers.hasOwnProperty(color)) {
        return mapMarkers[color] as L.Icon
    }

    const svg = createMapMarkerSvg(color)
    mapMarkers[color] = L.icon({
        iconAnchor, iconSize,
        iconUrl: svg,
    })

    return mapMarkers[color] as L.Icon
}

export const calculateMapCenter = (path: Path): LatLngTuple | undefined => {
    const displayableItems = path.filter((item) => isMapPointDisplayable(item))
    if (displayableItems.length === 0) {
        return undefined
    }

    return [
        displayableItems
            .map((item) => item.catalogItem.latitude ?? 0)
            .reduce((a, b) => a + b, 0) / displayableItems.length,
        displayableItems
            .map((item) => item.catalogItem.longitude ?? 0)
            .reduce((a, b) => a + b, 0) / displayableItems.length,
    ]
}

export const setMapPointIds = (pathVariant: Path): Path => pathVariant.map(mapPoint => {
    // Get identifier - either catalog item identifier is used or generate a new UUID if the point does not have uuid
    // - e.g. is not from the catalog
    return { ...mapPoint, id: mapPoint.catalogItem.id ? mapPoint.catalogItem.id : generateUuid() }
})

/**
 * Maps external catalog item to map point object
 * @param externalCatalogItem external catalog item to map to map point
 * @returns 
 */
export const mapExternalCatalogItemToMapPoint = (externalCatalogItem: ExternalCatalogItemDto) => {
    const coordinatesNotNull = externalCatalogItem.latitude !== null && externalCatalogItem.longitude !== null
    return ({
        id: externalCatalogItem.id,
        reactId: generateUuid(),
        idx: -1,
        addToPath: coordinatesNotNull,
        catalogItem: externalCatalogItem,
        type: MapPointType.ExternalCatalog,
        hidden: !coordinatesNotNull,
        externalSource: externalCatalogItem.externalSource,
    } as MapPoint)
}

/**
 * Maps local catalog item to map point
 * @param catalogItem catalog item to map to map point
 * @returns 
 */
export const mapLocalCatalogItemToMapPoint = (catalogItem: CatalogItemDto): ExternalMapPoint => {
    const coordinatesNotNull = catalogItem.latitude !== null && catalogItem.longitude !== null
    return ({
        id: catalogItem.id as string,
        reactId: generateUuid(),
        idx: -1,
        addToPath: coordinatesNotNull,
        catalogItem,
        type: MapPointType.LocalCatalog,
        hidden: !coordinatesNotNull,
        variantIdx: 0,
        variants: undefined
    })
}

/**
 * Builds path from pathDto
 * @param pathDto 
 * @returns 
 */
export const buildPath = (pathDto: PathDto): Path => {
    // Path dto contains an array of all variants of the catalog item in given path
    // By default we use the first variant of the catalog item, map the catalog item to MapPoint and add all the variants
    // to the map point + set the index to 0
    const path: Path = []
    pathDto.foundCatalogItems?.forEach(catalogItemVariants => {
        if (catalogItemVariants.length === 0) {
            return // This should never happen but should backend fail we will ignore empty arrays
        }

        const catalogItem = catalogItemVariants[0]
        const coordinatesNotNull = catalogItem.latitude !== null && catalogItem.longitude !== null // backend sends null for unknown coords
        path.push({
            id: catalogItem.id, // id should be the same as catalog item's id so that we do not render the same point multiple times
            reactId: generateUuid(), // react id should always be unique so that we can move the point in the list
            idx: path.length, // index in the result array
            variantIdx: 0, // we always pick the first variant
            addToPath: coordinatesNotNull,
            catalogItem,
            type: MapPointType.ProcessedText,
            hidden: !coordinatesNotNull,
            variants: catalogItemVariants.length === 1 ? undefined : catalogItemVariants,
            firstVariantCatalogItemId: catalogItem.id,
        } as MapPoint)
    })

    return path
}

/**
 * Updates map point if it has catalog item set or is in its variants
 */
export const updateMapPointIfCatalogItemPresent = (mapPoint: MapPoint, catalogItem: CatalogItemDto): MapPoint => {
    if (mapPoint.id !== catalogItem.id) {
        // If the id does not match catalog item id we need to check if the catalog item is in the variants
        if (!mapPoint.variants) {
            return mapPoint // if there are no variants we do not need to update the map point
        }

        const variantIdx = mapPoint.variants.findIndex(variant => variant.id === catalogItem.id)
        if (variantIdx === -1) {
            return mapPoint // if the catalog item is not in the variants we do not need to update the map point
        }

        // If the catalog item is in the variants we need to update the map point
        return {
            ...mapPoint,
            variants: mapPoint.variants.map((variant, idx) => idx === variantIdx ? catalogItem : variant),
        }
    }

    // Otherwise we have found map point that has same id as our catalog item
    // which means it will always have it in its variants if there are some
    const coordinatesNotNull = catalogItem.latitude !== null && catalogItem.longitude !== null
    if (!mapPoint.variants) {
        // If there are no variants simply update the map point
        return {
            ...mapPoint,
            catalogItem,
            addToPath: mapPoint.addToPath && coordinatesNotNull,
            hidden: mapPoint.hidden && !coordinatesNotNull,
        }
    }

    // If there are variants we need to update the map point in the variants as well
    const variantIdx = mapPoint.variants.findIndex(variant => variant.id === catalogItem.id)
    return {
        ...mapPoint,
        catalogItem,
        variants: mapPoint.variants.map((variant, idx) => idx === variantIdx ? catalogItem : variant),
        addToPath: mapPoint.addToPath && coordinatesNotNull,
        hidden: mapPoint.hidden && !coordinatesNotNull,
    }
}
