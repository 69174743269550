import {
    Button,
    Collapse,
    Divider,
    Stack,
    TextField,
} from '@mui/material'
import { Fragment } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { setFilter, setFilterOpen } from './catalogSlice'
import { fetchItems } from './catalogThunks'
import FilterListIcon from '@mui/icons-material/FilterList'
import FilterListOffIcon from '@mui/icons-material/FilterListOff'
import ManageSearchIcon from '@mui/icons-material/ManageSearch'
import { RootState } from '../redux/store'
import AddNewLocationDialog from './AddNewLocationDialog'

/**
 * Toggleable filter component on the catalog page
 */
const CatalogFilter = () => {
    const dispatch = useDispatch()

    // Controls
    const filterOpen = useSelector(
        (state: RootState) => state.catalog.filterOpen
    )
    const toggleFilter = () => {
        dispatch(setFilterOpen(!filterOpen))
    }

    // current filter object
    const filter = useSelector((state: RootState) => state.catalog.filter)
    const applyFilter = () => {
        dispatch(fetchItems())
    }

    return (
        <Fragment>
            <Stack direction="row" spacing={2} sx={{ mb: 2 }}>
                <Button
                    startIcon={
                        filterOpen ? <FilterListOffIcon /> : <FilterListIcon />
                    }
                    variant="outlined"
                    color="primary"
                    onClick={toggleFilter}
                >
                    Filter
                </Button>
                <AddNewLocationDialog />
            </Stack>
            <Collapse in={filterOpen} timeout="auto" unmountOnExit>
                <Stack direction="row" spacing={2} sx={{ mt: 2 }}>
                    <TextField
                        size="small"
                        id="name"
                        label="Name"
                        onChange={(e: any) => {
                            dispatch(
                                setFilter({
                                    ...filter,
                                    name: e.target.value,
                                })
                            )
                        }}
                        value={filter.name}
                    />
                    <TextField
                        size="small"
                        id="type"
                        label="Type"
                        onChange={(e: any) => {
                            dispatch(
                                setFilter({
                                    ...filter,
                                    type: e.target.value,
                                })
                            )
                        }}
                        value={filter.type}
                    />
                    <TextField
                        size="small"
                        id="writtenForm"
                        label="Written Form"
                        onChange={(e: any) => {
                            dispatch(
                                setFilter({
                                    ...filter,
                                    writtenForm: e.target.value,
                                })
                            )
                        }}
                    />

                    <TextField
                        size="small"
                        id="stateOrTerritory"
                        label="State or Territory"
                        onChange={(e: any) => {
                            dispatch(
                                setFilter({
                                    ...filter,
                                    country: e.target.value,
                                })
                            )
                        }}
                        value={filter.country}
                    />
                </Stack>
                <Stack alignItems="flex-end" sx={{ mt: 2 }}>
                    <Button
                        startIcon={<ManageSearchIcon />}
                        variant="contained"
                        onClick={applyFilter}
                    >
                        Search
                    </Button>
                </Stack>
            </Collapse>
            {filterOpen ? <Divider sx={{ mb: 5, mt: 1 }} /> : null}
        </Fragment>
    )
}

export default CatalogFilter
