import {
    Button,
    Container,
    Dialog,
    DialogContent,
    Divider,
    Grid,
    Paper,
    Stack,
    TextField,
    Typography,
} from '@mui/material'
import { Fragment, FunctionComponent, useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import axiosInstance from '../../api/api'
import { CatalogItemDto } from '../../swagger/data-contracts'
import ShowErrorIfPresent from '../Reusables/ShowErrorIfPresent'
import ContentLoading from '../Reusables/ContentLoading'
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos'
import { Link as RouterLink } from 'react-router-dom'
import { RootState } from '../redux/store'
import { useDispatch, useSelector } from 'react-redux'
import * as yup from 'yup'
import { Formik, FieldArray, Form } from 'formik'
import { updateCatalogItem } from './catalogItemThunks'
import AddIcon from '@mui/icons-material/Add'
import { IconButton } from '@mui/material'
import ClearIcon from '@mui/icons-material/Clear'
import SaveIcon from '@mui/icons-material/Save'
import { resetRequestCompleted } from './catalogItemSlice'

const apiError =
    'Error while fetching data from the server, please try again later.'

export interface CatalogItemEditProps {
    itemId: string
    showReturnToDetailButton?: boolean
    onRequestCompletedCallback?: () => void
}

export interface RowData {
    rowName: string
    items: string[] | undefined
    isExpandable: boolean
    key: string
}

export interface CreateCatalogItemProps extends CatalogItemEditProps {
    item?: CatalogItemDto
    isItemLoading: boolean
    err?: string
    loading: boolean
    disableContainer?: boolean
}

export const CreateCatalogItem: FunctionComponent<CreateCatalogItemProps> = ({
    item,
    showReturnToDetailButton,
    itemId,
    isItemLoading,
    err,
    loading,
    onRequestCompletedCallback,
}) => {
    const canEditCatalog = useSelector((state: RootState) =>
        state.user.roles.includes('WRITE')
    )
    const navigate = useNavigate()
    useEffect(() => {
        if (!canEditCatalog) {
            navigate('/')
        }
    })

    const dispatch = useDispatch()
    const requestCompleted = useSelector(
        (state: RootState) => state.catalogItem.isRequestCompleted
    )

    useEffect(() => {
        if (!requestCompleted) {
            return
        }
        dispatch(resetRequestCompleted())
        if (onRequestCompletedCallback) {
            onRequestCompletedCallback()
            return
        }
        // Otherwise navigate to the catalog
        navigate(`/catalog/${itemId as string}`)
    }, [
        requestCompleted,
        navigate,
        dispatch,
        onRequestCompletedCallback,
        itemId,
    ])

    const validationSchema = yup.object().shape({
        name: yup.string().required('Item name is required'),
        longitude: yup
            .number()
            .typeError('Longitude must be a number')
            .nullable(true),
        latitude: yup
            .number()
            .typeError('Latitude must be a number')
            .nullable(true),
        certainty: yup
            .number()
            .typeError('Certainty must be a number')
            .nullable(true),
    })

    const initialValues = {
        name: item?.name ? item?.name : '',
        allNames: item?.allNames ? item?.allNames : [''],
        writtenForms: item?.writtenForms ? item?.writtenForms : [''],
        types: item?.types ? item?.types : [''],
        countries: item?.countries ? item?.countries : [''],
        bibliography: item?.bibliography ? item?.bibliography : [''],
        longitude: item?.longitude,
        latitude: item?.latitude,
        certainty: item?.certainty,
        description: item?.description,
    }

    return (
        <Fragment>
            {showReturnToDetailButton && (
                <Stack
                    direction="row"
                    alignItems="flex-start"
                    spacing={2}
                    sx={{ mt: 1 }}
                >
                    <Button
                        startIcon={<ArrowBackIosIcon />}
                        variant="contained"
                        component={RouterLink}
                        to={`/catalog/${itemId as string}`}
                        color="primary"
                        sx={{ mb: 2 }}
                    >
                        Back To Detail
                    </Button>
                </Stack>
            )}
            <ShowErrorIfPresent err={err} />

            <Paper style={{ minHeight: '100vh' }} variant="outlined">
                {isItemLoading && !err ? <ContentLoading /> : null}
                {!isItemLoading && item ? (
                    <Formik
                        initialValues={initialValues}
                        validationSchema={validationSchema}
                        onSubmit={(values) => {
                            dispatch(
                                updateCatalogItem({
                                    item: {
                                        id: item?.id,
                                        name: values.name,
                                        allNames: values.allNames.filter(item => item !== ''),
                                        writtenForms: values.writtenForms.filter(item => item !== ''),
                                        types: values.types.filter(item => item !== ''),
                                        countries: values.countries.filter(item => item !== ''),
                                        bibliography: values.bibliography.filter(item => item !== ''),
                                        longitude: values.longitude,
                                        latitude: values.latitude,
                                        certainty: values.certainty,
                                        description: values.description,
                                    },
                                })
                            )
                        }}
                    >
                        {({ values, handleChange, errors, touched }) => (
                            <Form>
                                <Container sx={{ m: 2 }} maxWidth={false}>
                                    <TextField
                                        fullWidth
                                        label="Name"
                                        name="name"
                                        value={values.name}
                                        onChange={handleChange}
                                        error={
                                            Boolean(errors.name) && touched.name
                                        }
                                        helperText={
                                            errors.name &&
                                            touched.name &&
                                            errors.name
                                        }
                                    />
                                </Container>
                                <Divider textAlign="left">
                                    Alternative Names
                                </Divider>
                                <FieldArray
                                    name="allNames"
                                    render={(arrayHelpers) => (
                                        <Container maxWidth={false}>
                                            {values.allNames &&
                                            values.allNames.length > 0 ? (
                                                values.allNames.map(
                                                    (altName, i) => (
                                                        <Grid
                                                            sx={{ m: 2 }}
                                                            container
                                                            justifyContent="space-around"
                                                            key={i}
                                                        >
                                                            <Grid
                                                                item
                                                                xs={8}
                                                                md={10}
                                                                xl={11}
                                                            >
                                                                <TextField
                                                                    fullWidth
                                                                    label="Alternative Name"
                                                                    name={`allNames.${i}`}
                                                                    value={values.allNames[i]}
                                                                    onChange={
                                                                        handleChange
                                                                    }
                                                                />
                                                            </Grid>
                                                            <Grid
                                                                item
                                                                xs={4}
                                                                md={2}
                                                                lg={2}
                                                                xl={1}
                                                                sx={{ p: 1 }}
                                                            >
                                                                <IconButton
                                                                    color="primary"
                                                                    onClick={() =>
                                                                        arrayHelpers.insert(
                                                                            i +
                                                                                1,
                                                                            ''
                                                                        )
                                                                    }
                                                                >
                                                                    <AddIcon />
                                                                </IconButton>
                                                                <IconButton
                                                                    type="button"
                                                                    color="error"
                                                                    key={i}
                                                                    onClick={() =>
                                                                        arrayHelpers.remove(
                                                                            i
                                                                        )
                                                                    }
                                                                >
                                                                    <ClearIcon />
                                                                </IconButton>
                                                            </Grid>
                                                        </Grid>
                                                    )
                                                )
                                            ) : (
                                                <Stack
                                                    direction="row"
                                                    justifyContent="center"
                                                >
                                                    <IconButton
                                                        color="primary"
                                                        onClick={() =>
                                                            arrayHelpers.insert(
                                                                0,
                                                                ''
                                                            )
                                                        }
                                                    >
                                                        <AddIcon />
                                                    </IconButton>
                                                </Stack>
                                            )}
                                        </Container>
                                    )}
                                />
                                <Divider textAlign="left">
                                    Written Forms
                                </Divider>
                                <FieldArray
                                    name="writtenForms"
                                    render={(arrayHelpers) => (
                                        <Container maxWidth={false}>
                                            {values.writtenForms &&
                                            values.writtenForms.length > 0 ? (
                                                values.writtenForms.map(
                                                    (writtenForm, i) => (
                                                        <Grid
                                                            sx={{ m: 2 }}
                                                            container
                                                            justifyContent="space-around"
                                                            key={i}
                                                        >
                                                            <Grid
                                                                item
                                                                xs={8}
                                                                md={10}
                                                                xl={11}
                                                            >
                                                                <TextField
                                                                    fullWidth
                                                                    label="Written Form"
                                                                    name={`writtenForms.${i}`}
                                                                    value={values.writtenForms[i]}
                                                                    onChange={handleChange}
                                                                />
                                                            </Grid>
                                                            <Grid
                                                                item
                                                                xs={4}
                                                                md={2}
                                                                lg={2}
                                                                xl={1}
                                                                sx={{ p: 1 }}
                                                            >
                                                                <IconButton
                                                                    color="primary"
                                                                    onClick={() =>
                                                                        arrayHelpers.insert(i + 1,'')
                                                                    }
                                                                >
                                                                    <AddIcon />
                                                                </IconButton>
                                                                <IconButton
                                                                    type="button"
                                                                    color="error"
                                                                    key={i}
                                                                    onClick={() =>
                                                                        arrayHelpers.remove(i)
                                                                    }
                                                                >
                                                                    <ClearIcon />
                                                                </IconButton>
                                                            </Grid>
                                                        </Grid>
                                                    )
                                                )
                                            ) : (
                                                <Stack
                                                    direction="row"
                                                    justifyContent="center"
                                                >
                                                    <IconButton
                                                        color="primary"
                                                        onClick={() =>
                                                            arrayHelpers.insert(0, '')
                                                        }
                                                    >
                                                        <AddIcon />
                                                    </IconButton>
                                                </Stack>
                                            )}
                                        </Container>
                                    )}
                                />
                                <Divider textAlign="left">Types</Divider>
                                <FieldArray
                                    name="types"
                                    render={(arrayHelpers) => (
                                        <Container maxWidth={false}>
                                            {values.types &&
                                            values.types.length > 0 ? (
                                                values.types.map((type, i) => (
                                                    <Grid
                                                        sx={{ m: 2 }}
                                                        container
                                                        justifyContent="space-around"
                                                        key={i}
                                                    >
                                                        <Grid
                                                            item
                                                            xs={8}
                                                            md={10}
                                                            xl={11}
                                                        >
                                                            <TextField
                                                                fullWidth
                                                                label="Type"
                                                                name={`types.${i}`}
                                                                value={
                                                                    values.types[i]                                                                }
                                                                onChange={
                                                                    handleChange
                                                                }
                                                            />
                                                        </Grid>
                                                        <Grid
                                                            item
                                                            xs={4}
                                                            md={2}
                                                            lg={2}
                                                            xl={1}
                                                            sx={{ p: 1 }}
                                                        >
                                                            <IconButton
                                                                color="primary"
                                                                onClick={() =>
                                                                    arrayHelpers.insert(i + 1,'')
                                                                }
                                                            >
                                                                <AddIcon />
                                                            </IconButton>
                                                            <IconButton
                                                                type="button"
                                                                color="error"
                                                                key={i}
                                                                onClick={() =>
                                                                    arrayHelpers.remove(i)
                                                                }
                                                            >
                                                                <ClearIcon />
                                                            </IconButton>
                                                        </Grid>
                                                    </Grid>
                                                ))
                                            ) : (
                                                <Stack
                                                    direction="row"
                                                    justifyContent="center"
                                                >
                                                    <IconButton
                                                        color="primary"
                                                        onClick={() =>
                                                            arrayHelpers.insert(0,'')
                                                        }
                                                    >
                                                        <AddIcon />
                                                    </IconButton>
                                                </Stack>
                                            )}
                                        </Container>
                                    )}
                                />
                                <Divider textAlign="left">
                                    State or Territory
                                </Divider>
                                <FieldArray
                                    name="countries"
                                    render={(arrayHelpers) => (
                                        <Container maxWidth={false}>
                                            {values.countries &&
                                            values.countries.length > 0 ? (
                                                values.countries.map(
                                                    (country, i) => (
                                                        <Grid
                                                            sx={{ m: 2 }}
                                                            container
                                                            justifyContent="space-around"
                                                            key={i}
                                                        >
                                                            <Grid
                                                                item
                                                                xs={8}
                                                                md={10}
                                                                xl={11}
                                                            >
                                                                <TextField
                                                                    fullWidth
                                                                    label="State or Territory"
                                                                    name={`countries.${i}`}
                                                                    value={
                                                                        values.countries[i]
                                                                    }
                                                                    onChange={
                                                                        handleChange
                                                                    }
                                                                />
                                                            </Grid>
                                                            <Grid
                                                                item
                                                                xs={4}
                                                                md={2}
                                                                lg={2}
                                                                xl={1}
                                                                sx={{ p: 1 }}
                                                            >
                                                                <IconButton
                                                                    color="primary"
                                                                    onClick={() =>
                                                                        arrayHelpers.insert(i + 1,'')
                                                                    }
                                                                >
                                                                    <AddIcon />
                                                                </IconButton>
                                                                <IconButton
                                                                    type="button"
                                                                    color="error"
                                                                    key={i}
                                                                    onClick={() =>
                                                                        arrayHelpers.remove(i)
                                                                    }
                                                                >
                                                                    <ClearIcon />
                                                                </IconButton>
                                                            </Grid>
                                                        </Grid>
                                                    )
                                                )
                                            ) : (
                                                <Stack
                                                    direction="row"
                                                    justifyContent="center"
                                                >
                                                    <IconButton
                                                        color="primary"
                                                        onClick={() =>
                                                            arrayHelpers.insert(0,'')
                                                        }
                                                    >
                                                        <AddIcon />
                                                    </IconButton>
                                                </Stack>
                                            )}
                                        </Container>
                                    )}
                                />
                                <Divider textAlign="left">Coordinates</Divider>
                                <Container maxWidth={false}>
                                    <Grid
                                        sx={{ m: 2 }}
                                        container
                                        justifyContent="space-around"
                                    >
                                        <Grid item xs={6} sx={{ pr: 1 }}>
                                            <TextField
                                                fullWidth
                                                label="Latitude"
                                                name="latitude"
                                                value={values.latitude}
                                                onChange={handleChange}
                                                type="number"
                                                error={
                                                    Boolean(errors.latitude) &&
                                                    touched.latitude
                                                }
                                                helperText={
                                                    errors.latitude &&
                                                    touched.latitude &&
                                                    errors.latitude
                                                }
                                            />
                                        </Grid>
                                        <Grid item xs={6} sx={{ pl: 1 }}>
                                            <TextField
                                                fullWidth
                                                label="Longitude"
                                                name="longitude"
                                                type="number"
                                                value={values.longitude}
                                                onChange={handleChange}
                                                error={
                                                    Boolean(errors.longitude) &&
                                                    touched.longitude
                                                }
                                                helperText={
                                                    errors.longitude &&
                                                    touched.longitude &&
                                                    errors.longitude
                                                }
                                            />
                                        </Grid>
                                    </Grid>
                                </Container>
                                <Divider textAlign="left">Certainty</Divider>
                                <Container sx={{ m: 2 }} maxWidth={false}>
                                    <TextField
                                        fullWidth
                                        label="Certainty"
                                        name="certainty"
                                        value={values.certainty}
                                        onChange={handleChange}
                                        error={
                                            Boolean(errors.certainty) &&
                                            touched.certainty
                                        }
                                        helperText={
                                            errors.certainty &&
                                            touched.certainty &&
                                            errors.certainty
                                        }
                                    />
                                </Container>
                                <Divider textAlign="left">Bibliography</Divider>
                                <FieldArray
                                    name="bibliography"
                                    render={(arrayHelpers) => (
                                        <Container maxWidth={false}>
                                            {values.bibliography &&
                                            values.bibliography.length > 0 ? (
                                                values.bibliography.map(
                                                    (b, i) => (
                                                        <Grid
                                                            sx={{ m: 2 }}
                                                            container
                                                            justifyContent="space-around"
                                                            key={i}
                                                        >
                                                            <Grid
                                                                item
                                                                xs={8}
                                                                md={10}
                                                                xl={11}
                                                            >
                                                                <TextField
                                                                    fullWidth
                                                                    label="Bibliography"
                                                                    name={`bibliography.${i}`}
                                                                    value={
                                                                        values.bibliography[i]
                                                                    }
                                                                    onChange={
                                                                        handleChange
                                                                    }
                                                                />
                                                            </Grid>
                                                            <Grid
                                                                item
                                                                xs={4}
                                                                md={2}
                                                                lg={2}
                                                                xl={1}
                                                                sx={{ p: 1 }}
                                                            >
                                                                <IconButton
                                                                    color="primary"
                                                                    onClick={() =>
                                                                        arrayHelpers.insert(i + 1,'')
                                                                    }
                                                                >
                                                                    <AddIcon />
                                                                </IconButton>
                                                                <IconButton
                                                                    type="button"
                                                                    color="error"
                                                                    key={i}
                                                                    onClick={() =>
                                                                        arrayHelpers.remove(i)
                                                                    }
                                                                >
                                                                    <ClearIcon />
                                                                </IconButton>
                                                            </Grid>
                                                        </Grid>
                                                    )
                                                )
                                            ) : (
                                                <Stack
                                                    direction="row"
                                                    justifyContent="center"
                                                >
                                                    <IconButton
                                                        color="primary"
                                                        onClick={() =>
                                                            arrayHelpers.insert(0,'')
                                                        }
                                                    >
                                                        <AddIcon />
                                                    </IconButton>
                                                </Stack>
                                            )}
                                        </Container>
                                    )}
                                />
                                <Divider textAlign="left">Description</Divider>
                                <Container sx={{ m: 2 }} maxWidth={false}>
                                    <TextField
                                        fullWidth
                                        label="Description"
                                        name="description"
                                        multiline
                                        rows={3}
                                        value={values.description}
                                        onChange={handleChange}
                                    />
                                </Container>
                                <Container
                                    maxWidth={false}
                                    style={{
                                        display: 'flex',
                                        justifyContent: 'flex-end',
                                        alignItems: 'flex-end',
                                    }}
                                >
                                    <Button
                                        sx={{ m: 2 }}
                                        startIcon={<SaveIcon />}
                                        variant="contained"
                                        color="primary"
                                        type="submit"
                                        disabled={loading}
                                    >
                                        Save
                                    </Button>
                                </Container>
                            </Form>
                        )}
                    </Formik>
                ) : null}
            </Paper>
        </Fragment>
    )
}

const EditCatalogItem: FunctionComponent<CatalogItemEditProps> = ({
    itemId,
    showReturnToDetailButton,
    onRequestCompletedCallback,
}) => {
    const [item, setItem] = useState<CatalogItemDto | undefined>(undefined)
    const [isItemLoading, setIsItemLoading] = useState(true)
    const [err, setErr] = useState<string | undefined>(undefined)
    const error = useSelector((state: RootState) => state.catalogItem.error)
    const loading = useSelector((state: RootState) => state.catalogItem.loading)

    // Fetch the item from the api after mounting the component
    useEffect(() => {
        // Function to fetch the item from the api
        const fetchItem = async () => {
            try {
                if (!itemId || itemId === '') {
                    return
                }
                const { data, status } = await axiosInstance.get(
                    `/catalog-items/${itemId}`
                )
                if (status !== 200) {
                    setErr(apiError)
                    return
                }

                setItem(data)
                setIsItemLoading(false)
            } catch (err: any) {
                setErr(apiError)
            }
        }

        fetchItem()
    }, [itemId])

    return (
        <CreateCatalogItem
            item={item}
            isItemLoading={isItemLoading}
            err={error}
            itemId={itemId}
            loading={loading}
            showReturnToDetailButton={showReturnToDetailButton}
            onRequestCompletedCallback={onRequestCompletedCallback}
        />
    )
}

export const RoutedCatalogItemEdit = () => {
    const { itemId } = useParams()
    return <EditCatalogItem itemId={itemId ?? ''} showReturnToDetailButton />
}

export default EditCatalogItem
