import { Fragment, FunctionComponent, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '../../redux/store'
import {
    MapPoint,
    isMapPointDisplayable,
    MapPointType,
} from '../trackingToolUtils'
import TextPath from 'react-leaflet-textpath'
import { updateMapPoint } from '../trackingToolSlice'
import MapMarker from './MapMarker'
import { LatLngTuple } from 'leaflet'
import { Popup, Tooltip } from 'react-leaflet'
import { Checkbox, FormControlLabel, Stack, Typography } from '@mui/material'
import { formatHtmlStringToReactDom } from '../../../utils/Formatting/HtmlUtils'
import { CatalogItemDetailDialog as CatalogItemDetailDialog } from '../../Catalog/CatalogItemDetail'

type EdgeElement = any

const ProcessedTextMapMarker: FunctionComponent<{ item: MapPoint }> = ({
    item,
}) => {
    const dispatch = useDispatch()
    const [draggingEnabled, setDraggingEnabled] = useState(false)

    return (
        <MapMarker
            position={[
                item.catalogItem.latitude as number,
                item.catalogItem.longitude as number,
            ]}
            mapPoint={item}
            draggable={draggingEnabled}
            updatePositionCallbackFn={(position: LatLngTuple) => {
                dispatch(
                    updateMapPoint({
                        ...item,
                        catalogItem: {
                            ...item.catalogItem,
                            latitude: position[0],
                            longitude: position[1],
                        },
                    })
                )
            }}
        >
            <Fragment>
                <Tooltip>{item.catalogItem.name ?? ''}</Tooltip>
                <Popup>
                    <Fragment>
                        <Stack direction="column" sx={{ m: 0 }}>
                            <Typography
                                variant="h6"
                                fontWeight="bold"
                                fontSize={16}
                            >
                                {formatHtmlStringToReactDom(
                                    item.catalogItem.name as string
                                )}
                            </Typography>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={item.addToPath}
                                        onChange={() => {
                                            dispatch(
                                                updateMapPoint({
                                                    ...item,
                                                    addToPath: !item.addToPath,
                                                })
                                            )
                                        }}
                                    />
                                }
                                labelPlacement="end"
                                label="Active"
                            />
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={draggingEnabled}
                                        onChange={() => {
                                            setDraggingEnabled((prev) => !prev)
                                        }}
                                    />
                                }
                                labelPlacement="end"
                                label="Move"
                            />
                            <CatalogItemDetailDialog
                                itemId={item.catalogItem.id ?? ''}
                            />
                        </Stack>
                    </Fragment>
                </Popup>
            </Fragment>
        </MapMarker>
    )
}

export const buildEdges = (
    displayableMapPoints: MapPoint[],
    setEdges: (edges: EdgeElement[]) => void,
    pathColor: string,
    pathThickness = 17
) => {
    // Get all active map points
    const activeMapPoints = displayableMapPoints.filter(
        (item) => item.addToPath && !item.hidden
    )
    if (activeMapPoints.length < 2) {
        setEdges([])
        return
    }

    // Keep track of already built edges so we do not render excessive amount of them
    // We can use a simple hack where we concatenate id of both and check if it is already in the set
    const existingPaths = new Set<string>()
    const edges = []
    setEdges([])
    for (let i = 0; i < activeMapPoints.length - 1; i += 1) {
        const edgeId = `${activeMapPoints[i].id}${activeMapPoints[i + 1].id}`
        if (existingPaths.has(edgeId)) {
            continue
        }
        existingPaths.add(edgeId)
        const [start, end] = [
            activeMapPoints[i].catalogItem,
            activeMapPoints[i + 1].catalogItem,
        ]
        edges.push(
            <TextPath
                key={edgeId}
                positions={[
                    [start.latitude, start.longitude],
                    [end.latitude, end.longitude],
                ]}
                text="►"
                attributes={{
                    'font-size': pathThickness,
                    fill: pathColor,
                }}
                repeat
                center
                weight={0}
            />
        )
    }
    setEdges(edges)
}

const ProcessedTextPath = () => {
    const dispatch = useDispatch()

    // Displayed path contains all map points
    const path = useSelector(
        (state: RootState) => state.trackingTool.displayedPath
    )

    // Feature enabled
    const featureEnabled = useSelector(
        (state: RootState) =>
            state.trackingTool.toggleables[MapPointType.ProcessedText]
    )

    // Color of the path
    const pathColor = '#346eeb'

    // List of all active map points
    const [displayableMapPoints, setDisplayableMapPoints] = useState<
        MapPoint[]
    >([])
    useEffect(() => {
        if (!path || !featureEnabled) {
            setDisplayableMapPoints([])
            return
        }

        // Set all displayable vertices
        setDisplayableMapPoints(
            path.filter((mapPoint) => isMapPointDisplayable(mapPoint))
        )
    }, [path, featureEnabled])

    // List of all edges in the path
    const [edges, setEdges] = useState<EdgeElement[]>([])
    useEffect(() => {
        buildEdges(displayableMapPoints, setEdges, pathColor)
    }, [dispatch, displayableMapPoints])

    // List of vertices to display
    const [vertices, setVertices] = useState<JSX.Element[]>([])
    useEffect(() => {
        // Iterate over all displayable map points and map them to MapMarker
        const uniqueMapPoints = new Set<string>()
        setVertices(
            displayableMapPoints
                .filter((mapPoint) => {
                    if (uniqueMapPoints.has(mapPoint.id)) {
                        return false
                    }
                    uniqueMapPoints.add(mapPoint.id)
                    return true
                })
                .map((item) => (
                    <ProcessedTextMapMarker key={item.id} item={item} />
                ))
        )
        
    }, [dispatch, displayableMapPoints])

    return (
        <Fragment>
            {vertices}
            {edges}
        </Fragment>
    )
}

export default ProcessedTextPath
